import React, { useEffect } from 'react'
import { getBlocks } from 'blocks'
import Loading from 'components/Loading'
import { HDown } from 'Heading'
import { useFetchStore } from 'Fetch'
import { setResourcesBar } from 'redux/actions'
import { setTheme } from 'redux/actions'
import { useDispatch } from 'react-redux'

export const Shell = ({
  match,
  location,
  hero,
  builderType,
  mainClassName,
  afterBody,
}) => {
  const slug = match.params.slug ? match.params.slug : '__home__'
  const data = useFetchStore(
    `${process.env.REACT_APP_API}/${builderType}/${slug}.json${location.search}`
  )

  const dispatch = useDispatch()
  useEffect(() => {
    if (data) {
      dispatch(setResourcesBar(data.showResourcesBar || false))
      dispatch(setTheme(data.navTheme || 'white'))
    }
  }, [data, dispatch])

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_SEOMATIC_ENDPOINT}${location.pathname.replace(
        '/',
        ''
      )}&asArray=true`
    )
      .then(res => res.json())
      .then(res => {
        document.title = res.MetaTitleContainer.title.title
      })
      .catch(err => {
        console.log(err)
      })
  }, [location.pathname])

  return data ? (
    <Content
      key={data.id}
      data={data}
      hero={hero}
      className={mainClassName}
      afterBody={afterBody ? afterBody(data) : null}
    />
  ) : (
    <div className="PageLoader">
      <Loading />
    </div>
  )
}

const Content = ({ hero, data, className, afterBody }) => {
  useEffect(() => {
    if (window.location.hash) {
      const elem = document.getElementById(
        window.location.hash.replace('#', '')
      )
      if (elem) {
        elem.scrollIntoView()
      }
    }
  }, [])

  return (
    <>
      {hero && hero(data)}
      <Body blocks={data.blocks} className={className} afterBody={afterBody} />
    </>
  )
}

export const Page = props => <Shell builderType="page" {...props} />

const Body = ({ blocks, className = '', afterBody }) => {
  if (!blocks) {
    return null
  }
  let header, main
  if (
    blocks[0] &&
    ['IconAnchorsHero', 'OurServicesHero', 'StandardHero'].includes(
      blocks[0].component
    )
  ) {
    blocks[0].headerTag = true
    header = getBlocks([blocks[0]])
    main = getBlocks(blocks.slice(1))
  } else {
    main = getBlocks(blocks)
  }

  return (
    <React.Fragment>
      {header}
      {main.length > 0 && (
        <HDown>
          <main className={className}>
            {main}
            {afterBody}
          </main>
        </HDown>
      )}
    </React.Fragment>
  )
}
