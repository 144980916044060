import React from "react"

const Container = ({ Tag="section", className="", children }) => {

  return <Tag className={`Container ${className}`}>
    <Tag className={`Container-inner max`}>
      {children}
    </Tag>
  </Tag>
}

export default Container