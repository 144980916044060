function RightCarrot() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16">
      <path
        d="M1 1l7.5 7L1 15"
        fill="none"
        strokeWidth="2"
        stroke="var(--blue)"
      />
    </svg>
  );
}

export default RightCarrot;
