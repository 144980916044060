import React, { useRef } from 'react'
import { getBlocks } from 'blocks'
import './publication-article.scss'
import PublicationToc from './publication-toc'

const PublicationArticle = ({ title, blocks, hideTableOfContents }) => {
  const ref = useRef()

  if (blocks.length === 0) {
    return null
  }

  return (
    <div
      className={
        hideTableOfContents
          ? 'PublicationArticle--no-toc'
          : 'PublicationArticle'
      }
    >
      {!hideTableOfContents && (
        <PublicationToc title={title} documentRef={ref} />
      )}
      <div className="Article" ref={ref}>
        {getBlocks(blocks)}
      </div>
    </div>
  )
}

export default PublicationArticle
