import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
import cssVars from 'css-vars-ponyfill'
import 'picturefill'
import 'picturefill/dist/plugins/mutation/pf.mutation.min.js'

import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import createStore from './redux/store.js'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { createRoot } from 'react-dom/client'

const container = document.getElementById('root')
const root = createRoot(container)
cssVars()

// const renderMethod = !!module.hot ? root.render : root.hydrate

root.render(
  <Provider store={createStore(window.REDUX_DATA)}>
    <Router>
      <App />
    </Router>
  </Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
