import React from "react"
import Image from "./Image.js"
import Link from "components/Link"
import { H } from "Heading"
import { useFadein } from "animations"

const Hero = ({
  heading,
  image,
  text,
  link,
  layout = "default",
  headerTag
}) => {
  const { a, props } = useFadein()
  const Tag = headerTag ? a.header : a.div

  return (
    <Tag className={`StandardHero StandardHero--${layout}`} style={props}>
      <Image className="StandardHero-image" image={image} />
      <H className="StandardHero-heading">{heading}</H>
      <div
        className="StandardHero-text"
        dangerouslySetInnerHTML={{ __html: text }}
      />
      {link && link.url && (
        <div className="StandardHero-link">
          <Link {...link} />
        </div>
      )}
    </Tag>
  )
}

export default Hero
