import React from "react";

const SvgPhone = props => (
  <svg width={21} height={20} {...props}>
    <g fill="#161818">
      <path d="M14.5 10.6l-1.1-.6c.1-.1.6-1.2-.4-2.1-1-1-2.1-.4-2.1-.4l-.6-1.1c.7-.4 2.3-.8 3.7.5 1.3 1.4.9 3 .5 3.7" />
      <path d="M17.2 10.9l-1.1-.6c.1-.1 1.5-2.6-.8-4.8-2.2-2.2-4.8-.8-4.8-.8l-.5-1c1.2-.7 4-1.4 6.3 1 2.3 2.3 1.6 5.1.9 6.2" />
      <path d="M20.1 11.4l-1.1-.6c.1-.2 2.1-3.9-1.4-7.5-3.6-3.6-7.3-1.5-7.5-1.4L9.4.8s4.7-2.6 9 1.6c4.3 4.3 1.7 9 1.7 9" />
    </g>
    <path
      fill="#161818"
      d="M18.8 15.6l-2-2c-.8-.8-2.2-.8-3 0l-.8.9c-.2.2-.4.5-.5.8-1.5-.4-3.2-1.3-4.9-2.9C6 10.7 5.1 8.9 4.7 7.5c.3-.1.7-.3.9-.5l1-1c.9-.9.9-2.2 0-3.1L4.8.9C3.9 0 2.6 0 1.7.9l-1 1c-.6.6-.6.9-.7 1.8 0 .7-.4 6.6 4.6 11.7 4.1 4.1 8.8 4.6 10.8 4.6h.7c.7 0 1.4 0 1.9-.5l.8-.8c.9-.9.9-2.3 0-3.1"
    />
  </svg>
);

export default SvgPhone;
