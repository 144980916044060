import React, { useRef, useEffect, useState } from "react";
import { useSpring, a } from "react-spring";
import { useFade } from "a";
import { scaleLinear } from "d3-scale";
import { sum, max } from "d3-array";
import _filter from "lodash/filter";
import { formatValue, sumAll } from "components/CAT/helpers";
import colortest from "color";
import Tooltip from "react-tooltip";
import { scaleModes } from "components/data-viz/constants";
import QuestionMark from "svgr/QuestionMark";
import colors from "data-colors";
import Key from "components/data-viz/key";
import BarLabel from "components/data-viz/bar-label";
import styles from "components/pretrial-release-dashboard/bars.module.scss";

const Bars = ({ data, variables, fields, scaleMode, keyLabel, children }) => {
  const [variableKeys, variableValues] = variables;

  const rows = variableValues[0];
  const columns = variableValues[1];
  const groups = variableValues[2];

  let barNodes;
  if (variableKeys.length === 0) {
    const barData = pullData(data, null, null, [], scaleMode);

    barNodes = (
      <div>
        <SegmentedBar data={barData} />
      </div>
    );
  } else if (variableKeys.length === 1) {
    barNodes = variableKeys.map((key, i) => {
      const barData = pullData(data, key, fields[key], [], scaleMode);

      return (
        <div key={key}>
          <SegmentedBar data={barData} />
        </div>
      );
    });
  } else if (variableKeys.length === 2) {
    const dMax = max(
      columns.map(({ label, variable, value }) => {
        const filtered = _filter(data, { [variable]: value });
        return sum(filtered, (d) => +d.value);
      })
    );

    const scale = scaleLinear()
      .domain([0, dMax])
      .range([0, W]);

    barNodes = (
      <div className="Bars">
        <BarLabel variable={fields[variableKeys[1]].label} />
        <div className="Bars-inner">
          {columns.map(({ label, variable, value }) => {
            const filtered = _filter(data, { [variable]: value });
            const barData = pullData(
              filtered,
              variableKeys[0],
              fields[variableKeys[0]],
              [[fields[variable].label, label]],
              scaleMode
            );

            const total = sum(filtered, (d) => d.value);

            return (
              <div key={label}>
                <BarLabel value={label} />
                <SegmentedBar
                  key={variable}
                  data={barData}
                  widthScale={
                    scaleMode === scaleModes.ABSOLUTE ? scale(total) / W : 1
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  } else {
    //TODO

    const dMax = max(
      groups.map((group) => {
        return max(
          columns.map(({ label, variable, value }) => {
            const filtered = _filter(data, {
              [variable]: value,
              [group.variable]: group.value,
            });
            return sum(filtered, (d) => +d.value);
          })
        );
      })
    );

    barNodes = (
      <div className="Bars-inner">
        {groups.map((group) => {
          const scale = scaleLinear()
            .domain([0, dMax])
            .range([0, W]);
          return (
            <div className="BarGroup" key={group.label}>
              <BarLabel
                type={"primary"}
                variable={fields[group.variable].label}
                value={group.label}
              />
              <div className="BarGroup-inner">
                {columns.map(({ label, variable, value }) => {
                  const filtered = _filter(data, {
                    [variable]: value,
                    [group.variable]: group.value,
                  });
                  const barData = pullData(
                    filtered,
                    variableKeys[0],
                    fields[variableKeys[0]],
                    [
                      [fields[group.variable].label, group.label],
                      [fields[variable].label, label],
                    ],
                    scaleMode
                  );

                  const total = sum(filtered, (d) => d.value);

                  return (
                    <div key={label}>
                      <BarLabel
                        variable={fields[variableKeys[1]].label}
                        value={label}
                      />
                      <SegmentedBar
                        data={barData}
                        widthScale={
                          scaleMode === scaleModes.ABSOLUTE
                            ? scale(total) / W
                            : 1
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  const props = useFade();

  return (
    <a.div style={props}>
      <div className={styles.controlsKey}>
        <Key
          label={keyLabel}
          variable={
            variableKeys[0] ? fields[variableKeys[0]].label : "All Cases"
          }
          data={rows}
        />
        {children}
      </div>
      {barNodes}
    </a.div>
  );
};

export default Bars;

export const DataUnavailable = () => {
  return (
    <div className="Null">
      <span
        className="Null-tooltip"
        data-tip="When a result covers fewer than 25 cases, the number is withheld to prevent data from being identifiable."
      >
        <QuestionMark />
      </span>
      &nbsp;{"< 25 "}
    </div>
  );
};

export const W = 1000;
export const H = 30;
export const SegmentedBar = ({ data, widthScale = 1 }) => {
  const height = H;
  let xOff = 0;

  const ref = useRef();
  const [state, setState] = useState({
    width: 0,
    scale: null,
  });
  useEffect(() => {
    if (ref.current) {
      const onResize = () => {
        const { width } = ref.current.getBoundingClientRect();
        const scale = scaleLinear()
          .domain([0, sum(data, (d) => d.value)])
          .range([0, width * widthScale]);

        setState({
          width,
          scale,
        });
      };

      onResize();
      window.addEventListener("resize", onResize);

      return () => {
        window.removeEventListener("resize", onResize);
      };
    }
  }, [data, widthScale]);

  useEffect(() => {
    Tooltip.rebuild();
  }, [state]);

  const hasNull =
    data.length === 0 ||
    data.reduce((r, d) => {
      if (d.value === 0) {
        r = true;
      }
      return r;
    }, false);

  const style = useSpring({
    height: height ? `${height}px` : `0px`,
    width: state.width ? `${state.width}px` : `0px`,
  });

  if (hasNull) {
    return (
      <a.div style={{ minHeight: style.height }}>
        <DataUnavailable />
      </a.div>
    );
  }

  return (
    <div ref={ref} style={{ height: `${H}px` }}>
      {state.width && (
        <a.svg
          className="Bar"
          viewBox={`0 0 ${state.width} ${height}`}
          style={{
            ...style,
            position: "absolute",
          }}
        >
          <rect
            x={0}
            y={0}
            width={state.width}
            height={height}
            fill={"rgba(0,0,0,.03)"}
          />
          {data
            .map(({ key, value, formattedValue, tooltips }, i) => {
              const width = state.scale(value);
              xOff += width;
              const x = xOff - width;
              const y = 0;

              return (
                <Segment
                  key={key}
                  value={value}
                  formattedValue={formattedValue}
                  tooltips={tooltips}
                  fill={colors[i % colors.length]}
                  x={x}
                  y={y}
                  width={width}
                  height={height}
                />
              );
            })
            .reverse()}
        </a.svg>
      )}
    </div>
  );
};

const Segment = ({
  value,
  formattedValue,
  tooltips,
  fill,
  x,
  y,
  width,
  height,
}) => {
  const barRef = useRef();

  return (
    <>
      <Rect
        forwardRef={barRef}
        data-tip={
          tooltips &&
          `
        <div>
          ${tooltips
            .map(([key, value]) =>
              key
                ? `<p>
              <div class="key">${key}</div>
              <div class="value">${value}</div>
            </p>`
                : `<p>
              ${value}
            </p>`
            )
            .join("")}
        </div>
      `
        }
        data-html
        x={x}
        y={y}
        width={width}
        height={height}
        fill={fill}
      />
      <BarValue
        x={x}
        y={height}
        value={formattedValue}
        barRef={barRef}
        fill={colortest(fill).isLight() ? "#000" : "#fff"}
      />
    </>
  );
};

const BarValue = ({ x, y, value, barRef, fill }) => {
  const [show, setShow] = useState(false);
  const [props, set] = useSpring(() => ({
    opacity: 0,
    x: 0,
  }));
  set({
    opacity: show ? 1 : 0,
    x,
  });
  const ref = useRef();
  useEffect(() => {
    if (
      typeof ResizeObserver !== "undefined" &&
      ref.current &&
      barRef.current
    ) {
      const resizeObserver = new ResizeObserver(([entry]) => {
        if (ref.current) {
          const fits =
            entry.contentRect.width > 1.15 * ref.current.getBBox().width;
          setShow(fits);
        }
      });
      resizeObserver.observe(barRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [barRef]);
  return (
    <a.text
      style={{
        opacity: props.opacity,
        pointerEvents: "none",
        fill,
      }}
      ref={ref}
      x={props.x.interpolate((x) => x + 3)}
      y={y - 5}
    >
      {value}
    </a.text>
  );
};

const Rect = ({ x, width, forwardRef, ...rest }) => {
  const [props, set] = useSpring(() => ({
    x: 0,
    width: 0,
  }));
  set({
    x,
    width,
  });
  return <a.rect ref={forwardRef} {...rest} {...props} />;
};

const pullData = (data, key, field, tooltips = [], scaleMode) => {
  const total = sumAll(data);
  return data.map((datum) => {
    const option = key
      ? field.options.find((d) => d.value === datum[key])
      : {
          label: "All",
        };
    const formattedValue = formatValue({
      value: datum.value,
      total: total,
      scaleMode,
    });
    return {
      key: option.label,
      value: +datum.value,
      formattedValue,
      tooltips: [
        ...tooltips,
        [field ? field.label : null, option.label],
        [
          undefined,
          `
          <div class="total">
            <div class="value">
              <span style="font-size: 2em">${formattedValue}</span>
              <span class="key">cases</span>
            </div>
          <div>
        `,
        ],
      ],
    };
  });
};
