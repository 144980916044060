import { useState, useEffect, useCallback } from "react";

const useMatchMedia = (query) => {
  const isMatch = useCallback(() => {
    return typeof window !== "undefined" && window.matchMedia(query).matches;
  }, [query]);
  const [matches, setMatches] = useState(isMatch());
  useEffect(() => {
    const onResize = () => {
      setMatches(isMatch());
    };
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [setMatches, isMatch]);

  return matches;
};

export default useMatchMedia;
