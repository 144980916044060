import React from "react";
import { Link } from "react-router-dom";
import Image from "blocks/Image";
import { formatDate as format, oxford } from "format";

const StreamCard = ({ featured=false, url, external=false, isSearch, title, description, type, authors, date, image, image_square }) => {
  const hasImage = image || image_square
  const inner =  <div className="StreamCard-inner">
    <div className="StreamCard-type">{type === "Pages" ? "CJA" : type}</div>
    {hasImage && <Image className="StreamCard-image" image={featured ? image_square : image} hideCaption />}
    <div className="StreamCard-title">{title}</div>
    {description && ((!hasImage && title.length < 30) || type === "People") && (
      <div className="StreamCard-description">
        {description.slice(0,200)}
      </div>
    )}
    {authors && <div className="StreamCard-authors">{oxford(authors)}</div>}
    {date && <div className="StreamCard-date">{format(date)}</div>}
  </div>
  const className = `StreamCard StreamCard--${type} ${featured && 'StreamCard--featured'}`
  return external
    ? <a className={className} href={url} target={"_blank"} rel={"noopener noreferrer"}>{inner}</a>
    : <Link to={url} className={className}>{inner}</Link>
}

export default StreamCard;