import React from "react";
import { Link } from "react-router-dom";
import Image from "./Image";
import orderBy from "lodash/orderBy";

const People = ({ people, sort }) => {
  if (!people || people.length === 0) {
    return null
  }
  const sorted = sort === "alphabetical"
    ? orderBy(people, [person => person.name.toLowerCase()], ['asc'])
    : people
  return <ul className="People" data-count={sorted.length}>{sorted.map(({ id, name, title, image, slug }) => {
    // if (!image) {
    //   image = {
    //     "src": "//placehold.it/300x300"
    //   }
    // }
    return (
      <li className="People-item" key={id}>
        <Link to={`/people/${slug}`}>
          <Image image={image} caption={<>
            <div className="People-item-name">{name}</div>
            <div className="People-item-title">{title}</div>
          </>} />
        </Link>
      </li>
    )
  })}</ul>
}

export default People