import React, { useState, useEffect } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import useMinWidth from 'hooks/useMinWidth'
import { usePageHeadingsTree } from 'use-page-headings-tree'

const PublicationToc = ({ title, documentRef }) => {
  const [pageHeadingNodes, setPageHedingNodes] = useState([])
  const [pageHeadingTree, setPageHeadingTree] = useState(null)
  const isDesktop = useMinWidth('1000px')
  const [toggleMobile, setToggleMobile] = useState(false)

  const currentDocumentRef = documentRef.current
  useEffect(() => {
    if (currentDocumentRef) {
      const headingNodes = currentDocumentRef.querySelectorAll('h2,h3')
      setPageHedingNodes(headingNodes)
    }
  }, [currentDocumentRef])

  usePageHeadingsTree(pageHeadingNodes, setPageHeadingTree, false)

  const renderNodeList = level => node => (
    <li key={node.id}>
      <a href={`#${node.id}`} onClick={() => setToggleMobile(false)}>
        {node.text}
      </a>
      {node.childNodes.length > 0 ? (
        <ul className={`PublicationArticle-toc-level--${level + 1}`}>
          {node.childNodes.map(renderNodeList(level + 1))}
        </ul>
      ) : null}
    </li>
  )

  const tocInner = pageHeadingTree && (
    <ul className="PublicationArticle-toc-level--1">
      {pageHeadingTree.map(renderNodeList(1))}
    </ul>
  )
  return (
    <div
      className={`PublicationArticle-toc ${
        isDesktop || toggleMobile ? 'open' : 'closed'
      }`}
    >
      {isDesktop ? (
        <div className="PublicationArticle-toc-title">{title}</div>
      ) : (
        <button
          onClick={() => setToggleMobile(!toggleMobile)}
          className={`PublicationArticle-toc-toggle ${
            toggleMobile ? 'open' : 'closed'
          }`}
        >
          {toggleMobile ? 'Close' : 'Show table of contents'}
        </button>
      )}
      <div className="PublicationArticle-toc-inner">
        {isDesktop ? (
          <Scrollbars
            style={{ width: '100%', height: '100%' }}
            renderThumbVertical={props => (
              <div {...props} className="thumb-vertical" />
            )}
          >
            {tocInner}
          </Scrollbars>
        ) : (
          toggleMobile && tocInner
        )}
      </div>
    </div>
  )
}

export default PublicationToc
