import React from 'react'
import { useFetchStore } from 'Fetch'
import Loading from 'components/Loading'

import { oxford, formatDate } from 'format'
import './Publications.scss'
import useTheme from 'useTheme'
import NotFound from 'components/not-found'
import { getBlocks } from 'blocks'
import PublicationArticle from 'components/publication-article'

const Publication = ({ match, location }) => {
  const data = useFetchStore(
    `${process.env.REACT_APP_API}/publication/${match.params.slug}.json${location.search}`
  )
  useTheme('lightBlue')
  if (data && data.error) {
    return <NotFound />
  }
  return data ? (
    <>
      <PublicationHero
        title={data.title}
        date={data.date}
        authors={data.authors}
        download={data.download}
        body={getBlocks(data.blocks)}
      />
      {data.article.length > 0 && (
        <PublicationArticle
          title={data.title}
          blocks={data.article}
          hideTableOfContents={data.hideTableOfContents}
        />
      )}
    </>
  ) : (
    <div className="PageLoader">
      <Loading />
    </div>
  )
}

const PublicationHero = ({ title, authors, date, download, body }) => {
  return (
    <div className="Publication max">
      <header className="PublicationHero-header">
        <div className="PublicationHero-header-inner">
          <div className="PublicationHero-date">{formatDate(date)}</div>
          <h1 className="PublicationHero-heading">{title}</h1>
          {authors.length > 0 && (
            <div className="PublicationHero-authors">{oxford(authors)}</div>
          )}
        </div>
        <div className="PublicationHero-header-gradient"></div>
      </header>
      {download && (
        <div className="PublicationHero-download">
          <a
            href={download}
            target="_blank"
            rel="noopener noreferrer"
            className="PublicationHero-download-button"
          >
            Open PDF
          </a>
        </div>
      )}
      {body.length > 0 && (
        <main
          className={`Publication-main ${!download &&
            'Publication-main-padtop'}`}
        >
          {body}
        </main>
      )}
    </div>
  )
}

export default Publication
