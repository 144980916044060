import React, { useState, useEffect, useMemo } from "react";
import Tooltip from "react-tooltip";

import { determineVariables } from "components/CAT/helpers";

import TableView from "./views/Table";
import BarsView from "./views/Bars";

import Bargraph from "svgr/Bargraph";
import Table from "svgr/Table";

import DownloadCsv from "./DownloadCsv";
import GlossaryIcon from "svgr/Glossary";
import IconLink from "components/icon-link";

import ToggleSet from "components/data-viz/toggle-set";
import ScaleToggle from "components/data-viz/scale-toggle";

const views = ["Bars", "Table"];

const viewIcons = {
  Bars: Bargraph,
  Table: Table,
};

const Results = ({ state, dispatch, fields, showControls }) => {
  const [data, setData] = useState();

  useEffect(() => {
    const group = Object.values(state.variables)
      .filter((d) => d.visualize)
      .map(({ value }) => value);
    const filter = Object.values(state.variables).reduce(
      (result, { value, filters }) => {
        if (filters && filters.length) {
          result[value] = filters;
        }
        return result;
      },
      {}
    );

    const params = {
      group,
      filter: JSON.stringify(filter),
    };

    fetch(`${process.env.REACT_APP_CAT}?${queryString(params)}`)
      .then((res) => res.json())
      .then((res) => setData(Array.isArray(res) ? res : Object.values(res)));
  }, [state]);

  const csvData = useMemo(() => {
    if (!data) {
      return null;
    }

    return data.map((d) => {
      return Object.keys(d).reduce((r, key) => {
        if (key === "value") {
          r["Number of cases"] = d.value ? d.value : "< 25";
        } else {
          r[fields[key].label] = fields[key].options.find(
            (option) => option.value === d[key]
          ).label;
        }
        return r;
      }, {});
    });
  }, [data, fields]);

  if (!data) {
    return null;
  }

  const setView = (view) => () => {
    dispatch({
      type: "setView",
      value: view,
    });
  };

  const setScaleMode = (mode) => () => {
    dispatch({
      type: "setScaleMode",
      value: mode,
    });
  };

  const variables = determineVariables(data, fields);
  const [variableKeys] = variables;

  const filteredData = data.reduce((r, datum) => {
    let keep = true;
    variableKeys.forEach((key) => {
      const option = fields[key].options.find((d) => d.value === datum[key]);
      if (option.label.toLowerCase() === "excluded") {
        keep = false;
      }
    });
    if (keep) {
      r.push(datum);
    }
    return r;
  }, []);

  const yearOptions = fields["YEAR"].options.map((d) => d.value);
  const yearSpan = !variableKeys.includes("YEAR")
    ? `${Math.min(...yearOptions)}-${Math.max(...yearOptions)}`
    : null;

  return (
    <div className="CAT-results">
      <Tooltip className="CATTooltip" />
      {showControls && (
        <Controls
          views={views}
          currentView={state.view}
          setView={setView}
          currentScaleMode={state.scaleMode}
          setScaleMode={setScaleMode}
          csvData={csvData}
        />
      )}
      <View
        view={state.view}
        data={filteredData}
        variables={variables}
        fields={fields}
        scaleMode={state.scaleMode}
        keyLabel={`Prosecuted Cases in NYC${yearSpan ? ` ${yearSpan}` : ""}`}
      ></View>
    </div>
  );
};

export default Results;

const Controls = ({
  views,
  currentView,
  setView,
  currentScaleMode,
  setScaleMode,
  csvData,
}) => {
  return (
    <div className="CAT-results-controls">
      <ToggleSet
        items={views}
        current={currentView}
        set={setView}
        icons={viewIcons}
      />
      <ScaleToggle value={currentScaleMode} set={setScaleMode} />
      <div style={{ display: "flex" }}>
        <IconLink
          label="Glossary"
          href="https://www.nycja.org/assets/CAT_Variables_Glossary.pdf"
        >
          <GlossaryIcon />
        </IconLink>
        <DownloadCsv data={csvData} />
      </div>
    </div>
  );
};

const View = ({ view, ...props }) => {
  return (
    <>
      {view === "Table" ? (
        <TableView {...props} />
      ) : view === "Bars" ? (
        <BarsView {...props} />
      ) : null}

      <div className="CAT-results-citation">
        <div>Suggested citation:</div>
        <div>New York City Criminal Justice Agency. Case Analysis Tool.</div>
        <div>Retrieved from: https://www.nycja.org/cat</div>
      </div>
    </>
  );
};

var queryString = (params) =>
  Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
