import React, { useCallback, useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Results } from "blocks/Stream";
import { setQuery } from "blocks/Stream/streamReducer";
import "./Search.scss";
import { stringify } from "qs";
import { useFetchStore } from "Fetch";
import useTheme from "useTheme";
import SearchIcon from "svgr/Search";

const ID = "global";

export const useStream = (id, initialState) => {
  const state = useSelector((state) => state.search[id], shallowEqual);
  const dispatch = useDispatch();
  if (!state) {
    dispatch({
      type: "INIT",
      id,
      initialState,
    });
  }
  const queryParams = state
    ? {
        section: state.section,
        ids: state.ids,
        categories: state.categories,
        years: state.years,
        query: state.query,
        page: state.page,
        pageSize: state.pageSize,
        orderBy: state.orderBy,
      }
    : {};
  const queryString = stringify(queryParams);

  const res = useFetchStore(
    state ? `${process.env.REACT_APP_API}/stream.json?${queryString}` : null
  );
  const initialized = state !== null;
  const currentIds = useRef([]);
  currentIds.current = state ? state.results.map((d) => d.id) : [];

  useEffect(() => {
    if (initialized) {
      dispatch({
        type: "FETCHING",
        id,
      });

      if (res && res.data) {
        if (
          res.meta.pagination.current_page >= res.meta.pagination.total_pages
        ) {
          dispatch({
            type: "NO_MORE",
            id,
          });
        }
        //filtering these results for initial mount where already queried results get added again :/
        //feels a little wrong, but how?
        const results = res.data.filter(
          ({ id }) => !currentIds.current.includes(id)
        );
        dispatch({
          type: "ADD_RESULTS",
          id,
          value: results,
        });
      }
    }
  }, [res, id, dispatch, initialized]);

  const dispatchWithId = useCallback(
    (action) => {
      dispatch({ ...action, id });
    },
    [dispatch, id]
  );

  return [state, dispatchWithId];
};

const Search = ({ history }) => {
  useTheme("lightBlue");
  const [state, dispatch] = useStream(ID, { pageSize: 12 });
  useEffect(() => {
    if (state && state.results && state.page === 1) {
      window.scrollTo(0, 0);
    }
  }, [state]);

  if (!state) {
    return null;
  }

  return (
    <div className="Search">
      <div className="Search-inputs receives-theme">
        <label className="Search-inputs-query">
          <SearchIcon />
          <SearchQuery
            state={state}
            dispatch={dispatch}
            placeholder={"e.g. pretrial services, bail reform"}
            className="Search-inputs-query-input"
            autoFocus
          />
        </label>
      </div>
      {state.query && <Results state={state} dispatch={dispatch} />}
    </div>
  );
};

export default Search;

export const SearchQuery = ({
  state,
  dispatch,
  placeholder,
  className,
  autoFocus = false,
}) => {
  const updateQuery = useCallback((query) => dispatch(setQuery(query)), [
    dispatch,
  ]);
  const [inputQuery, setInputQuery] = useState(state ? state.query : "");

  useEffect(() => {
    const timer = setTimeout(() => {
      updateQuery(inputQuery);
    }, 200);

    return () => {
      clearTimeout(timer);
    };
  }, [inputQuery, updateQuery]);

  return (
    <input
      type="text"
      placeholder={placeholder}
      className={className}
      autoFocus={autoFocus}
      value={inputQuery}
      onChange={(event) => setInputQuery(event.target.value)}
    />
  );
};
