export const storeData = (endpoint,data) => ({
  type: "STORE_DATA",
  endpoint,
  data
})

export const fetchData = endpoint => {
  return (dispatch, getState) => {
    return fetch(endpoint)
      .then(response => response.json())
      .then(data => {
        dispatch(storeData(endpoint,data))
      })
      .catch(err => {
        console.log(endpoint,err)
      })
  }
}

export const setTheme = value => ({
  type: "SET_THEME",
  value
})

export const setResourcesBar = value => ({
  type: "SET_RESOURCES_BAR",
  value
})