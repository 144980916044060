import React from "react";
import Image from "./Image";
import Link from "components/Link";

const ExplainerLink = ({ heading, description, image, link }) => {
  return (
    <div className={"ExplainerLink"}>
      <Link {...link}>
        <Image className="ExplainerLink-image" image={image} />
        <div className={"ExplainerLink-heading"}>{heading}</div>
        <div className={"ExplainerLink-description"}>{description}</div>
      </Link>
    </div>
  );
};

export default ExplainerLink;
