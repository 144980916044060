import React from "react";

const BarLabel = ({ variable, value, type, sliceBy }) => (
  <div className={`BarLabel BarLabel--${type}`}>
    {variable && (
      <div className="BarLabel-key">{sliceBy ? sliceBy : variable}</div>
    )}
    {value && <div className="BarLabel-value">{value}</div>}
  </div>
);

export default BarLabel;
