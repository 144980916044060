import React from 'react'
import { H, HDown } from 'Heading'
import Link from 'components/Link'
import RichText from 'components/rich-text/rich-text'
const ThingsItem = ({ heading, text, link, links }) => {
  return (
    <HDown>
      <li className="Things-item">
        <H className="Things-item-heading">{heading}</H>
        <RichText className="Things-item-text" text={text} />
        <ul className="Things-item-links">
          {links &&
            links.length > 0 &&
            links.map(link => (
              <li key={link.url}>
                <Link className="Things-item-link" {...link} />
              </li>
            ))}
        </ul>
      </li>
    </HDown>
  )
}

export default ThingsItem
