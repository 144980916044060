import React from 'react'
import { useFetchStore } from 'Fetch'
import Image from 'blocks/Image'
import Loading from 'components/Loading'
import Container from 'Container'
import { HDown, H } from 'Heading'
import Stream from 'blocks/Stream'
import './Person.scss'
import Email from 'svgr/Email'
import useTheme from 'useTheme'

const Person = ({ match }) => {
  const data = useFetchStore(
    `${process.env.REACT_APP_API}/person/${match.params.slug}.json`
  )

  useTheme('white')

  return data ? (
    <>
      <div className="Person max">
        <Image className="Person-image" image={data.image} />
        <div className="Person-name">{data.title}</div>
        <div className="Person-title">{data.jobTitle}</div>
        {data.email && (
          <a
            className="Person-email"
            href={`mailto:${data.email}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Email />
            {data.email}
          </a>
        )}
        <div
          className="Person-bio"
          dangerouslySetInnerHTML={{ __html: data.text }}
        />
      </div>
      {data.relatedWork.length > 0 && (
        <Container className="PersonWork">
          <HDown>
            <H className="Heading">Related Work</H>
            <Stream entryIds={data.relatedWork} uid={data.id} />
          </HDown>
        </Container>
      )}
    </>
  ) : (
    <Loading />
  )
}

export default Person
