import React, { useEffect, useState } from 'react'
import { scaleLinear, scaleBand } from 'd3-scale'
import { max } from 'd3-array'
import { timeFormat, timeParse } from 'd3-time-format'
import colors from 'data-colors'
import { Axis, axisPropsFromTickScale, LEFT } from 'react-d3-axis'
import { format } from 'd3-format'
import Key from 'components/data-viz/key'
import Loading from 'components/Loading'
import './bars.scss'
import { useSpring, a } from 'react-spring'
import { scaleModes } from '../data-viz/constants'
import { formatSI } from 'format-si-prefix'
import ScaleToggle from '../data-viz/scale-toggle'
import styles from './bars.module.scss'
import useMeasure from 'hooks/useMeasure'
import Tooltip from 'react-tooltip'

const pad = 0.05
const bottomPad = 30
const barPad = bottomPad * (1 + pad)
const topPad = 30
const quarterColumns = ['arrest_quarter', 'Quarter']

const parseDate = d => {
  return timeParse('%Y-%m-%d')(d)
}
const formatMonth = (d, col, isLast) => {
  if (col === 'Month') {
    return (
      <>
        <text>{timeFormat('%b')(parseDate(d[col]))}</text>

        {isJan(d, col) && (
          <text fontSize="1.5em" dy="1em">
            {timeFormat('%Y')(parseDate(d[col]))}
          </text>
        )}
      </>
    )
  } else if (col === 'Quarter') {
    const split = d[col].split(' - ')

    return (
      <>
        <text>{split[1]}</text>
        {split[1] === 'Q1' && (
          <text fontSize="1.5em" dy="1em">
            {split[0]}
          </text>
        )}
      </>
    )
  } else if (col === 'arrest_quarter') {
    const split = d[col].split(' - ')
    return (
      <>
        <text>{split[0]}</text>
        {split[0] === 'Q1' && (
          <text dy="1em" className={styles.quarterYear}>
            {split[1]}
            {/* {isLast ? `'${split[1].slice(2)}` : split[1]} */}
          </text>
        )}
      </>
    )
    // return <text>{d[col].replace(/\s-\s20/, " - ")}</text>;
  } else if (col === 'Year of Arrest') {
    return (
      <text fontSize="1.5em" dy=".2em">
        {d[col]}
      </text>
    )
  }
}
const isJan = (d, col) => {
  if (col === 'Month') {
    return parseDate(d[col]).getMonth() === 0
  } else if (quarterColumns.includes(col)) {
    return d[col].split(' - ')[0] === 'Q1'
  }
}
const formatNumber = d => format(',')(d)

function findKeyData(fields, current) {
  let result
  let reversed

  const variable = fields.find(d => {
    if (d.label && d.label === current) {
      return true
    }
    return d.columnName === current
  })
  if (variable) {
    result = variable.options
    reversed = variable.reversed || true
  }
  return [result, reversed]
}

function getLeftPad(maximum, scaleMode) {
  if (scaleMode === scaleModes.RELATIVE) {
    return 15 * 4.5
  }
  return (
    15 *
    (maximum < 10
      ? 3
      : maximum < 100
      ? 3.5
      : maximum < 1000
      ? 4
      : maximum < 10000
      ? 4.5
      : maximum < 100000
      ? 5
      : maximum < 1000000
      ? 6
      : 7)
  )
}

// const MIN_WIDTH = 700;
const Bars = ({
  data = [],
  allVariables,
  label,
  column,
  filters,
  loading,
  scaleMode,
  setScaleMode,
  sliceBy,
  glossaryDownload,
  yAxisLabel = 'Number of People',
  tooltipUnit,
  borough,
  followUp,
  numberOfArrests,
  timeColumn = 'Month',
  showFilteredInKey,
  xDomain,
  isStaticColumn,
  isDesktop,
}) => {
  const [chartWrapRef, { width: measuredWidth, height }] = useMeasure()
  const width = measuredWidth
  const hasData = data.length > 0
  const currentColumn = sliceBy ? sliceBy : column
  const yearsToDisplay = width > 800 ? 3 : 1

  const allDatesLastToFirst = xDomain
    ? xDomain
    : data
        .map(d => d[timeColumn])
        .filter((d, i, a) => a.indexOf(d) === i)
        .reverse()

  const firstDate = new Date(allDatesLastToFirst[0])
  const lastDate = new Date(allDatesLastToFirst[allDatesLastToFirst.length - 1])
  const threeYearsBeforeLastDate = new Date(
    lastDate.setFullYear(lastDate.getFullYear() - yearsToDisplay)
  )

  const { year, nextYear, previousYear, setYear } = useYear(
    threeYearsBeforeLastDate.getFullYear()
  )

  const threeYearsBeforeLastDateYear = threeYearsBeforeLastDate.getFullYear()

  useEffect(() => {
    setYear(threeYearsBeforeLastDateYear)
  }, [threeYearsBeforeLastDateYear, setYear])

  const maxYear = lastDate.getFullYear()
  const minYear = firstDate.getFullYear()

  const showPrevNextYearControls = timeColumn === 'Month'

  // maybe this can be removed if we're fetching all
  let xDomainValues = xDomain
    ? xDomain
    : data.map(d => d[timeColumn]).filter((d, i, a) => a.indexOf(d) === i)

  if (timeColumn === 'Month' && !isNaN(lastDate.getTime()) && !isNaN(year)) {
    const currentStartDate = new Date(lastDate.setFullYear(year))
    const threeYearsAgo = currentStartDate.toISOString().replace(/T.*$/g, '')
    const chartEndDate = new Date(lastDate.setFullYear(year + yearsToDisplay))
      .toISOString()
      .replace(/T.*$/g, '')

    data = data.reduce((r, d) => {
      if (d.Month > threeYearsAgo && d.Month <= chartEndDate) {
        r.push(d)
      }

      return r
    }, [])

    xDomainValues = xDomainValues.filter(date => {
      return date > threeYearsAgo && date <= chartEndDate
    })
  }

  const [keyData, reversed] = findKeyData(allVariables, currentColumn)

  const groupedData = Object.values(
    data.reduce((r, d) => {
      if (!r[d[timeColumn]]) {
        r[d[timeColumn]] = []
      }
      r[d[timeColumn]].push(d)

      return r
    }, {})
  ).map(group => {
    const values = group.map(d => d[currentColumn])
    const missing = keyData.reduce((r, d) => {
      if (!values.includes(d.value)) {
        r.push({
          [currentColumn]: d.value,
          value: null,
          [timeColumn]: group[0][timeColumn],
        })
      }
      return r
    }, [])
    return [...missing, ...group]
  })

  const groupSums = groupedData.map(d => {
    //add calculated total of each group
    return d.reduce((r, d) => {
      return r + d.value
    }, 0)
  })

  const maximum = max(groupSums)
  const scaleY = scaleLinear()
    .domain([0, maximum])
    .range([0, height - barPad - topPad])

  const leftPad = getLeftPad(maximum, scaleMode)

  const scaleX = scaleBand()
    .domain(xDomainValues)
    .range([leftPad, width])
    .padding(pad)

  const bandwidth = scaleX.bandwidth()

  const [selectedBar, setSelectedBar] = useState(null)

  return (
    <>
      <section
        className={`StackedBars ${loading ? 'StackedBars--loading' : ''}`}
      >
        <div className={styles.controlsKey}>
          {hasData && (
            <Key
              data={keyData}
              filters={filters}
              variable={column}
              columnLabel={label}
              sliceBy={sliceBy}
              reversed={reversed}
              borough={borough}
              followUp={followUp}
              numberOfArrests={numberOfArrests}
              showFiltered={showFilteredInKey}
              isStaticColumn={isStaticColumn}
            />
          )}
          <div className={styles.controlsKeyControls}>
            {glossaryDownload[0] && (
              <a
                href={glossaryDownload[0].url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Glossary
              </a>
            )}
            <ChartControls
              setScaleMode={setScaleMode}
              currentScaleMode={scaleMode}
              glossaryDownload={glossaryDownload}
            />
          </div>
        </div>
        {maximum < 100 && <SmallSampleNote />}
        <div
          className={`StackedBars-chartwrap ${
            showPrevNextYearControls
              ? 'StackedBars-chartwrap--with-controls'
              : ''
          }`}
          ref={chartWrapRef}
        >
          {hasData && (
            <>
              <svg
                viewBox={`0 0 ${width} ${height}`}
                width={width}
                height={height}
              >
                <YAxis
                  label={
                    yAxisLabel && scaleMode === scaleModes.RELATIVE
                      ? yAxisLabel.replace('Number', 'Percent')
                      : yAxisLabel
                  }
                  scale={scaleY}
                  scaleMode={scaleMode}
                  height={height}
                  leftPad={leftPad}
                />
                <XAxis
                  xDomain={xDomainValues}
                  scaleX={scaleX}
                  height={height}
                  column={timeColumn}
                  selectedBar={selectedBar}
                  isDesktop={isDesktop}
                />
                {groupedData.map((d, i) => {
                  const thisScaleY =
                    scaleMode === scaleModes.RELATIVE
                      ? scaleY.copy().domain([0, groupSums[i]])
                      : scaleY

                  //Sort data
                  const sorted = keyData.reduce((r, { value }) => {
                    const match = d.find(
                      props => props[currentColumn] === value
                    )
                    if (match) {
                      r.push(match)
                    }
                    return r
                  }, [])

                  if (!sorted.length) {
                    return null
                  }

                  return (
                    <StackedBar
                      key={d[0][timeColumn]}
                      data={sorted}
                      scaleY={thisScaleY}
                      scaleX={scaleX}
                      bandwidth={bandwidth}
                      pad={pad}
                      column={column}
                      sliceBy={sliceBy}
                      reversed={reversed}
                      height={height}
                      tooltipUnit={tooltipUnit}
                      timeColumn={timeColumn}
                      setSelectedBar={() =>
                        setSelectedBar(selectedBar === i ? null : i)
                      }
                      isSelected={!isDesktop && selectedBar === i}
                    />
                  )
                })}
              </svg>
            </>
          )}
        </div>

        {showPrevNextYearControls && (
          <div className={styles.yearControls}>
            <button
              className={styles.yearButton}
              onClick={() => previousYear()}
              disabled={year <= minYear}
            >
              ← {year}
            </button>
            <button
              className={styles.yearButton}
              onClick={() => nextYear()}
              disabled={year >= maxYear}
            >
              {year + (yearsToDisplay + 1)} →
            </button>
          </div>
        )}
        <div className="StackedBars-loader" />
        <Loading />
        <Tooltip
          id="bars-tooltip"
          className={`CATTooltip${isDesktop ? '' : '--mobile'}`}
          place={'left'}
          effect="solid"
        />
      </section>
      {selectedBar !== null && !isDesktop && (
        <MobileDetailView
          d={groupedData[selectedBar]}
          keyData={keyData}
          currentColumn={currentColumn}
          sliceBy={sliceBy}
          column={column}
          tooltipUnit={tooltipUnit}
          timeColumn={timeColumn}
          close={() => setSelectedBar(null)}
        />
      )}
    </>
  )
}

export default Bars

function MobileDetailView({
  d,
  keyData,
  currentColumn,
  sliceBy,
  column,
  tooltipUnit,
  timeColumn,
  close,
}) {
  const sorted = keyData.reduce((r, { value }) => {
    const match = d.find(props => props[currentColumn] === value)
    if (match) {
      r.push(match)
    }
    return r
  }, [])
  const tooltips = makeTooltips(sorted, sliceBy ? sliceBy : column)
  const displayMonth = getDisplayDate(d[0], timeColumn)

  return (
    <div className="MobileCatTooltip">
      <div className="tooltip-date">
        {displayMonth}{' '}
        <button onClick={close} className="MobileCatTooltip-close">
          <svg
            viewBox="0 0 18.05 18.05"
            width="18.05"
            height="18.05"
            stroke="#000"
          >
            <g>
              <line class="st0" x1="4.52" y1="4.52" x2="13.52" y2="13.52" />
              <line class="st0" x1="13.52" y1="4.52" x2="4.52" y2="13.52" />
            </g>
          </svg>
        </button>
      </div>
      <ul className={'column'}>
        {tooltips.items.map(({ label, value, percent }, i) => {
          if (!label || value === null) {
            return null
          }
          return label ? (
            <li class="item">
              <div class="key">
                <div class="color" style={{ color: colors[i] }}></div>
                {label}
              </div>
              <div class="value">
                {formatNumber(value)} ({percent})
              </div>
            </li>
          ) : (
            <li>{value}</li>
          )
        })}
      </ul>
      <div class="total">
        <div class="key total-key">Total</div>
        <div class="value">{formatNumber(tooltips.total)}</div>
      </div>
    </div>
  )
}

function useYear(startValue) {
  const [year, setYear] = useState(startValue)

  useEffect(() => {
    if (isNaN(year) && !isNaN(startValue)) {
      setYear(startValue)
    }
  }, [startValue, year])

  function nextYear() {
    setYear(year + 1)
  }
  function previousYear() {
    setYear(year - 1)
  }

  return {
    year,
    nextYear,
    previousYear,
    setYear,
  }
}

const useFadeIn = () => {
  return useSpring({
    config: { mass: 50, tension: 280, friction: 120 },
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  })
}

function SmallSampleNote() {
  return (
    <div className={styles.rangeNote}>
      Note: Selected population size is less than 100. Results should be
      interpreted with caution.
    </div>
  )
}

const XAxis = ({ xDomain, scaleX, height, column, selectedBar, isDesktop }) => {
  return (
    <g transform="translate(0,2)">
      {xDomain.map((d, i) => {
        return (
          <XAxisItem
            key={d}
            value={d}
            scaleX={scaleX}
            height={height}
            column={column}
            isLast={i === xDomain.length - 1}
            isSelected={selectedBar === i && !isDesktop}
          />
        )
      })}
    </g>
  )
}

const XAxisItem = ({ value, scaleX, height, column, isLast, isSelected }) => {
  const d = { [column]: value }
  const props = useFadeIn()

  return (
    <a.g
      // fontWeight={jan ? 700 : 400}
      className={isSelected ? styles.selectedDate : styles.date}
      style={props}
      transform={`translate(${scaleX(value)} ${height - barPad + 10})`}
      // textAnchor="end"
    >
      {formatMonth(d, column, isLast)}
    </a.g>
  )
}

const YAxis = ({ label, scale, scaleMode, height, leftPad }) => {
  const scaleCopy = scale.copy()
  let newScale
  if (scaleMode === scaleModes.RELATIVE) {
    newScale = scaleCopy.domain([1, 0])
  } else if (scaleMode === scaleModes.ABSOLUTE) {
    newScale = scaleCopy.domain(scaleCopy.domain().reverse())
  }
  const props = useFadeIn()
  const thisFormat =
    scaleMode === scaleModes.ABSOLUTE
      ? scaleCopy.domain()[0] < 1000
        ? d => {
            //if the top is under 1k, dont use SI and output nothing for fractional axis labels
            if (Math.floor(d) !== d) {
              return
            }
            return format('d')(d)
          }
        : formatSI
      : format('.0%')

  return (
    <a.g {...props}>
      {scaleMode === scaleModes.ABSOLUTE && (
        <text
          x={leftPad - 16}
          y={topPad - 10}
          dominantBaseline="bottom"
          textAnchor="end"
          fontSize={17}
          fontWeight={700}
        >
          {format(',.0f')(newScale.domain()[0] || 0)}
        </text>
      )}
      <text
        transform={`translate(${15} ${height / 2}) rotate(-90)`}
        textAnchor="middle"
      >
        {label}
      </text>
      <g
        transform={`translate(${leftPad - 10}, ${topPad})`}
        className={styles.yGroup}
      >
        <Axis
          {...axisPropsFromTickScale(newScale, 10)}
          style={{ orient: LEFT }}
          format={thisFormat}
        />
      </g>
    </a.g>
  )
}

function getDisplayDate(d, col) {
  if (col === 'Month') {
    const dateParts = d[col].split('-')
    const currentDateTime = new Date(
      dateParts[0],
      +dateParts[1],
      dateParts[2] - 1
    )
    return currentDateTime.toLocaleString('en-us', {
      month: 'long',
      year: 'numeric',
    })
  } else if (quarterColumns.includes(col)) {
    return d[col]
  } else if (col === 'Year of Arrest') {
    return d[col]
  }
}
const StackedBar = ({
  data,
  scaleY,
  scaleX,
  bandwidth,
  column,
  sliceBy,
  reversed,
  height,
  tooltipUnit = 'people',
  timeColumn,
  pad,
  setSelectedBar,
  isSelected,
}) => {
  let yOff = height - barPad
  const tooltips = makeTooltips(data, sliceBy ? sliceBy : column)
  const usedColors = [...colors.slice(0, data.length)]
  const directionalColors = reversed ? usedColors : usedColors.reverse()
  const directionalData = reversed ? data : data.reverse()
  const displayMonth = getDisplayDate(data[0], timeColumn)

  useEffect(() => {
    Tooltip.rebuild()
  }, [])

  const tooltipContent =
    tooltips &&
    `
<div>
  <p class="tooltip-date">${displayMonth}</p>
  <ul class="${tooltips.items.length > 6 ? 'grid' : 'column'}">
  ${tooltips.items
    .map(({ label, value, percent }, i) => {
      if (!label || value === null) {
        return null
      }
      return label
        ? `<li class="item">
      <div class="key"><div class="color" style="color: ${
        colors[i]
      }"></div>${label}</div>
      <div class="value">${formatNumber(value)} (${percent})</div></li>`
        : `<li>${value}</li>`
    })
    .join('')}
  </ul>
  <div class="total">
    <div class="key total-key">Total</div>
    <div class="value">${formatNumber(tooltips.total)}</div>
  </div>
</div>
`

  return (
    <g
      className={
        isSelected
          ? 'PretrialRelease-bargroup--selected'
          : 'PretrialRelease-bargroup'
      }
    >
      {directionalData.map((d, i) => {
        if (d.value === null) {
          return null
        }
        const y = scaleY(d.value)
        yOff -= y
        const key = `${d[timeColumn]}${d[column]}${d[sliceBy]}`
        return (
          <AnimatedRect
            key={key}
            x={scaleX(d[timeColumn])}
            width={bandwidth}
            y={yOff + 1.5}
            height={Math.max(0, y - 1.5)}
            fill={directionalColors[i]}
            bottom={height}
          />
        )
      })}
      <rect
        width={bandwidth * (1 + pad)}
        height={height}
        y={0}
        x={scaleX(directionalData[0][timeColumn]) - (bandwidth * pad) / 2}
        data-html
        data-for="bars-tooltip"
        data-tip={tooltipContent}
        fill="transparent"
        onClick={setSelectedBar}
      />
    </g>
  )
}

const makeTooltips = (data, key) => {
  const total = sumAll(data)
  const items = data.map(d => {
    return {
      label: d[key],
      value: d.value,
      percent: `${Math.round((10000 * d.value) / total) / 100}%`,
    }
  })
  return {
    total,
    items,
  }
}

function sumAll(d) {
  return d.reduce((r, d) => {
    return r + d.value
  }, 0)
}

const AnimatedRect = ({ x, y, width, height, fill, bottom }) => {
  const props = useSpring({
    from: { width: 0, height: 0, x, y: bottom - bottomPad, fill },
    to: {
      x,
      y,
      width,
      height,
      fill,
    },
  })
  return <a.rect {...props} />
}

const ChartControls = ({ currentScaleMode, setScaleMode }) => {
  return <ScaleToggle value={currentScaleMode} set={setScaleMode} />
}
