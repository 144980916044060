import React from "react";
import InnerHTML from "dangerously-set-html-content";
import "./code.scss";

const Code = ({ code, caption }) => {
  return (
    <div className="Code">
      <InnerHTML html={code} />
      {caption && (
        <div
          className="Code-caption"
          dangerouslySetInnerHTML={{ __html: caption }}
        />
      )}
    </div>
  );
};

export default Code;
