import React, { useEffect, useState, useReducer } from 'react'
// import PretrialReleaseDashboard from 'components/pretrial-release-dashboard'
import ArrestDashboardBlock from 'blocks/ArrestDashboard'
import NewOffenseDashboardBlock from 'blocks/new-offense-dashboard'
import NewOffenseDashboard from 'components/pretrial-release-dashboard/new-offense-dashboard'
import styles from './dashboard-switcher.module.scss'
import { useFetchStore } from 'Fetch'
import RichText from 'components/rich-text'
import useMeasure from 'react-use-measure'
import PeopleArrestsDashboardBlock from 'blocks/PeopleArrestsDashboardBlock'
import RightArrow from 'components/right-carrot'
import useTheme from 'useTheme'
import { scaleModes } from 'components/data-viz/constants'
import ReactTooltip from 'react-tooltip'
import useFetch from 'hooks/useFetch'
import SupportiveServicesDashboardBlock, {
  ViewSwitcher,
} from 'blocks/SupportiveServicesDashboardBlock'

// import { Link } from 'react-router-dom'
// import { useLocation } from 'react-router-dom'

const components = {
  PretrialReleaseDashboardBlock,
  ArrestDashboardBlock,
  NewOffenseDashboardBlock,
  PeopleArrestsDashboardBlock,
  SupportiveServicesDashboardBlock,
  ProsecutedCriminalCasesDashboardBlock: function(props) {
    return <ArrestDashboardBlock {...props} resultsEndpoint="prosecuted-results" resetOnViewChange />
  }
}

const DashboardSwitcher = ({
  selectedDashboard,
  isEmbed,
  settingsJson,
  showControls = true,
  ...rest
}) => {
  const { data: globalData } = useFetch('/api/dashboard-globals')

  const [current, setCurrent] = useState(
    selectedDashboard && selectedDashboard !== 'none'
      ? selectedDashboard + 'Block'
      : null
  )

  const dashboards = globalData
    ? globalData.dashboardSwitcher.reduce((r, d) => {
        r[d.component] = { ...d, component: components[d.component] }
        return r
      }, {})
    : {}

  const currentDashboard = dashboards[current]
  const CurrentDashboard = currentDashboard?.component

  const [menuOpen, setMenuOpen] = useState(false)
  const toggleMenu = () => setMenuOpen(!menuOpen)

  useEffect(() => {
    dispatch({
      type: 'reset',
    })
    ReactTooltip.rebuild()
  }, [current])

  useHideOlark()
  useTheme('white')

  const [isDesktop, ref] = useIsDesktop()
  const storageKey = currentDashboard?.resultsEndpoint
  const initialState = settingsJson
    ? JSON.parse(settingsJson)
    : initial(storageKey)
  const [state, dispatch] = useReducer(reducer, initialState)
  const isLoaded = globalData && !!globalData.dashboardSwitcher

  return (
    <article
      className={`${styles.element} ${
        isDesktop ? 'CAT--desktop' : 'CAT--mobile'
      }`}
      ref={ref}
    >
      {isLoaded && (
        <div className={styles.content}>
          {!isEmbed && currentDashboard && (
            <Header
              toggleMenu={toggleMenu}
              currentDashboard={currentDashboard}
              menuOpen={menuOpen}
            />
          )}
          {menuOpen || !current ? (
            <SwitcherMenu
              setMenuOpen={setMenuOpen}
              currentDashboard={currentDashboard}
              setCurrent={setCurrent}
              dashboards={dashboards}
            />
          ) : (
            <>
              {!isEmbed && <About {...currentDashboard} />}
              <DashboardWrap
                key={current}
                title={currentDashboard.heading}
                id={currentDashboard.id}
              >
                {currentDashboard && (
                  <CurrentDashboard
                    title={currentDashboard.heading}
                    {...currentDashboard}
                    isDesktop={isDesktop}
                    state={state}
                    dispatch={dispatch}
                    showControls={showControls}
                    {...rest}
                  />
                )}
              </DashboardWrap>
              <DashboardNotes />

              {/* {!isEmbed && Object.keys(dashboards).length > 0 && (
                <SwitcherMenu
                  setMenuOpen={setMenuOpen}
                  currentDashboard={currentDashboard}
                  setCurrent={setCurrent}
                  showMoreCta
                  dashboards={dashboards}
                />
              )} */}
            </>
          )}
        </div>
      )}
    </article>
  )
}

export default DashboardSwitcher

export const reducer = (state, action) => {
  switch (action.type) {
    case 'reset':
      return {
        ...initial(),
        variables: [],
        numberOfArrests: [],
      }
    case 'setYear':
      return {
        ...state,
        year: action.value,
      }
    case 'addVariable':
      return {
        ...state,
        variables: [
          ...state.variables,
          newVariable(state.variables.length + 1),
        ],
      }

    case 'setVariable':
      const newVariables = [...state.variables]
      newVariables[action.index] = {
        ...newVariables[action.index],
        value: action.value,
        filters: [],
      }

      return {
        ...state,
        variables: newVariables,
        sliceBy: null,
      }

    case 'setVariables':
      return {
        ...state,
        variables: action.value,
      }

    case 'setSliceBy':
      return {
        ...state,
        sliceBy: action.value,
      }

    case 'resetVariables':
      return {
        ...state,
        variables: [],
        sliceBy: initialState.sliceBy,
        borough: initialState.borough,
        followUp: initialState.followUp,
        numberOfArrests: initialState.numberOfArrests,
      }

    case 'removeVariable': {
      return {
        ...state,
        variables: state.variables.filter((d, i) => action.variable !== i),
      }
    }

    case 'toggleFilter':
      if (state.variables[action.index]) {
        state.variables[action.index] = {
          ...state.variables[action.index],
          filters: state.variables[action.index].filters.includes(action.value)
            ? state.variables[action.index].filters.filter(
                d => d !== action.value
              )
            : [...state.variables[action.index].filters, action.value],
        }
      } else {
        state.variables[action.index] = {
          filters: [action.value],
          value: action.key,
        }
      }

      return {
        ...state,
      }

    case 'resetFilters':
      state.variables[action.index] = {
        ...state.variables[action.index],
        filters: [],
      }
      return {
        ...state,
        sliceBy: null,
      }
    
    case 'resetAllFilters':
      return {
        ...state,
        variables: [],
        sliceBy: null,
      }

    case 'toggleVisualized':
      state.variables[action.index] = {
        ...state.variables[action.index],
        visualize: action.value,
      }
      return {
        ...state,
      }

    case 'setView':
      return {
        ...state,
        view: action.value,
      }

    case 'setScaleMode':
      return {
        ...state,
        scaleMode: action.value,
      }

    case 'loadingResults':
      return {
        ...state,
        loadingResults: true,
        errorResults: false,
      }

    case 'doneLoading':
      return {
        ...state,
        loadingResults: false,
      }

    case 'errorResults':
      return {
        ...state,
        errorResults: action.value,
      }

    case 'setBorough':
      return {
        ...state,
        borough: action.value,
      }

    case 'setFollowUp':
      return {
        ...state,
        followUp: action.value,
      }

    case 'setNumberOfArrests':
      if (action.value === 'All') {
        return {
          ...state,
          numberOfArrests: [],
        }
      }
      if (state.numberOfArrests.includes(action.value)) {
        state.numberOfArrests.splice(
          state.numberOfArrests.indexOf(action.value),
          1
        )
        return {
          ...state,
          numberOfArrests: [...state.numberOfArrests],
        }
      }
      return {
        ...state,
        numberOfArrests: [...state.numberOfArrests, action.value],
      }

    case 'setDataView':
      // resets filter to all if selected as the view
      const viewIsFilteredIndex = state.variables.findIndex(
        d => d && d.value === action.value
      )
      const newDataVariables = state.variables
      if (viewIsFilteredIndex > -1) {
        newDataVariables[viewIsFilteredIndex] = {
          ...newDataVariables[viewIsFilteredIndex],
          filters: [],
        }
      }

      return {
        ...state,
        dataView: action.value,
        variables: newDataVariables,
      }

    case 'setDataViewAndClearFilters':
      return {
        ...state,
        borough: 'All',
        followUp: '6 months',
        numberOfArrests: [],
        variables: [],
        dataView: action.value,
      }

    case 'setSubView':
      return {
        ...state,
        subView: action.value,
      }

    case 'setSubViewAndClearFilters':
      return {
        ...state,
        borough: 'All',
        followUp: '6 months',
        numberOfArrests: [],
        variables: [],
        subView: action.value,
      }

    default:
      throw new Error(`unknown action type: ${action.type}`)
  }
}

function newVariable(key) {
  return {
    key,
    value: null,
    filters: [],
    visualize: true,
  }
}

const initialState = {
  variables: [],
  view: 'Bars',
  scaleMode: scaleModes.ABSOLUTE,
  loadingResults: true,
  errorResults: false,
  borough: 'All',
  followUp: '6 months',
  numberOfArrests: [],
  subView: 'filter',
  sliceBy: null,
}

const initial = storageKey =>
  process.env.NODE_ENV === 'development' &&
  typeof window !== 'undefined' &&
  localStorage.getItem(storageKey)
    ? JSON.parse(localStorage.getItem(storageKey))
    : initialState

function useIsDesktop() {
  const [ref, { width }] = useMeasure()
  return [width >= 700, ref]
}

function useHideOlark() {
  useEffect(() => {
    let olark

    const olarkObserver = new MutationObserver(function(list) {
      list.forEach(mutation => {
        mutation.addedNodes.forEach(function(node) {
          olark = document.querySelector('.olark-launch-button')
          if (olark) {
            olark.style.display = 'none !important'
            olarkObserver.disconnect()
          }
        })
      })
    })

    const bodyObserver = new MutationObserver(function(mutations_list) {
      mutations_list.forEach(function(mutation) {
        mutation.addedNodes.forEach(function(added_node) {
          if (added_node.id === 'hbl-live-chat-wrapper') {
            bodyObserver.disconnect()
            olarkObserver.observe(added_node, {
              subtree: true,
              childList: true,
            })
          }
        })
      })
    })

    bodyObserver.observe(document.querySelector('body'), {
      subtree: false,
      childList: true,
    })

    return () => {
      bodyObserver.disconnect()
      olarkObserver.disconnect()
      if (olark) {
        olark.style.display = ''
      }
    }
  }, [])
}

function DashboardWrap({ children, title, id }) {
  // const [showAnchorLink, setShowAnchorLink] = useState(false)
  // const ref = useRef()
  // useEffect(() => {
  //   function onScroll() {
  //     if (ref.current) {
  //       const scrollBottom = window.scrollY + window.innerHeight
  //       const chartTop =
  //         window.scrollY + ref.current.getBoundingClientRect().top
  //       setShowAnchorLink(scrollBottom < chartTop)
  //     }
  //   }

  //   onScroll()

  //   window.addEventListener('scroll', onScroll)
  //   window.addEventListener('resize', onScroll)
  //   return () => {
  //     window.removeEventListener('scroll', onScroll)
  //     window.removeEventListener('resize', onScroll)
  //   }
  // }, [])
  // const location = useLocation()
  // useEffect(() => {
  //   console.log(location, id)
  //   setTimeout(() => {
  //     onLoad()
  //   }, 4000)

  //   function onLoad() {
  //     const newHref = `#data-${id}`
  //     const dataLink = document.querySelector('[href="#data"]')
  //     console.log('data', dataLink)
  //     if (dataLink) {
  //       dataLink.setAttribute('href', newHref)
  //     } else {
  //       const existingLink = document.querySelector('[href^="#data-"]')
  //       existingLink && existingLink.setAttribute('href', newHref)
  //     }
  //   }

  //   // return () => window.clearTimeout(timer)
  // }, [location, id])

  return (
    <div id={`data`} className={styles.dashboardWrap}>
      <div className={styles.dashboardContent}>
        <h1
          // ref={ref}
          className={title.length > 85 ? styles.longTitle : styles.title}
        >
          {title}
        </h1>
        {children}
      </div>
      {/* {showAnchorLink && (
        <a
          href={'#data'}
          className={styles.dataAnchor}
          onClick={() => {
            ref.current.scrollIntoView({
              behavior: 'smooth',
            })
          }}
        >
          View Data ↓
        </a>
      )} */}
    </div>
  )
}

const Header = ({ toggleMenu, currentDashboard, menuOpen }) => {
  const heading =
    currentDashboard && !menuOpen
      ? currentDashboard.heading
      : 'NYC Pretrial Data'

  return (
    <header className={styles.header}>
      <div className={styles.headerInner}>
        <div className={styles.switcher}>
          <div className={styles.chartHeading}>
            {!menuOpen && <p>NYC Pretrial Data</p>}
            <h1 className={styles.heading}>{heading}</h1>
          </div>
        </div>
        {currentDashboard && (
          <button onClick={toggleMenu} className={styles.toggleMenu}>
            {!menuOpen ? 'Switch Dashboards' : 'Close Menu'}
          </button>
        )}
      </div>
    </header>
  )
}

const SwitcherMenu = ({
  setCurrent,
  currentDashboard,
  setMenuOpen,
  showMoreCta,
  dashboards,
}) => {
  return (
    <div className={styles.switcherMenu}>
      {showMoreCta && <p>More NYC Pretrial Data</p>}
      <ul className={styles.switcherMenuList}>
        {Object.keys(dashboards).map(key => {
          const { heading, description, entry } = dashboards[key]
          const isCurrent =
            heading === (currentDashboard && currentDashboard.heading)

          return (
            <li key={key}>
              <a
                href={`/${entry.uri}`}
                className={
                  isCurrent
                    ? styles.currentSwitcherMenuButton
                    : styles.switcherMenuButton
                }
              >
                <h2 className={styles.switcherMenuButtonHeading}>
                  {heading} {!isCurrent && <RightArrow />}
                </h2>
                <p className={styles.switcherMenuButtonDescription}>
                  {description}
                </p>
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

const About = ({ variableEndpoint }) => {
  const content = useFetchStore(
    `${process.env.REACT_APP_API}/${variableEndpoint}.json`
  )
  const hasAbout = content && content.about
  const hasFacts =
    content && content.quickFacts && content.quickFacts.length > 0

  return (
    <article className={styles.about}>
      <div className={styles.aboutInner}>
        <div className={styles.aboutMain}>
          <section id="about" aria-label="About this data">
            {hasAbout && <RichText text={content.about} />}
          </section>
        </div>
        <nav className={styles.aboutJump}>
          {hasFacts && (
            <section id="facts" className={styles.facts}>
              <h2 className={styles.factHeading}>{ content.keyFactsHeading ? content.keyFactsHeading : 'Key facts and insights' }</h2>
              <ul className={styles.factList}>
                {content.quickFacts.map(({ id, number, description }) => {
                  return <li key={id}>{description}</li>
                })}
              </ul>
            </section>
          )}
        </nav>
      </div>
    </article>
  )
}

function DashboardNotes() {
  return (
    <div className={`CAT-results-note ${styles.notes}`}>
      <div className="CAT-results-note-label">Notes</div>
      <ul>
        <li>
          Data are updated frequently, therefore, numbers are expected to
          change.
        </li>
        <li>Percentages may not total 100% due to rounding.</li>
        <li>
          If you have any questions about the data, you can{' '}
          <a href="mailto:researchinquiries@nycja.org">contact us</a>.
        </li>
      </ul>
    </div>
  )
}

function PretrialReleaseDashboardBlock(props) {
  const variableGroups = useFetchStore(
    `${process.env.REACT_APP_API}/pretrial-dashboard-variables.json`
  )
  function setView(value) {
    props.dispatch({
      type: 'setDataView',
      value,
    })
  }
  // const currentView =
  //   variableGroups &&
  //   variableGroups.viewVariables &&
  //   variableGroups.viewVariables.find(
  //     d => d.columnName === props.state.dataView
  //   )

  const viewItems = variableGroups && variableGroups.viewVariables

  return (
    <NewOffenseDashboard
      globalControls={
        <ViewSwitcher
          items={viewItems}
          setView={setView}
          dataView={props.state.dataView}
        />
      }
      {...props}
      setView={setView}
      showAllFilters
      variableGroups={variableGroups}
      resultsEndpoint={'pretrial-results'}
      // tooltipUnit={'TODO: People?'}
      timeColumn="Month"
    />
  )
}
