import React from 'react'
import Select from 'react-select'

const customStyles = {
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  valueContainer: provided => ({
    ...provided,
    padding: '5px',
  }),
  singleValue: provided => ({
    ...provided,
    whiteSpace: 'initial',
    position: 'initial',
    transform: 'initial',
    display: 'flex',
    alignItems: 'center',
  }),
}

const Dropdown = ({ options, value, setVariable, ...rest }) => {
  return (
    <Select
      isSearchable={false}
      styles={customStyles}
      menuPosition="fixed"
      menuPortalTarget={document.body}
      options={options.map(({ value, label }) => ({ value, label }))}
      value={options.find(option => option.value === value)}
      onChange={option => {
        setVariable(option ? option.value : null)
      }}
      {...rest}
    />
  )
}

// const Buttons = ({ options, setVariable }) => {
//   return <div style={{ display: 'grid', gridGap: '.5em' }}>
//     {options.map(({ label, value, disabled }, i) => (
//       !disabled && <button
//         className="DropdownButton"
//         key={value}
//         onClick={() => {
//           setVariable(value)
//         }}
//         value={value}>
//         {label}
//       </button>
//     ))}
//   </div>
// }

export default Dropdown
