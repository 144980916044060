import React from "react";
import "./Loading.scss";

const LoadingDots = ({ dotClassName="bg--blue" }) => <div className={`LoadingDots`}>
  <div className={dotClassName} />
  <div className={dotClassName} />
  <div className={dotClassName} />
</div>

const Loading = ({ className="", ...rest }) => (
  <div className={`Loading ${className}`} {...rest}>
    <LoadingDots />
  </div>
)

export default Loading;