import React from "react";

const IconLink = ({ children, href, label }) => {
  return (
    <a
      className="IconToggle"
      target="_blank"
      rel="noopener noreferrer"
      href={href}
    >
      <div className="IconToggle-icon">{children}</div>
      <div className="IconToggle-label">{label}</div>
    </a>
  );
};

export default IconLink;
