import { useEffect, useState } from "react";

const useMinWidth = (width) => {
  const [matches, setMatches] = useState();
  useEffect(() => {
    const mq = window.matchMedia(`(min-width: ${width})`);
    const setMatch = (e) => {
      setMatches(e.matches);
    };

    mq.addListener(setMatch);
    setMatch(mq);
    return () => mq.removeListener(setMatch);
  }, [width]);

  return matches;
};

export default useMinWidth;
