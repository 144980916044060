import React, { useEffect } from "react";
import { sumAll, formatValue } from "components/CAT/helpers";
import { useFade, a } from "a";
import Key from "components/data-viz/key";
import BarLabel from "components/data-viz/bar-label";
import Tooltip from "react-tooltip";

const TableComponent = ({ data, variables, fields, scaleMode, keyLabel }) => {
  const [variableKeys, variableValues] = variables;

  const rows =
    variableKeys.length === 1 ? variableValues[0] : variableValues[1];
  const columns = variableValues[0];
  const groups = variableValues[2];

  useEffect(() => {
    Tooltip.rebuild();
  }, [variables]);

  let tableNodes;
  if (variableKeys.length === 0) {
    tableNodes = (
      <Table>
        <tr>
          <th className="Table-total">Total</th>
          <td className="Table-total">
            {formatValue({
              value: data[0].value,
              total: data[0].value,
              scaleMode,
            })}
          </td>
        </tr>
      </Table>
    );
  } else if (variableKeys.length === 1) {
    const total = sumAll(data);
    tableNodes = (
      <Table>
        {rows.map((col) => {
          const filtered = data.filter(
            (d) => d[col.variable] === col.value
          ) || { value: null };
          return (
            <tr key={col.value}>
              <th>{col.label}</th>
              {filtered.map((datum) => {
                return (
                  <td key={datum.value}>
                    {formatValue({
                      value: datum.value,
                      total,
                      scaleMode,
                    })}
                  </td>
                );
              })}
            </tr>
          );
        })}
        <tr>
          <th className="Table-total">Total</th>
          <td className="Table-total">
            {formatValue({
              value: total,
              total,
              scaleMode,
            })}
          </td>
        </tr>
      </Table>
    );
  } else if (variableKeys.length === 2) {
    tableNodes = (
      <Table>
        <tr>
          <th className="VariableCell">{fields[rows[0].variable].label}</th>
          {columns.map((col) => (
            <th key={col.value}>{col.label}</th>
          ))}
          <th className="Table-total">Total</th>
        </tr>
        {rows.map((row) => {
          const filtered = columns.map((column) => {
            return (
              data.find(
                (d) =>
                  d[row.variable] === row.value &&
                  d[column.variable] === column.value
              ) || { value: null }
            );
          });
          const total = sumAll(filtered);
          return (
            <tr key={row.label}>
              <th>{row.label}</th>
              {filtered.map((datum, i) => {
                return (
                  <td
                    key={`${row.variable}${row.value}${columns[i].variable}${columns[i].value}`}
                  >
                    {formatValue({
                      value: datum.value,
                      total,
                      scaleMode,
                    })}
                  </td>
                );
              })}
              <td className="Table-total">
                {formatValue({
                  value: total,
                  total,
                  scaleMode,
                })}
              </td>
            </tr>
          );
        })}
        <Totals
          data={data}
          columns={columns}
          rows={rows}
          scaleMode={scaleMode}
        />
      </Table>
    );
  } else if (variableKeys.length === 3) {
    tableNodes = groups.map((group) => {
      const groupData = data.filter((d) => d[group.variable] === group.value);
      return (
        <Table
          heading={
            <BarLabel
              type={"primary"}
              value={group.label}
              variable={fields[group.variable].label}
            />
          }
          key={group.label}
        >
          {/* <tr>
          <td className="BlankCell"></td>
          <th className="VariableCell" colSpan={columns.length + 1}>{fields[columns[0].variable].label}</th>
        </tr> */}
          <tr>
            <th className="VariableCell">{fields[rows[0].variable].label}</th>
            {columns.map((col) => (
              <th key={col.value}>{col.label}</th>
            ))}
            <th className="Table-total">Total</th>
          </tr>
          {rows.map((row) => {
            const filtered = columns.map((column) => {
              return (
                groupData.find(
                  (d) =>
                    d[row.variable] === row.value &&
                    d[column.variable] === column.value
                ) || { value: null }
              );
            });
            const total = sumAll(filtered);

            const rowKey = `${group.variable}${group.label}${row.label}`;
            return (
              <tr key={rowKey}>
                <th>{row.label}</th>
                {filtered.map((datum, i) => {
                  const key = `${rowKey}${columns[i].label}`;
                  return (
                    <td key={key}>
                      {formatValue({
                        value: datum.value,
                        total,
                        scaleMode,
                      })}
                    </td>
                  );
                })}
                <td className="Table-total">
                  {formatValue({
                    value: total,
                    total,
                    scaleMode,
                  })}
                </td>
              </tr>
            );
          })}
          <Totals
            data={groupData}
            columns={columns}
            rows={rows}
            scaleMode={scaleMode}
          />
        </Table>
      );
    });
  }
  const props = useFade();

  return (
    <a.div style={props}>
      <Key
        style={
          variableKeys.length < 3
            ? { borderBottom: 0, marginBottom: 0 }
            : undefined
        }
        variable={variableKeys[0] ? fields[variableKeys[0]].label : "All Cases"}
        label={keyLabel}
      />
      {tableNodes}
    </a.div>
  );
};

export default TableComponent;

const Table = ({ children, heading }) => (
  <div className="Table">
    {heading}
    <table>
      <tbody>{children}</tbody>
    </table>
  </div>
);

const Totals = ({ data, columns, rows, scaleMode }) => {
  const totals = columns.map(({ variable, value }) => {
    const rowData = data.filter((d) => d[variable] === value);
    return sumAll(rowData);
  });

  const grandTotal = totals.reduce((r, d) => r + d, 0);

  return (
    <tr>
      <th className="Table-total">Total</th>
      {totals.map((total, i) => (
        <td key={i} className="Table-total">
          {formatValue({
            value: total,
            total: grandTotal,
            scaleMode,
          })}
        </td>
      ))}
      <td className="Table-total">
        {formatValue({
          value: grandTotal,
          total: grandTotal,
          scaleMode,
        })}
      </td>
    </tr>
  );
};
