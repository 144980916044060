import React from "react";
import { DataUnavailable } from "./views/Bars";
import { scaleModes } from "components/data-viz/constants";

export const determineVariables = (data, fields) => {
  const keys = Object.keys(data[0])
    .filter((d) => d !== "value")
    .reverse();
  const values = keys.reduce((r, variable) => {
    const extent = data
      .reduce((r, datum) => {
        if (!r.find((stack) => datum[variable] === stack.value)) {
          const match = fields[variable].options.find(
            (d) => d.value === datum[variable]
          );
          r.push({
            ...match,
            variable,
          });
        }
        return r;
      }, [])
      .filter((d) => d.label.toLowerCase() !== "excluded");

    r.push(extent);
    return r;
  }, []);

  return [keys, values];
};

export const format = (value) => (+value).toLocaleString();

export const formatValue = ({ value, total, scaleMode }) => {
  if (!value) {
    return <DataUnavailable />;
  }

  switch (scaleMode) {
    case scaleModes.ABSOLUTE:
      return format(value);

    case scaleModes.RELATIVE:
      return `${((100 * value) / total).toFixed(1)}%`;

    default:
      throw new Error(`unknown scale mode: ${scaleMode}`);
  }
};

export const sumAll = (data) => {
  return data.reduce((r, d) => {
    r += +d.value;
    return r;
  }, 0);
};
