import React from "react";

const SvgDownload = props => (
  <svg
    id="download_svg__Layer_1"
    x={0}
    y={0}
    viewBox="0 0 30 30"
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".download_svg__st0{fill:none;stroke:#000;stroke-width:2;stroke-miterlimit:10}"
      }
    </style>
    <path
      className="download_svg__st0"
      d="M5.1 4.6h19.8M5.1 10.2H11M5.1 15.9H11M19 10.2h5.9M19 15.9h5.9M15 9.2v14.6M19 21l-3.9 3.9-4-3.9"
    />
  </svg>
);

export default SvgDownload;
