import DashboardSwitcher from './DashboardSwitcher'
import styles from './DashboardNav.module.scss'

function DashboardNav({ heading }) {
  return (
    <section className={styles.element}>
      {heading && <h2 className={styles.heading}>{heading}</h2>}
      <DashboardSwitcher />
    </section>
  )
}

export default DashboardNav
