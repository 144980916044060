import React from "react";
import "./QuickFacts.scss";

const QuickFact = ({ number, description }) => {
  return <div className={"QuickFact"}>
    <div className={"QuickFact-number"}>{number}</div>
    <div className={"QuickFact-description"}>{description}</div>
  </div>
};

export default QuickFact;