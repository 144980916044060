import React, { useEffect, useState } from 'react'
import Controls from 'components/CAT/Controls'
import Results from './results'
import 'components/CAT/cat.scss'
import Loading from 'components/Loading'
import './pretrial-release-dashboard.scss'
import FilterSelector from './filter-selector'
import { setupVariable } from './helpers'
import { NumberOfArrestsFilterSelector } from './pretrial-release-dashboard'
import DashboardLayout from './dashboard-layout'
import styles from './new-offense-dashboard.module.scss'

const NewOffenseDashboard = ({
  showControls = true,
  childrenProps,
  resultsEndpoint,
  tooltipUnit,
  globalFilter,
  timeColumn = 'arrest_quarter',
  title,
  isDesktop,
  state,
  dispatch,
  showAllFilters,
  setView,
  variableGroups,
  globalControls,
  yAxisLabel,
}) => {
  const [showMobile, setShowMobile] = useState(false)

  return (
    <div className={`CAT ${!showControls ? 'CAT-nosettings' : ''}`}>
      <Tool
        state={state}
        dispatch={dispatch}
        showControls={showControls}
        cta={childrenProps}
        resultsEndpoint={resultsEndpoint}
        tooltipUnit={tooltipUnit}
        globalFilter={globalFilter}
        timeColumn={timeColumn}
        title={title}
        isDesktop={isDesktop}
        showAllFilters={showAllFilters}
        setView={setView}
        variableGroups={variableGroups}
        showMobile={showMobile}
        setShowMobile={setShowMobile}
        globalControls={globalControls}
        yAxisLabel={yAxisLabel}
      />
    </div>
  )
}

const Tool = ({
  state,
  dispatch,
  showControls,
  cta,
  resultsEndpoint,
  tooltipUnit,
  globalFilter,
  timeColumn,
  title,
  isDesktop,
  showAllFilters,
  setView,
  variableGroups,
  globalControls,
  showMobile,
  setShowMobile,
  yAxisLabel,
}) => {
  const viewVariables = variableGroups?.viewVariables
  const currentView = state.dataView
  const viewVariableColumns =
    viewVariables && viewVariables.map(({ columnName }) => columnName)

  useEffect(() => {
    if (
      viewVariableColumns &&
      (!currentView || !viewVariableColumns.includes(currentView))
    ) {
      setView(viewVariableColumns[0])
    }
  }, [viewVariableColumns, setView, currentView])

  if (
    !variableGroups ||
    (viewVariableColumns && !viewVariableColumns.includes(currentView))
  ) {
    return <Loading style={{ margin: '4em 0' }} />
  }

  const thisVariables = variableGroups.filterVariables
    ? variableGroups.filterVariables
    : variableGroups.groups



  const staticColumn = variableGroups.viewVariables
    ? state.dataView
    : variableGroups.displayVariable[0].columnName

  const currentValue = staticColumn
    ? staticColumn
    : state.variables[0]
      ? state.variables[0].value
      : null

  const fields = thisVariables.map(setupVariable);
  const allVariables = [
    ...fields,
    ...(variableGroups.viewVariables
      ? variableGroups.viewVariables.map(setupVariable)
      : [setupVariable(variableGroups.displayVariable[0])]),
  ]
  const currentVariable = allVariables.find(
    ({ columnName }) => columnName === currentValue
  )

  const excludeFilters = currentVariable && currentVariable.excludeFilters && currentVariable.excludeFilters.split(",").map(d => d.trim());
  const excludePeriods = currentVariable && currentVariable.excludePeriods && currentVariable.excludePeriods.split(",").map(d => d.trim());

  const filteredFields = excludeFilters ? fields.filter(function (d) {
    return !excludeFilters.includes(d.columnName)
  }) : fields;

  const currentLabel = currentVariable.label
    ? currentVariable.label
    : currentVariable.columnName

  const sliceByLabel = state.sliceBy
    ? allVariables.find(({ columnName }) => columnName === state.sliceBy).label
    : null

  const globalFilterArray = Array.isArray(globalFilter)
    ? globalFilter
    : [globalFilter]
  const hasBoroughSelector = globalFilterArray.includes('Borough')
  const hasFollowUpSelector = globalFilterArray.includes(
    'New Offense Follow-up Period'
  )
  const hasNumberOfArrestsSelector = globalFilterArray.includes(
    'Number of Prosecuted Arrests'
  )

  function reset() {
    dispatch({
      type: 'resetVariables',
    })
  }


  return (
    <DashboardLayout
      reset={reset}
      isDesktop={isDesktop}
      showControls={showControls}
      globalControls={globalControls}
      showMobile={showMobile}
      setShowMobile={setShowMobile}
      controls={
        <Controls
          fields={filteredFields}
          state={state}
          dispatch={dispatch}
          canDrag={false}
          variableExplainer={
            'Select additional variables to filter the results'
          }
          maxVariables={5}
          showAllFilters={showAllFilters}
          staticFilter={
            <>
              {hasBoroughSelector && (
                <FilterSelector
                  label="Borough"
                  items={[
                    'All',
                    'Manhattan',
                    'Brooklyn',
                    'Queens',
                    'Bronx',
                    'Staten Island',
                  ]}
                  onChange={value => () => {
                    dispatch({
                      type: 'setBorough',
                      value,
                    })
                  }}
                  current={state.borough}
                />
              )}
              {hasFollowUpSelector && (
                <div className={styles.followUp}>
                  <FilterSelector
                    label="New Arrest Follow Up Period"
                    note={variableGroups.followUpFilterNote}
                    items={['3 months', '6 months', '9 months', '12 months']}
                    onChange={value => () => {
                      dispatch({
                        type: 'setFollowUp',
                        value,
                      })
                    }}
                    current={state.followUp}
                  />
                </div>
              )}
              {hasNumberOfArrestsSelector && (
                <NumberOfArrestsFilterSelector
                  dispatch={dispatch}
                  numberOfArrests={state.numberOfArrests}
                />
              )}
            </>
          }
        />
      }
      results={
        <Results
          fields={fields}
          allVariables={allVariables}
          state={state}
          dispatch={dispatch}
          label={currentLabel}
          column={currentVariable.columnName}
          sliceByLabel={sliceByLabel}
          glossaryDownload={variableGroups.download}
          yAxisLabel={yAxisLabel || variableGroups.yAxisLabel}
          cta={cta}
          endpoint={resultsEndpoint}
          tooltipUnit={tooltipUnit}
          globalFilter={globalFilter}
          staticColumn={staticColumn}
          timeColumn={timeColumn}
          showFilteredInKey={!showAllFilters}
          hasFollowUpSelector={hasFollowUpSelector}
          title={title}
          isDesktop={isDesktop}
          excludePeriods={excludePeriods}
        />
      }
    />
  )
}

export default NewOffenseDashboard
