import React, { useEffect } from 'react'
import HTML from 'dangerously-set-html-content'
import ReactTooltip from 'react-tooltip'

const RichText = ({ text, className = '' }) => {
  useEffect(() => {
    ReactTooltip.rebuild()
  }, [text])

  if (text === null) {
    return null
  }

  const html = tooltips(text)

  return (
    <>
      {html && <HTML html={html} className={className} />}
      <ReactTooltip className="CATTooltip-terms" />
    </>
  )
}

export default RichText

const test = /.*?(\{.*?\}\[.*?\]).*?/g
const innerTest = /\{(.*)\}\[(.*)\]/

function tooltips(text) {
  const matchResult = text.matchAll(test)
  const matches = [...matchResult]

  matches.forEach(match => {
    match.slice(1).forEach(replace => {
      const [, term, def] = replace.match(innerTest)
      text = text.replace(
        replace,
        `<span class="term-tooltip" data-tip="${def}">${term}</span>`
      )
    })
  })

  return text
}
