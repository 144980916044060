import React from "react";
import Link from "components/Link";
import "./Footer.scss";
import { useFetchStore } from "Fetch";
import { fetchData } from "redux/actions";
import Logo from "svg/NYCJA_wordmark_white.png";
import NewsletterSignup from "./NewsletterSignup";
import Email from "svgr/Email";
import Phone from "svgr/Phone";
import Person from "svgr/Person";
import Nav from "components/Nav";
const endpoint = `${process.env.REACT_APP_API}/footer.json`;

const Footer = () => {
  const data = useFetchStore(endpoint);
  return data ? (
    <>
      <Nav />

      <footer className="Footer bg--blue color--white">
        <div className="Footer-resources">
          <div className="Footer-resources-heading">
            {data.resourcesHeading}
          </div>
          <div className="Footer-resources-explainer">
            {data.resourcesExplainer}
          </div>
          <ul className="Footer-resources-list">
            <li className="Footer-resources-list-item">
              <a href={`tel:${data.resourcesPhone}`}>
                <Phone /> {data.resourcesPhone}
              </a>
            </li>
            <li className="Footer-resources-list-item">
              <a href={`mailto:${data.resourcesEmail}`}>
                <Email /> {data.resourcesEmail}
              </a>
            </li>
            <li className="Footer-resources-list-item">
              <Link
                useRouter
                url={data.resourcesLink.url}
                label={
                  <>
                    <Person /> {data.resourcesLink.label}
                  </>
                }
              />
            </li>
          </ul>
        </div>
        <div className="Footer-contact">
          <img
            className="Footer-contact-logo"
            src={Logo}
            alt="CJA Logo"
            style={{ width: "200px" }}
          />
          <div
            className="Footer-contact-address"
            dangerouslySetInnerHTML={{ __html: data.address }}
          />
          <div className="Footer-contact-phone">
            <div>T {data.phoneNumber}</div>
            <div>F {data.faxNumber}</div>
          </div>
        </div>
        <div className="Footer-newsletter">
          <div className="Footer-newsletter-heading">Newsletter</div>
          <NewsletterSignup />
        </div>
        <div className="Footer-links">
          <div className="Footer-links-heading">{data.linksHeading}</div>
          <ul className="Footer-links-list">
            {data.links.map((props) => {
              return (
                <li className="Footer-links-list-item" key={props.label}>
                  <Link {...props} />
                </li>
              );
            })}
          </ul>
        </div>
        <div className="Footer-copyright">{data.copyrightNotice}</div>
      </footer>
    </>
  ) : null;
};

Footer.serverFetch = () => fetchData(endpoint);

export default Footer;
