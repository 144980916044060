import React from "react"
import { H } from "Heading"
import { kebabCase } from "lodash"

const Heading = ({ text }) => {
  return (
    <H className="Heading" id={kebabCase(text)}>
      {text}
    </H>
  )
}

export default Heading
