import RichText from 'components/rich-text/rich-text'
import Flickity from 'react-flickity-component'
import 'flickity/css/flickity.css'
import Image from './Image'
import styles from './timeline.module.scss'
import { useRef } from 'react'

function Timeline({ title, subheading, headerImage, items }) {
  const flickity = useRef()

  function prev() {
    flickity.current.previous()
  }
  function next() {
    flickity.current.next()
  }
  return (
    <article className={styles.element}>
      <header className={styles.header}>
        <h1 className={headerImage ? styles.heading : styles.headingVisible}>
          {title}
        </h1>
        {headerImage && <Image image={headerImage} />}
      </header>
      <div className={styles.controls}>
        <button onClick={prev} aria-label="Previous" className={styles.prev}>
          <svg
            width="54"
            height="54"
            viewBox="0 0 54 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="27"
              cy="27"
              r="27"
              transform="rotate(-180 27 27)"
              fill="#6F2CFF"
            />
            <path
              d="M48 27L8 27"
              stroke="white"
              stroke-width="3"
              stroke-linecap="round"
            />
            <path
              d="M23 43L7 27L23 11"
              stroke="white"
              stroke-width="3"
              stroke-linecap="round"
            />
          </svg>
        </button>
        {subheading && <div className={styles.subheading}>{subheading}</div>}
        <button onClick={next} aria-label="Next" className={styles.next}>
          <svg
            width="54"
            height="54"
            viewBox="0 0 54 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="27" cy="27" r="27" fill="#6F2CFF" />
            <path
              d="M6 27H46"
              stroke="white"
              stroke-width="3"
              stroke-linecap="round"
            />
            <path
              d="M31 11L47 27L31 43"
              stroke="white"
              stroke-width="3"
              stroke-linecap="round"
            />
          </svg>
        </button>
      </div>
      <Flickity
        elementType="ul"
        flickityRef={c => (flickity.current = c)}
        options={{
          cellAlign: 'left',
          contain: true,
          pageDots: false,
          prevNextButtons: false,
          groupCells: true,
        }}
        className={styles.carousel}
      >
        {items.map(({ heading, year, yearIcon, text, image }, i) => {
          return (
            <li className={styles.item}>
              <div className={styles.itemLine} />
              <div className={styles.itemInner}>
                <div className={styles.year}>
                  {yearIcon && <Image image={yearIcon} />}
                  <span
                    className={
                      yearIcon ? styles.yearText : styles.yearTextVisible
                    }
                  >
                    {year}
                  </span>
                </div>
                {heading && <h3>{heading}</h3>}
                {image && <Image image={image} />}
                <RichText text={text} />
              </div>
            </li>
          )
        })}
      </Flickity>
    </article>
  )
}

export default Timeline
