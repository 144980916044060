import React, { useState } from "react";
import { H } from "Heading"
import Arrow from "components/Arrow";

const AccordionItem = ({ heading, children }) => {
  const [open,setOpen] = useState(false)
  const toggle = () => setOpen(!open)

  return <div className={`AccordionItem ${open ? "AccordionItem--open" : ""}`}>
    <button onClick={toggle} className="AccordionItem-heading">
      <H>{heading}</H> <Arrow open={open} />
    </button>
    {open && <div className="AccordionItem-content">{children}</div>}
  </div>
}

export default AccordionItem;