import React, { useEffect, useRef } from "react"
import { useFadein } from "animations"
import Caret from "svgr/Caret"
import Heading from "./Heading"
import "swiper/swiper.min.css"

function setButtons(swiper, carouselEl) {
  if (swiper.current?.isEnd) {
    carouselEl.current?.classList.add("end")
  } else {
    carouselEl.current?.classList.remove("end")
  }
  if (swiper.current?.isBeginning) {
    carouselEl.current?.classList.add("start")
  } else {
    carouselEl.current?.classList.remove("start")
  }
}

const Carousel = ({ heading, children, headerTag }) => {
  const { a, props } = useFadein()

  const Tag = headerTag ? a.header : a.div

  const id = Math.random(10000000000)

  let carouselEl = useRef(null)
  let swiperEl = useRef(null)
  let swiperRef = useRef(null)

  useEffect(() => {
    if (!swiperRef.current) {
      swiperRef.current = new window.Swiper(swiperEl.current, {
        slidesPerView: 1,
        spaceBetween: 50,
        loop: false,
        breakpoints: {
          767: {
            slidesPerView: 2,
            spaceBetween: 120
          }
        }
      })

      swiperRef.current.on("slideChange", () => {
        setButtons(swiperRef, carouselEl)
      })

      setButtons(swiperRef, carouselEl)
    }
  })

  return (
    <Tag className={`Carousel`} style={props} ref={carouselEl}>
      <Heading className="Carousel_heading" text={heading} />
      <div id={id} className="swiper" ref={swiperEl}>
        <div className="swiper-wrapper">{children}</div>
      </div>
      <Caret
        className="swiper-button-next"
        onClick={() => {
          swiperRef.current?.slideNext()
          setButtons(swiperRef, carouselEl)
        }}
      />
      <Caret
        className="swiper-button-prev"
        onClick={event => {
          swiperRef.current?.slidePrev()
          setButtons(swiperRef, carouselEl)
        }}
      />
    </Tag>
  )
}

export default Carousel
