import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducers';
//import { loadState, saveState } from "./localStorage";
//import throttle from "lodash/throttle"
import thunkMiddleware from 'redux-thunk'

const composeEnhancers = (typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
//const persistedState = {} //loadState();

const store = initialState => createStore(
  rootReducer,
  initialState,
  composeEnhancers(
    applyMiddleware(
      thunkMiddleware
    )
  )
)

// store.subscribe(throttle(() => {
//   saveState({
//     auth: store.getState().auth
//   })
// }), 1000)

export default store;
