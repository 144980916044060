import React from "react";
import Link from "components/Link";
import Email from "svgr/Email";

const icons = {
  email: Email
}

const ContactLink = ({ heading, description, link }) => {
  const Icon = icons[link.type];
  return <li className={"ContactLink"}>
    <div className={"ContactLink-heading"}>{heading}</div>
    <div className={"ContactLink-description"}>{description}</div>
    <Link {...link} className={`ContactLink-link ${link.className || ""}`}>
      <Icon /> {link.label}
    </Link>
  </li>
}

export default ContactLink