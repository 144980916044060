import React from "react";

const SvgGlossary = props => (
  <svg viewBox="0 0 30 30" {...props}>
    <path d="M24.4 1.3H8.7c-1.9 0-3.1 1.3-3.1 3.3v20.5c0 2.1 1.3 3.6 3.1 3.6h15.8v-1.9H8.7c-.9 0-1.2-.9-1.2-1.7v-2.7c0-.9.4-1.2 1.2-1.2h15.8V1.3zm-1.8 18H8.7c-.5 0-.9.1-1.2.2v-15c0-.9.4-1.4 1.2-1.4h1.6v7.4l1.3-1.9 1.5 1.9V3.1h9.4v16.2z" />
    <path d="M10.3 23c-.6 0-.9.4-.9.9 0 .6.4.9.9.9h12.3v-1.9H10.3zM15 15.5h5.7v1.9H15zM15 12.6h5.7v1.9H15zM15 9.8h5.7v1.9H15z" />
  </svg>
);

export default SvgGlossary;
