import React from 'react'
import NewOffenseDashboard from 'components/pretrial-release-dashboard/new-offense-dashboard'
import styles from './PeopleArrestsDashboardBlock.module.scss'
import { useFetchStore } from 'Fetch'
// import supportiveStyles from './supportive-services-dashboard-block.module.scss'
import RightArrow from 'components/right-carrot'

const SupportiveServicesDashboardBlock = props => {
  function setView(value) {
    props.dispatch({
      type: 'setDataView',
      value,
    })
  }

  const variableGroups = useFetchStore(
    `${process.env.REACT_APP_API}/${props.variableEndpoint}.json`
  )
  const currentView =
    variableGroups &&
    variableGroups.viewVariables.find(
      d => d.columnName === props.state.dataView
    )

  return (
    <NewOffenseDashboard
      {...props}
      globalControls={
        props.showControls && (
          <ViewSwitcher
            items={variableGroups?.viewVariables}
            dataView={props.state.dataView}
            setView={setView}
          />
        )
      }
      variableGroups={variableGroups}
      resultsEndpoint={props.resultsEndpoint}
      tooltipUnit={'arrests'}
      timeColumn={'Quarter'}
      uid={'supportive-services-filter' + props.state.dataView}
      showAllFilters={true}
      setView={setView}
      yAxisLabel={currentView?.yAxisLabel}
    />
  )
}

export function ViewSwitcher({ items, dataView, setView, size }) {
  if (!items || items.length === 0) {
    return null
  }
  return (
    <div className={size ? styles[size] : styles.toggle}>
      <span>Change View: </span>
      {items.map(({ id, columnName, label, text }) => {
        const isActive = dataView === columnName
        return (
          <button
            key={id}
            className={styles[isActive ? 'activeToggleButton' : 'toggleButton']}
            onClick={() => setView(columnName)}
            data-tip={text}
            data-for={'view-tooltip'}
            data-html
          >
            <span>{label || columnName}</span> <RightArrow />
          </button>
        )
      })}
    </div>
  )
}

export default SupportiveServicesDashboardBlock
