import React, { useEffect, useRef } from "react"
import { HDown } from "Heading"
import Link from "components/Link"

const ThingsNumber = ({ startingNumber, number, text, links }) => {
  const numberRef = useRef(null)
  number = parseFloat(number);
  startingNumber = parseFloat(startingNumber) || 0;

  useEffect(() => {
    let options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    }

    let observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            let start, previousTimeStamp;
            let done = false
            let duration = 3000;

            function step(timestamp) {
              if (start === undefined) {
                start = timestamp;
              }

              const elapsed = timestamp - start;

              if (previousTimeStamp !== timestamp) {
                // Math.min() is used here to make sure the element stops at exactly numberpx
                const count = Math.min(startingNumber + (elapsed/duration)*(number-startingNumber), number);

                if (count === number) done = true;

                const numberShown = done ? count : parseInt(count)

                entry.target.innerHTML = numberShown;
              }

              if (elapsed < duration) { // Stop the animation after 2 seconds
                previousTimeStamp = timestamp;

                if (!done) {
                  window.requestAnimationFrame(step);
                }
              }
            }

            window.requestAnimationFrame(step);

            observer.unobserve(entry.target);
          }
        });
      }, options);

    observer.observe(numberRef.current);
  });

  return (
    <HDown>
      <li className={`Things-item Things-number`}>
        <div ref={numberRef} className="Things-number__number"></div>
        <div
          className="Things-item-text"
          dangerouslySetInnerHTML={{ __html: text }}
        />
        <ul className="Things-item-links">
          {links &&
            links.length > 0 &&
            links.map(link => (
              <li key={link.url}>
                <Link className="Things-item-link" {...link} />
              </li>
            ))}
        </ul>
      </li>
    </HDown>
  )
}

export default ThingsNumber
