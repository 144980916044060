import React from 'react';
import { Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";

import './App.scss';
import Nav from "components/Nav";
import Footer from "components/Footer";
import { Page} from "components/Page";
import Publication from "components/Publication";
import Article from "components/Article";
import Person from "components/Person";
import ScrollMemory from 'react-router-scroll-memory';
import Search from "components/Nav/Search";
import { Route as ReleaseAssessment } from "components/ReleaseAssessment";

const App = () => {
  return <>
    <ScrollMemory />
    <Main />
  </>
}

const Main = () => {
  const theme = useSelector(state => state.style.theme);

  return <div className={`App ${`App--${theme}`}`}>
    <Nav />
    <div className={"Main"}>
      <Switch>
        <Route path="/search" component={Search} />
        <Route path="/justice-in-practice/:slug" component={Article} />
        <Route path="/publications/:slug" component={Publication} />
        <Route path="/people/:slug" component={Person} />
        <Route path="/release-assessment/:id" component={ReleaseAssessment} />
        <Route path="/:slug?" component={Page} />
      </Switch>
    </div>
    <Footer />
  </div>
}

export default App;
