import React from "react"
import Image from "./Image.js"
import { useFadein } from "animations"

const Hero = ({ image, text, caption, layout = "default", backgroundColor, headerTag }) => {
  const { a, props } = useFadein()
  const Tag = headerTag ? a.header : a.div

  return (
    <Tag
      className={`QuoteHero QuoteHero--${layout ? 'alt' : 'default'} bg--${backgroundColor}`}
      style={props}
    >
      <div className="QuoteHero--inner">
        <Image className="QuoteHero_image" image={image} />
        <div
          className="QuoteHero_quote--text"
          dangerouslySetInnerHTML={{ __html: text }}
        />
        <div
          className="QuoteHero_quote--caption"
          dangerouslySetInnerHTML={{ __html: caption }}
        />
      </div>
    </Tag>
  )
}

export default Hero
