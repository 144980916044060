import React from "react";

const ResourcesGroup = ({ heading, children }) => {
  return (
    <div className={"ResourcesGroup"}>
      <div className={"ResourcesGroup-heading"}>{heading}</div>
      <ul className={"ResourcesGroup-list"}>
        {children.map((child) => {
          return (
            <li key={child.key} className={"ResourcesGroup-list-item"}>
              {child}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export default ResourcesGroup;
