import React from 'react'
// import Circle from 'svgr/Circle'

const Fact = ({ heading, text }) => {
  return (
    <div className="Fact">
      {/* <Circle /> */}
      <div className="Fact-heading">{heading}</div>
      <div className="Fact-text">{text}</div>
    </div>
  )
}

export default Fact
