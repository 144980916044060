import React from "react";
import Image from "./Image";
import Link from "components/Link";
import "./BigStatement.scss";

const BigStatement = ({ image, description, link }) => {
  return (
    <div className={"BigStatement"}>
      <Image image={image} className={"BigStatement-image"} />
      <div className={"BigStatement-description"}>{description}</div>
      <div className={"BigStatement-link"}>
        <Link {...link} />
      </div>
    </div>
  );
};
export default BigStatement;
