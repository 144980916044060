import React from 'react'
import Stream from './index.js'

const CuratedContent = ({ itemIds, uid }) => {
  return itemIds.length > 0 ? (
    <div className="CuratedContent">
      <Stream entryIds={itemIds} uid={uid} />
    </div>
  ) : null
}

export default CuratedContent
