import React from "react"
import Image from "./Image.js"
import { H } from "Heading"
import { useFadein } from "animations"

const StaffPerson = ({ image, heading, jobTitle, text, headerTag }) => {
  const { a, props } = useFadein()
  const Tag = headerTag ? a.header : a.div

  return (
    <div className="swiper-slide">
      <Tag className={`StaffPerson`} style={props}>
        <Image className="StaffPerson-image" image={image} />
        <H className="StaffPerson-heading">{heading}</H>
        <p className="StaffPerson-jobTitle">{jobTitle}</p>
        <div
          className="StaffPerson-text"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </Tag>
    </div>
  )
}

export default StaffPerson
