import React from "react";
import "./Video.scss";

const Video = (props) => {
  return (
    <div className="Video">
      <video src={props.url} controls></video>
    </div>
  );
};

export default Video;
