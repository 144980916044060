import React, { useMemo } from "react";
import { useFetchStore } from "Fetch";
import { setCategories, setYears, setOrder } from "./streamReducer.js";
import Search from "svgr/Search";
import Select, { components as selectComponents } from "react-select";
import { SearchQuery } from "components/Nav/Search";

const colors = {
  lightBlue: "#eef5fb",
  black: "#000",
};
const dropdownStyles = {
  control: (styles, state) => {
    return {
      ...styles,
      backgroundColor: "transparent",
      borderRadius: 0,
      borderColor: colors.black,
      boxShadow: state.isFocused ? "0 0 0 1px #000" : null,
      minHeight: "44px",
      ":hover": {
        ...styles[":hover"],
        borderColor: colors.black,
      },
    };
  },
  indicatorSeparator: (styles) => ({
    display: "none",
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "inherit",
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    stroke: colors.black,
    fill: colors.black,
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: colors.lightBlue,
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  option: (styles) => ({
    ...styles,
    ":hover": {
      ...styles[":hover"],
      backgroundColor: colors.lightBlue,
    },
    ":focus": {
      ...styles[":focus"],
      backgroundColor: colors.lightBlue,
    },
  }),
  // multiValueRemove: (styles, { data }) => ({
  //   ...styles
  // }),
};

const DropdownIndicator = (props) => {
  const width = 1000;
  const height = 800;
  const barH = 0.15 * height;
  const barY = barH + (height - 3 * barH) / 2;

  return (
    selectComponents.DropdownIndicator && (
      <selectComponents.DropdownIndicator {...props}>
        <svg
          viewBox={`0 0 ${width} ${height}`}
          fill={colors.black}
          width="20px"
        >
          <rect x={0} y={0} width={width} height={barH} />
          <rect x={0} y={barY} width={width} height={barH} />
          <rect x={0} y={2 * barY} width={width} height={barH} />
        </svg>
      </selectComponents.DropdownIndicator>
    )
  );
};

const StreamFilter = ({ groupHandle, state, dispatch }) => {
  const catRes = useFetchStore(
    `${process.env.REACT_APP_API}/filter/${groupHandle}.json`
  );
  const catOptions = catRes
    ? catRes.data.reduce((r, d) => {
        r.push({
          label: d.title,
          options: d.children.map((d) => ({ label: d.title, value: d.id })),
        });
        return r;
      }, [])
    : null;

  const yearOptions = useMemo(() => {
    const startYear = 2006;
    const thisYear = new Date().getFullYear();
    const yearOptions = [];
    for (let i = thisYear; i >= startYear; i--) {
      yearOptions.push({
        label: `${i}`,
        value: i,
      });
    }
    return yearOptions;
  }, []);

  return (
    <div className="StreamFilter">
      <div className="StreamFilter-inner">
        <label className="StreamFilter-query">
          Search
          <div>
            <SearchQuery
              state={state}
              dispatch={dispatch}
              placeholder="title, description, author"
            />
            <Search alt="Search" />
          </div>
        </label>
        {catOptions && (
          <Dropdown
            options={catOptions}
            label={"Topic"}
            update={(value) => dispatch(setCategories(value))}
          />
        )}
        {yearOptions && (
          <Dropdown
            options={yearOptions}
            label={"Year"}
            update={(value) => dispatch(setYears(value))}
          />
        )}
        <fieldset className="RadioSet">
          <legend className="RadioSet-heading">Sort By</legend>
          <div className="RadioSet-options">
            {[
              {
                label: "Relevance",
                value: "score",
                checked: true,
              },
              {
                label: "Newest",
                value: "postDate desc",
              },
              { label: "Oldest", value: "postDate asc" },
            ].map(({ label, value }) => (
              <label key={value} className="RadioOption">
                <input
                  type="radio"
                  name="orderBy"
                  value={value}
                  checked={state ? state.orderBy === value : false}
                  onChange={() => dispatch(setOrder(value))}
                />
                <span className="Radio-label">{label}</span>
              </label>
            ))}
          </div>
        </fieldset>
      </div>
    </div>
  );
};

export default StreamFilter;

const Dropdown = ({ label, update, options }) => {
  return (
    <label className="StreamFilterDropdown">
      {label}
      <Select
        components={{
          DropdownIndicator,
          IndicatorSeparator: () => null,
          ClearIndicator: () => null,
        }}
        options={options}
        styles={dropdownStyles}
        isMulti
        placeholder="All"
        onChange={(e) => {
          update(e ? e.map((e) => e.value).join(",") : null);
        }}
      />
    </label>
  );
};
