import React, { useRef } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe"

const action = "https://gmail.us3.list-manage.com/subscribe/post?u=8c2f797fa399cd1dafb9a27c1&amp;id=2613fa8dcb"

const NewsletterSignup = () => {
  const inputRef = useRef()

  return (
    <div className="NewsletterSignup">
      <MailchimpSubscribe
        url={action}
        render={({ subscribe, status, message }) => <>
          <form onSubmit={event => {
              event.preventDefault()
              subscribe({
                EMAIL: inputRef.current.value
              })
            }}>
            <label className="NewsletterSignup-email">
              <input type="email" placeholder="Email Address" ref={inputRef} />
              <div className="NewsletterSignup-email-label">Email Address</div>
            </label>
            <button aria-label="submit" className="NewsletterSignup-submit" />
          </form>
          <div className="NewsletterSignup-message">
            {status === "sending" && <div>sending...</div>}
            {status === "error" && <div dangerouslySetInnerHTML={{__html: message}}/>}
            {status === "success" && <div>Subscribed !</div>}
          </div>
        </>} />

    </div>
  )
}

export default NewsletterSignup