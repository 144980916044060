import React from 'react'
import NewOffenseDashboard from 'components/pretrial-release-dashboard/new-offense-dashboard'
import { useFetchStore } from 'Fetch'
import FilterSelector from 'components/pretrial-release-dashboard/filter-selector'
import styles from './new-offense-dashboard.module.scss'
import ReactTooltip from 'react-tooltip'

const NewOffenseDashboardBlock = props => {
  const variableGroups = useFetchStore(
    '/api/new-offense-dashboard-variables.json'
  )

  if (!variableGroups) {
    return null
  }

  return (
    <NewOffenseDashboard
      {...props}
      variableGroups={variableGroups}
      resultsEndpoint={'new-offense-results'}
      tooltipUnit={'arrests'}
      showAllFilters
      globalControls={
        <div className={styles.followUp}>
          <FilterSelector
            // label={}
            changeViewLabel={
              <span
                data-tip={`The time a case is tracked for reporting a new prosecuted arrest, which is until case resolution or a set period (3, 6, 9, or 12 months) from the arrest date, whichever comes first.`}
                data-for="followuptt"
              >
                Change Follow Up Period:
              </span>
            }
            // note={variableGroups.followUpFilterNote}
            items={['3 months', '6 months', '9 months', '12 months']}
            onChange={value => () => {
              props.dispatch({
                type: 'setFollowUp',
                value,
              })
            }}
            current={props.state.followUp}
          />
          <ReactTooltip
            id="followuptt"
            className="CATTooltip"
            delayHide={300}
            delayUpdate={100}
            effect="solid"
            place="right"
          />
        </div>
      }
    />
  )
}

export default NewOffenseDashboardBlock
