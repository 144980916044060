import React from "react";
import ToggleSet from "./toggle-set";
import { scaleModes } from "./constants";
import Percent from "svgr/Percent";
import Counts from "svgr/Counts";

const scaleIcons = {
  [scaleModes.ABSOLUTE]: Counts,
  [scaleModes.RELATIVE]: Percent,
};
const ScaleToggle = ({ value, set }) => (
  <ToggleSet
    items={Object.values(scaleModes)}
    current={value}
    set={set}
    icons={scaleIcons}
  />
);

export default ScaleToggle;
