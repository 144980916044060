import React from "react";
import Heading from "./Heading";
import Image from "./Image";
import "./IconHeading.scss";

const IconHeading = ({ text, image }) => {
  return <div className="IconHeading">
    <Image image={image} />
    <Heading text={text} />
  </div>
}

export default IconHeading