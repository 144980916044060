import React from "react";
import { useTransition, a } from "react-spring";
import { NavLink } from "react-router-dom";

const Menu = ({ data, open, setOpen }) => {
  const t = useTransition(open, null, {
    from: { width: `0%` },
    enter: { width: `100%` },
    leave: { width: `0%` },
  });

  if (!data) {
    return null;
  }

  const closeClick = {
    onClick: () => setOpen(false),
  };

  return t.map(
    ({ item, key, props }) =>
      item && (
        <a.nav key={key} className={"Menu"} style={props}>
          <button className="MenuClose" {...closeClick}>
            {Array(2)
              .fill(null)
              .map((d, i) => (
                <div key={i} />
              ))}
          </button>
          <div className={"Menu-inner"}>
            {data.blocks.map((block) => {
              switch (block.type) {
                case "link":
                  return (
                    <NavLink
                      className={"nav-item"}
                      key={block.id}
                      to={block.url}
                      {...closeClick}
                    >
                      {block.text}
                    </NavLink>
                  );

                case "subnav":
                  return block.children.map((block) => (
                    <NavLink
                      className={"nav-item"}
                      key={block.id}
                      to={block.url}
                      {...closeClick}
                    >
                      {block.text}
                    </NavLink>
                  ));

                default:
                  return null;
              }
            })}
          </div>
        </a.nav>
      )
  );
};

export default Menu;
