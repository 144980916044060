export const formatDate = date => {
  return new Date(date)
    .toLocaleDateString("en", {
      year: "numeric",
      month: "long",
      day: "numeric"
    })
}

export const oxford = items => items.reduce((r,d,i) => {
  r.push(d)
  if (i < items.length - 2) {
    r.push(", ")
  } else if (i === items.length - 2) {
    r.push(" and ")
  }
  return r
},[])