import React from "react";

const SvgTable = props => (
  <svg viewBox="0 0 30 30" {...props}>
    <path
      d="M28 4.5v21H2v-21h26zM10 20.8H4v2.8h6v-2.8zm8 0h-6v2.8h6v-2.8zm8 0h-6v2.8h6v-2.8zM10 16H4v2.8h6V16zm8 0h-6v2.8h6V16zm8 0h-6v2.8h6V16zM4 11.2V14h6v-2.8H4zm8 0V14h6v-2.8h-6zm8 0V14h6v-2.8h-6zm6-4.7h-6v2.8h6V6.5zm-14 0v2.8h6V6.5h-6zm-2 0H4v2.8h6V6.5z"
      fill="#171919"
    />
  </svg>
);

export default SvgTable;
