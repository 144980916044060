import React, { useCallback, useEffect, useState } from 'react'
import HTML5Backend from 'react-dnd-html5-backend-cjs'
import { DndProvider } from 'react-dnd-cjs'
import Card from './Card'
import Variable from './Variable'
import update from 'immutability-helper'
import { Filters } from 'components/CAT/Variable'
import { useLocation } from 'react-router-dom'
import qs from 'qs'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import useMeasure from 'react-use-measure'
import { useSpring, a } from 'react-spring'
import Dropdown from './Dropdown'
import Tooltip from 'react-tooltip'
import styles from './controls.module.scss'
import RightCarrot from 'components/right-carrot'
import ReactTooltip from 'react-tooltip'

const Controls = ({
  fields,
  state,
  dispatch,
  oneVariable = false,
  staticFilter,
  canDrag = true,
  variableExplainer,
  maxVariables = 3,
  allowFilterSlice,
  showAllFilters
}) => {
  const addVariable = () => {
    dispatch({
      type: 'addVariable',
    })
  }

  const [copied, setCopied] = useState(false)
  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => {
        setCopied(false)
      }, 3000)
      return () => clearTimeout(timer)
    }
  }, [copied])

  const jsonState = JSON.stringify(state)

  useEffect(() => {
    setCopied(false)
    ReactTooltip.rebuild()
  }, [jsonState])

  const quantityVariablesVisualized = state.variables.filter(
    d => d && d.visualize
  ).length
  const quantityVariablesSelected = state.variables.filter(
    d => d && d.visualize && d.value
  ).length

  const addVariableDisabled =
    quantityVariablesVisualized > quantityVariablesSelected

  const location = useLocation()
  const getParams = qs.parse(location.search.replace('?', ''))
  const showSettings = Object.keys(getParams).includes('settings')

  if (showAllFilters) {
    return (
      <>
        {staticFilter}
        <AllFilters state={state} dispatch={dispatch} fields={fields} />
      </>
    )
  }

  return (
    <div className="CAT-controls">
      {oneVariable ? (
        <StaticCards
          state={state}
          fields={fields}
          dispatch={dispatch}
          staticFilter={staticFilter}
          allowFilterSlice={allowFilterSlice}
        />
      ) : (
        <>
          {staticFilter}
          <Cards
            state={state}
            dispatch={dispatch}
            fields={fields}
            canDrag={canDrag}
          />
        </>
      )}
      {state.variables.length === 0 && <Explainer text={variableExplainer} />}
      <div className={styles.buttonWrap}>
        {!oneVariable && quantityVariablesVisualized < maxVariables && (
          <button
            disabled={addVariableDisabled}
            className="CAT-controls-add"
            onClick={addVariable}
          >
            + Add Filter
          </button>
        )}
        {/* {canReset && (
          <button className="CAT-controls-reset" onClick={reset}>
            Reset
          </button>
        )} */}
      </div>
      {showSettings && (
        <CopyToClipboard
          text={JSON.stringify(state)}
          onCopy={() => setCopied(true)}
        >
          <span className="CtaExplainer-link">
            {copied ? 'Copied' : 'Copy settings'}
          </span>
        </CopyToClipboard>
      )}
    </div>
  )
}

export default Controls

const Explainer = ({
  text = 'Compare the data by selecting up to three variables. You can refine by filtering out categories within a variable.  Using the buttons above the visualization, display the data as frequencies or percentages in a graph or table.',
}) => {
  return (
    <div className="CAT-controls-explainer">
      <div>{text}</div>
    </div>
  )
}

const StaticCards = ({
  state,
  dispatch,
  fields,
  staticFilter,
  allowFilterSlice,
}) => {
  const sliceByOptions = Object.values(fields).reduce((r, d) => {
    const newVariables = d.variables.reduce((r, d) => {
      return d.value !== state.variables[0].value ? [...r, d] : r
    }, [])
    return [...r, ...newVariables]
  }, [])

  const jsonState = JSON.stringify(state)
  useEffect(() => {
    Tooltip.rebuild()
  }, [jsonState])

  return (
    <div className="CAT-controls-staticcards">
      {staticFilter && (
        <div className="CAT-controls-staticcards-group"> {staticFilter}</div>
      )}
      {state.variables.map(({ value, filters, visualize }, i) => {
        const groups = Object.values(fields)
        return groups.map(({ variables, heading, subheading, id }) => {
          return (
            <div className="CAT-controls-staticcards-group" key={id}>
              {heading && (
                <div className="CAT-controls-staticcards-group-heading">
                  {heading}
                </div>
              )}
              <div>
                {variables.map(d => (
                  <StaticCardsOption
                    {...d}
                    key={d.value}
                    dispatch={dispatch}
                    currentValue={state.variables[0].value}
                    filters={filters}
                    options={d.options}
                    sliceByOptions={sliceByOptions}
                    state={state}
                    allowFilterSlice={allowFilterSlice}
                  />
                ))}
              </div>
              <div className="CAT-controls-staticcards-group-subheading">
                {subheading}
              </div>
              <Tooltip
                id="controls-tooltip"
                className="CATTooltip"
                delayHide={300}
                delayUpdate={100}
                effect="solid"
                place="right"
              />
            </div>
          )
        })
      })}
    </div>
  )
}

const StaticCardsOption = ({
  currentValue,
  value,
  label,
  dispatch,
  state,
  filters,
  options,
  sliceByOptions,
  text,
  allowFilterSlice,
}) => {
  const isChecked = currentValue === value

  const [ref, { height }] = useMeasure()
  const heightProps = useSpring({
    height: isChecked ? `${height}px` : '0px',
    overflow: 'hidden',
  })

  return (
    <div
      className={`CAT-controls-staticcards-item${
        isChecked ? '--active' : '--inactive'
      }`}
    >
      <label>
        <input
          type="radio"
          name="variable"
          value={value}
          checked={isChecked}
          onChange={() => {
            dispatch({
              type: 'setVariable',
              index: 0,
              value: value,
            })
          }}
        />
        <div
          className="CAT-controls-staticcards-item-label"
          data-for="controls-tooltip"
          data-tip={!isChecked ? text : undefined}
          data-html
        >
          <span>
            {label}{' '}
            {/* <div
              className={styles.definition}
              data-for="controls-tooltip"
              data-tip={!isChecked ? text : undefined}
              data-html
            >
              ?
            </div> */}
          </span>
          {!isChecked && <RightCarrot />}
        </div>
        {isChecked && text && (
          <div
            className="CAT-controls-staticcards-item-description"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        )}
      </label>
      {allowFilterSlice && (
        <a.div
          style={heightProps}
          className={'CAT-controls-staticcards-item-inner'}
        >
          <div ref={ref}>
            <Filters
              filters={filters}
              options={options}
              value={value}
              variableIndex={0}
              dispatch={dispatch}
              allowFilter={allowFilterSlice}
            />
            {filters.length > 0 && (
              <SliceBy
                options={sliceByOptions}
                dispatch={dispatch}
                value={state.sliceBy}
              />
            )}
          </div>
        </a.div>
      )}
    </div>
  )
}

const SliceBy = ({ options, dispatch, value }) => {
  const setSliceBy = value => {
    dispatch({
      type: 'setSliceBy',
      value,
    })
  }
  return (
    <div className="CAT-controls-variable-sliceby">
      <Dropdown
        isClearable={true}
        placeholder="Slice by"
        options={options}
        setVariable={setSliceBy}
        value={value}
      />
    </div>
  )
}

function AllFilters({ state, dispatch, fields }) {
  return (
    <div>
      {fields.map(({ columnName, options, text }, i) => {
        const isDisabled = state.dataView === columnName
        return (
          <div
            className={
              isDisabled
                ? styles.allFiltersGroupDisabled
                : styles.allFiltersGroup
            }
            key={columnName}
          >
            <div
              className={styles.allFiltersGroupHeading}
              data-tip={text}
              data-html
              data-for={'filters-tooltip'}
            >
              {columnName}
            </div>
            <Filters
              filters={state.variables[i] ? state.variables[i].filters : []}
              options={options}
              // value={value}
              variableIndex={i}
              dispatch={dispatch}
              variableKey={columnName}
              // allowFilter={allowFilterSlice}
            />
          </div>
        )
      })}

      <Tooltip
        id="filters-tooltip"
        className="CATTooltip"
        delayHide={300}
        delayUpdate={100}
        effect="solid"
        place="right"
      />
    </div>
  )
}

const Cards = ({ state, dispatch, fields, isStatic, canDrag = true }) => {
  const usedVariables = Object.values(state.variables).map(({ value }) => value)
  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = state.variables[dragIndex]
      dispatch({
        type: 'setVariables',
        value: update(state.variables, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      })
    },
    [state.variables, dispatch]
  )

  if (canDrag) {
    return (
      <DndProvider backend={HTML5Backend}>
        {state.variables.map(({ value, filters, visualize }, i) => {
          const otherUsedVariables = usedVariables.filter(
            (d, count) => count !== i
          )

          const options = Object.values(fields).filter(option => {
            return !otherUsedVariables.includes(option.value)
          })

          const canReset =
            !isStatic && i === Object.keys(state.variables).length - 1

          return (
            <Card moveCard={moveCard} key={value} index={i}>
              <Variable
                key={i}
                label={i}
                variableIndex={i}
                options={options}
                canReset={canReset}
                value={value}
                filters={filters}
                visualize={visualize}
                dispatch={dispatch}
                canDrag={canDrag}
              />
            </Card>
          )
        })}
      </DndProvider>
    )
  } else {
    //No drag
    return state.variables.map(({ value, filters, visualize }, i) => {
      const otherUsedVariables = usedVariables.filter((d, count) => count !== i)

      const options = Object.values(fields).filter(option => {
        return !otherUsedVariables.includes(option.value)
      })

      const canReset =
        !isStatic && i === Object.keys(state.variables).length - 1

      return (
        <Variable
          key={i}
          label={i}
          variableIndex={i}
          options={options}
          canReset={canReset}
          value={value}
          filters={filters}
          visualize={visualize}
          dispatch={dispatch}
          canDrag={canDrag}
        />
      )
    })
  }
}
