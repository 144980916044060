import React from "react";
import { useSpring, animated } from "react-spring";

const Arrow = ({ open }) => {
  const [props,set] = useSpring(() => ({
    transform: "rotate(0deg)",
    config: {
      tension: 300
    }
  }))
  set({
    transform: open ? "rotate(90deg)" : "rotate(0deg)"
  })

  return (
    <animated.svg style={props} width="15" height="26" viewBox="0 0 15 26">
      <path d="M1 1l12 12L1 25" stroke="#171919" strokeWidth="2" fill="none" />
    </animated.svg>
  )
}

export default Arrow;