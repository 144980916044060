import React, { useState } from "react";
import ObjectsToCsv from "objects-to-csv";
import DownloadIcon from "svgr/Download";

const DownloadCsv = ({ data }) => {
  const [string,setString] = useState()

  new ObjectsToCsv(data).toString()
    .then(res => setString(res))
  
  if (!string) { return null }

  return (
    <a className="IconToggle" download={`CJA Case Analysis.csv`} 
      href={'data:text/plain;charset=utf-8,' + encodeURIComponent(string)}>
      <div className="IconToggle-icon"><DownloadIcon /></div>
      <div className="IconToggle-label">.csv</div>
    </a>
  )
}

export default DownloadCsv
