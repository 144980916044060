import React from "react";
import { H } from "Heading";
import Image from "./Image";
import "./IconHero.scss";

const IconHero = ({ image, heading, text }) => {
  return <div className="IconHero">
    <Image className="IconHero-image" image={image} />
    <H className="IconHero-heading">{heading}</H>
    <div className="IconHero-explainer" dangerouslySetInnerHTML={{ __html: text }} />
  </div>
}

export default IconHero