import styles from './pretrial-release-dashboard.module.scss'
import buttonStyles from 'blocks/PeopleArrestsDashboardBlock.module.scss'
import RightCarrot from 'components/right-carrot'

const FilterSelector = ({
  items,
  note,
  label,
  onChange,
  current,
  selectionType = 'radio',
  changeViewLabel = (
    <span
      style={{
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      Change View:{' '}
    </span>
  ),
}) => {
  return (
    <fieldset
      className={`${styles.radio} ${
        !label && changeViewLabel ? styles.radioNoLabel : ''
      }`}
    >
      <div>
        {label && (
          <div className={styles.legend}>
            {label} {note && <p className={styles.note}>Note: {note}</p>}
          </div>
        )}
        <div>
          {changeViewLabel}
          {items.map(item => {
            const isActive =
              selectionType === 'radio'
                ? item === current
                : current.includes(item) ||
                  (item === 'All' && current.length === 0)
            return (
              <label
                key={item}
                className={
                  isActive
                    ? buttonStyles.activeToggleButton
                    : buttonStyles.toggleButton
                }
              >
                <input
                  type={selectionType}
                  className={styles.radioInput}
                  value={item}
                  name={label}
                  checked={isActive}
                  onChange={onChange(item)}
                />
                <span>{item}</span>
                <RightCarrot />
              </label>
            )
          })}
        </div>
      </div>
    </fieldset>
  )
}

export default FilterSelector
