import { useReducer } from "react";

export const reducer = (state = {}, action) => {
  switch (action.type) {
    case "INIT":
      return {
        ...state,
        [action.id]: {
          ...defaultState,
          ...action.initialState,
        },
      };
    case "SET_CATEGORIES":
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          ...resetState,
          categories: action.value,
        },
      };

    case "SET_YEARS":
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          ...resetState,
          years: action.value,
        },
      };

    case "SET_QUERY":
      if (action.value === state[action.id].query) {
        return state;
      }
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          ...resetState,
          query: action.value,
        },
      };
    case "SET_ORDERBY":
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          ...resetState,
          orderBy: action.value,
        },
      };

    case "INCREMENT_PAGE":
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          page: state[action.id].page + 1,
        },
      };

    case "ADD_RESULTS":
      const updatedResults = [...state[action.id].results, ...action.value];
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          results: updatedResults,
          fetching: false,
        },
      };

    case "NO_MORE":
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          noMore: true,
        },
      };

    case "FETCHING":
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          fetching: typeof action.value !== "undefined" ? action.value : true,
        },
      };

    default:
      return state;
  }
};
export const setQuery = (value) => ({
  type: "SET_QUERY",
  value,
});
export const setCategories = (value) => ({
  type: "SET_CATEGORIES",
  value,
});

export const setYears = (value) => ({
  type: "SET_YEARS",
  value,
});

export const setOrder = (value) => ({
  type: "SET_ORDERBY",
  value,
});

const resetState = {
  results: [],
  page: 1,
  noMore: false,
};
const defaultState = {
  section: null,
  ids: null,
  categories: [],
  years: [],
  query: "",
  orderBy: "score",
  fetching: false,
  ...resetState,
};

export const useStreamReducer = () => {
  return useReducer(reducer, {});
};
// ...defaultState,
// ...initialState
