import React, { useRef, useEffect, useState } from 'react'
import { useTransition, a, useSpring } from 'react-spring'
import { useFetchStore } from 'Fetch'
import { Link, NavLink } from 'react-router-dom'
import { fetchData } from 'redux/actions'
import { useSelector } from 'react-redux'
import SearchIcon from 'svgr/Search'
import Menu from './Menu'
import ResourcesBar from './ResourcesBar'
import { useFadein } from 'animations'
import { useScrollDirection } from 'react-use-scroll-direction'
import SVG from 'react-inlinesvg'
import styles from './nav.module.scss'
import './Nav.scss'

const navEndpoint = `${process.env.REACT_APP_API}/nav.json`
const footerEndpoint = `${process.env.REACT_APP_API}/footer.json`

function isMobile() {
  return (
    typeof window !== 'undefined' &&
    !window.matchMedia(`(min-width: 768px)`).matches
  )
}
const Nav = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [mobile, setMobile] = useState(isMobile())
  const showResources = useSelector(state => state.style.showResourcesBar)

  useEffect(() => {
    const onResize = () => {
      setMobile(isMobile())
    }
    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [setMobile])

  const data = useFetchStore(navEndpoint)
  const footerData = useFetchStore(footerEndpoint)
  const { a, props } = useFadein()

  const [isUp, setIsUp] = useState(true)
  const { scrollDirection } = useScrollDirection()
  const [negativeScroll, setNegativeScroll] = useState(true)

  useEffect(() => {
    function scroll() {
      setNegativeScroll(window.scrollY <= 0)
    }
    window.addEventListener('scroll', scroll)
    return () => window.removeEventListener('scroll', scroll)
  }, [])
  useEffect(() => {
    if (scrollDirection) {
      const nowIsUp = scrollDirection === 'UP'
      setIsUp(nowIsUp)
    }
  }, [scrollDirection])

  const showHideProps = useSpring({
    transform:
      negativeScroll || isUp || menuOpen
        ? 'translateY(0%)'
        : 'translateY(-100%)',
  })

  return (
    <>
      <a.nav className={`Nav`} style={props}>
        <a.div className={'Nav-inner receives-theme'} style={showHideProps}>
          {footerData && showResources && <ResourcesBar {...footerData} />}
          <div className="Nav-inner-pad">
            <Link to="/" className="Nav-logo">
              {data && data.logo && (
                <>
                  <SVG src={data?.logo.url} className={styles.logo} />
                  <SVG
                    src={
                      data && data.flatLogo ? data.flatLogo.url : data.logo.url
                    }
                    className={styles.flatLogo}
                  />
                </>
              )}
            </Link>
            {mobile ? (
              <MenuButton
                open={menuOpen}
                onClick={() => setMenuOpen(!menuOpen)}
              />
            ) : (
              <div className="Nav-toplinks">
                {data && <Links data={data} />}
              </div>
            )}
          </div>
        </a.div>
      </a.nav>
      <Menu data={data} open={menuOpen && mobile} setOpen={setMenuOpen} />
    </>
  )
}

const Links = ({ data, showLabel }) => {
  const [active, setActive] = useState()
  const [activeSubnav, setActiveSubnav] = useState()
  const refs = new Array(data.blocks.length).fill(useRef())
  const subnavs = data.blocks.reduce((result, block, i) => {
    result[block.id] = {
      ref: refs[i],
      comp: (
        <Subnav
          forwardRef={refs[i]}
          items={block.children}
          setActive={clear => {
            setActiveSubnav(clear ? null : block.id)
          }}
        />
      ),
    }
    return result
  }, {})

  useEffect(() => {
    const onBlur = () => {
      setActive(null)
      setActiveSubnav(null)
    }
    window.addEventListener('blur', onBlur)

    return () => {
      window.removeEventListener('blur', onBlur)
    }
  }, [])

  return (
    <>
      {data.blocks.map(block => {
        switch (block.type) {
          case 'link':
            return (
              <NavLink
                className={'nav-item'}
                key={block.id}
                to={block.url.replace(process.env.DEFAULT_SITE_URL, '')}
              >
                {block.text}
              </NavLink>
            )

          case 'subnav':
            const isActive = [active, activeSubnav].includes(block.id)
            // const Sub = isActive && subnavs[active || activeSubnav]
            return (
              <SubnavLabel
                className={`nav-item ${isActive ? 'active' : ''}`}
                key={block.id}
                setActive={clear => {
                  !!block.children && setActive(clear ? null : block.id)
                }}
                showLabel={showLabel}
                Sub={subnavs[block.id]}
                {...block}
              />
            )

          default:
            return null
        }
      })}
      <NavLink to="/search" className="SearchToggle nav-item">
        <SearchIcon alt="Search" />
        {/* Search */}
      </NavLink>
    </>
  )
}

export default Nav

Nav.serverFetch = [
  () => fetchData(navEndpoint),
  () => fetchData(footerEndpoint),
]

const closed = {
  opacity: 0,
  // transform: `translateY(-100%)`
}
const open = {
  opacity: 1,
  // transform: `translateY(0%)`
}
const Subnav = ({ items = [], setActive, forwardRef }) => {
  const show = !!items

  const t = useTransition(show, null, {
    from: closed,
    enter: open,
    leave: closed,
  })

  return t.map(
    ({ item, key, props }) =>
      item && (
        <a.ul
          ref={forwardRef}
          key={key}
          className={'Nav-subnav receives-theme'}
          // style={props}
          onFocus={() => setActive()}
          onBlur={() => setActive(true)}
          onMouseEnter={() => setActive()}
          onMouseLeave={() => setActive(true)}
        >
          {items.map(block => {
            return (
              <li key={block.id}>
                <NavLink className={'nav-item'} to={block.url}>
                  {block.text}
                </NavLink>
              </li>
            )
          })}
        </a.ul>
      )
  )
}
const SubnavLabel = ({ text, setActive, className, Sub }) => {
  return (
    <>
      <div
        className={`Nav-subnavlabel ${className}`}
        onFocus={() => setActive()}
        onBlur={() => setActive(true)}
        onMouseEnter={() => setActive()}
        onMouseLeave={() => setActive(true)}
      >
        {text}
        {Sub.comp}
      </div>
    </>
  )
}

const MenuButton = ({ open, onClick }) => {
  return (
    <button className="MenuToggle" onClick={onClick}>
      {Array(3)
        .fill(null)
        .map((d, i) => (
          <div key={i} className="receives-opposing-theme" />
        ))}
    </button>
  )
}
