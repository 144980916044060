import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setTheme } from "redux/actions";

const useTheme = (theme) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTheme(theme));
  }, [dispatch, theme]);
};

export default useTheme;
