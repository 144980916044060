import React from "react";
import { H } from "Heading";
import Link from "components/Link";

const CtaExplainer = ({ heading, text, link }) => {
  return <div className="CtaExplainer">
    <H className="CtaExplainer-heading">{heading}</H>
    <div className="CtaExplainer-body" dangerouslySetInnerHTML={{ __html: text }} />
    <Link className="CtaExplainer-link" {...link} />
  </div>
}

export default CtaExplainer