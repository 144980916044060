import React from "react";
import { useTransition, a } from "react-spring";
import Link from "components/Link";
import email from "svg/email.svg";
import phone from "svg/phone.svg";
import SVG from "react-inlinesvg";

const ResourcesBar = (props) => {
  const {
    resourcesLink,
    resourcesHeading,
    resourcesPhone,
    resourcesEmail,
  } = props;

  const t = useTransition(true, null, {
    from: {
      opacity: 0,
      transform: `translateY(-100%)`,
    },
    enter: {
      opacity: 1,
      transform: `translateY(-0%)`,
    },
    leave: {
      transform: `translateY(-100%)`,
    },
  });

  return t.map(({ item, key, props }) => (
    <a.div key={key} style={props} className={"ResourcesBar receives-theme"}>
      <div className={"ResourcesBar-inner"}>
        <Link url={resourcesLink.url} useRouter>
          {resourcesHeading}
        </Link>
        <ul>
          <li>
            <a href={`tel:${resourcesPhone}`}>
              <SVG src={phone} />
              {resourcesPhone}
            </a>
          </li>
          <li>
            <a href={`mailto:${resourcesEmail}`}>
              <SVG src={email} />
              {resourcesEmail}
            </a>
          </li>
        </ul>
      </div>
    </a.div>
  ));
};

export default ResourcesBar;
