import RightCarrot from 'components/right-carrot'
import styles from './dashboard-layout.module.scss'
import ReactTooltip from 'react-tooltip'

function DashboardLayout({
  showControls,
  controls,
  results,
  isDesktop,
  reset,
  globalControls,
  showMobile,
  setShowMobile,
}) {
  return (
    <>
      {globalControls || (isDesktop && showControls) ? (
        <div className={styles.globalControls}>{globalControls}</div>
      ) : null}
      <div
        className={showControls ? styles.elementWithControls : styles.element}
      >
        {showControls && isDesktop ? (
          <div className={styles.controls}>
            <div className={styles.controlsHeader}>
              Filter
              {isDesktop && showControls && (
                <button className={styles.allFiltersReset} onClick={reset}>
                  Reset
                </button>
              )}
            </div>
            <div className={styles.controlsInner}>{controls}</div>
          </div>
        ) : (
          showControls && (
            <MobileControls
              isDesktop={isDesktop}
              show={showMobile}
              setShow={setShowMobile}
            >
              <div className={styles.controls}>
                <button
                  className={styles.mobileAllFiltersReset}
                  onClick={reset}
                >
                  Reset
                </button>
                <div>{controls}</div>
              </div>
            </MobileControls>
          )
        )}
        <div className={styles.results}>{results}</div>
        <ReactTooltip
          id="view-tooltip"
          className={'CATTooltip view-tooltip'}
          position="top"
          effect="solid"
        />
      </div>
    </>
  )
}

export default DashboardLayout

function MobileControls({ children, show, setShow }) {
  return (
    <div>
      <button
        className={show ? styles.toggleShow : styles.toggle}
        onClick={() => setShow(!show)}
      >
        {show ? 'View Results' : 'Filter data'}
        <RightCarrot />
      </button>
      {show && children}
    </div>
  )
}
