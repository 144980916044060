import React from "react";

const SvgQuestionMark = props => (
  <svg viewBox="0 0 18 18" {...props}>
    <path
      d="M5.6 7.1c0-.1-.1-.3-.1-.5 0-1.8 1.3-3.3 3.5-3.3 2 0 3.3 1.2 3.3 3 0 1.5-1.1 2.3-2.3 3.1-.6.4-.8.9-.9 1.4v.3h-.6l-.1-.2c0-.2-.1-.4-.1-.5 0-.7.2-1.3 1.2-1.9.8-.5 1.5-1.2 1.5-2.2 0-1-.8-1.7-2-1.7-1.3 0-2.2.9-2.2 2.1V7l-1.2.1zm2.5 7.7v-1.9h1.7v1.9H8.1z"
      fill="#334456"
    />
  </svg>
);

export default SvgQuestionMark;
