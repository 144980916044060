import { useState, useEffect } from "react";
import fetch from "isomorphic-fetch";
import { fetchData } from "redux/actions";
import { useDispatch, useSelector } from "react-redux";

const Fetch = ({ endpoint, children, clearOnUpdate = true }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    clearOnUpdate && setData(null);

    const controller = new AbortController();
    const signal = controller.signal;

    fetch(endpoint, { signal })
      .then((response) => response.json())
      .then(setData)
      .catch((err) => {
        console.log(err);
      });

    return () => {
      controller.abort();
    };
  }, [endpoint, clearOnUpdate]);
  return children(data);
};
export default Fetch;
export const useFetchStore = (endpoint) => {
  const data = useSelector(({ data }) => data[endpoint]);
  const dispatch = useDispatch();

  if (!endpoint) {
    return null;
  }

  if (!data && typeof window !== "undefined") {
    //prevent fetch during ssr serve
    dispatch(fetchData(endpoint));
  }
  return data;
};

// export const useFetchStorePromise = (endpoint) => new Promise((resolve,reject) => {
//   const data = useSelector(({ data }) => data[endpoint])
//   const dispatch = useDispatch()
//   if (!data && typeof window !== "undefined") { //prevent fetch during ssr serve
//     dispatch(fetchData(endpoint));
//   }
//   return data
// })
//
