import React, { createContext } from "react";

const Level = createContext(1);

export const HDown = props => {
  return (
    <Level.Consumer>{level =>
      <Level.Provider value={level + 1}>
        {props.children}
      </Level.Provider>
    }</Level.Consumer>
  );
}
export const H = props => {
  return (
    <Level.Consumer>{level => {
      const Heading = 'h' + Math.min(level, 6);
      return <Heading {...props} />;
    }}</Level.Consumer>
  );
}

export default H