import React from "react";

const IconAnchorsHero = ({ children, headerTag }) => {
  //If first block header tag, if not div
  const inner = <div className="IconAnchorsHero-inner">
    {children}
  </div>

  return headerTag
    ? <header className="IconAnchorsHero">
        {inner}
      </header>
    : inner
}

export default IconAnchorsHero;