import React, { useState } from "react";

const Image = ({
  image,
  color = "#eef5fb",
  className = "",
  imageSize,
  caption,
  hideCaption,
  setImageLoaded,
}) => {
  const [loaded, setLoaded] = useState();

  if (!image && !color) {
    return null;
  }
  if (!image && color) {
    return (
      <ColorImage
        color={color}
        className={className}
        caption={caption}
        hideCaption={hideCaption}
      />
    );
  }
  const { src, srcset, alt } = image;
  caption = caption || image.caption;

  function didLoad() {
    setLoaded(true);
  }

  return (
    <figure
      className={`Image Image--${imageSize} ${className} ${
        loaded ? "" : "loading"
      }`}
    >
      <img alt={alt} src={src} srcSet={srcset} onLoad={() => didLoad()} />
      {caption && !hideCaption && <figcaption>{caption}</figcaption>}
    </figure>
  );
};

export default Image;

const ColorImage = ({ color, className, caption, hideCaption }) => {
  return (
    <figure className={`Image ${className}`}>
      <div
        style={{ backgroundColor: color, width: "100%", paddingBottom: "100%" }}
      />
      {caption && !hideCaption && <figcaption>{caption}</figcaption>}
    </figure>
  );
};
