import React from 'react'
// import PretrialReleaseDashboard from 'components/pretrial-release-dashboard'
import NewOffenseDashboard from 'components/pretrial-release-dashboard/new-offense-dashboard'
import { useFetchStore } from 'Fetch'
import { ViewSwitcher } from './SupportiveServicesDashboardBlock'

const ArrestDashboardBlock = props => {
  const variableGroups = useFetchStore(
    `${process.env.REACT_APP_API}/${props.variableEndpoint}.json`
  )
  function setView(value) {
    props.dispatch({
      type: 'setDataView',
      value,
    })
    if (props.resetOnViewChange) {
      props.dispatch({
        type: 'resetAllFilters',
      })
    }
  }
  const currentView =
    variableGroups &&
    variableGroups.viewVariables &&
    variableGroups.viewVariables.find(
      d => d.columnName === props.state.dataView
    )

  const viewItems = variableGroups && variableGroups.viewVariables

  return (
    <NewOffenseDashboard
      {...props}
      globalControls={
        <ViewSwitcher
          items={viewItems}
          setView={setView}
          dataView={props.state.dataView}
        />
      }
      variableGroups={variableGroups}
      resultsEndpoint={props.resultsEndpoint ? props.resultsEndpoint : 'arrest-results'}
      tooltipUnit={'arrests'}
      // globalFilter={'Borough'}
      timeColumn="Quarter"
      showAllFilters={true}
      setView={setView}
      yAxisLabel={currentView?.yAxisLabel}
    />
  )
}

export default ArrestDashboardBlock
