const defaultState = {
  theme: "white",
  showResourcesBar: false,
};

const style = (state = defaultState, action) => {
  switch (action.type) {
    case "SET_THEME":
      return {
        ...state,
        theme: action.value,
      };

    case "SET_RESOURCES_BAR":
      return {
        ...state,
        showResourcesBar: action.value,
      };

    default:
      return state;
  }
};

export default style;
