const defaultState = {};

const data = (state = defaultState, action) => {
  switch (action.type) {
    case "STORE_DATA":
      return {
        ...state,
        [action.endpoint]: action.data,
      };

    default:
      return state;
  }
};

export default data;
