import React from "react";
import Image from "./Image.js";
import { HDown } from "Heading";

const Hero = ({ heading, image, children, headerTag }) => {
  const Tag = headerTag ? 'header' : 'div';
  return <Tag className="OurServicesHero">
    <div>
      <h1>{heading}</h1>
      <Image className="OurServicesHero-icon" image={image} />
      <div className="OurServicesHero-inner max">
        <HDown>{children}</HDown>
      </div>
    </div>
  </Tag>
}

export default Hero;