import React from "react";
import { Link as InternalLink } from "react-router-dom";

const Link = ({ url, Icon, label, children, useRouter, external, type, ...rest }) => {
  return useRouter
    ? <InternalLink to={url} {...rest}>{children ? children : label}</InternalLink>
    : <a href={url} target={external ? "_blank" : undefined} rel="noopener noreferrer" {...rest}>
        {children ? children : label}
      </a>
}

export default Link;
