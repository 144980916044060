import React from "react";
import "./info-box.scss";

const InfoBox = ({ heading, text }) => {
  return (
    <div className="InfoBox">
      <div className="InfoBox-inner">
        <div className="InfoBox-heading">{heading}</div>
        <div
          className="InfoBox-text"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
    </div>
  );
};

export default InfoBox;
