import React from 'react'
// import { useFetchStore } from "Fetch";
// import useBreakpoint from "useBreakpoint";
import StreamCard from 'blocks/Stream/StreamCard'
import StreamFilter from './StreamFilter'
import './Stream.scss'
import { useStream } from 'components/Nav/Search'
//TODO Move this!

const Stream = ({ type = '', filterGroupHandle, entryIds, uid }) => {
  const section = type === 'mixed' ? ['publications', 'justiceJournal'] : type

  const [state, dispatch] = useStream(uid, {
    ids: entryIds,
    section,
    pageSize: entryIds ? entryIds.length : 6,
    orderBy: 'score',
  })

  const hasFilter = filterGroupHandle && filterGroupHandle !== 'none'

  return (
    <div className="Stream">
      {hasFilter && (
        <StreamFilter
          groupHandle={filterGroupHandle}
          state={state}
          dispatch={dispatch}
        />
      )}
      <Results state={state} dispatch={dispatch} />
    </div>
  )
}

export default Stream

export const Results = ({ state, dispatch }) => {
  const loadMore = () => {
    dispatch({
      type: 'INCREMENT_PAGE',
    })
  }
  if (!state) {
    return null
  }
  return (
    <div className="StreamResults">
      <div className="StreamCards">
        {state && state.results.length > 0
          ? state.results.map(d => <StreamCard key={d.id} {...d} />)
          : state &&
            state.noMore && (
              <div className="StreamCards-noresults">No results</div>
            )}
        {state.fetching &&
          new Array(state.pageSize)
            .fill(null)
            .map((d, i) => (
              <Placeholder key={`StreamCard--placeholder--${i}`} />
            ))}
      </div>
      {!state ||
      state.fetching ||
      state.noMore ||
      !state.results.length ? null : (
        <button className="Stream-loadmore" onClick={() => loadMore()}>
          Load More
        </button>
      )}
    </div>
  )
}

const Placeholder = () => <div className="StreamCard StreamCard--placeholder" />
