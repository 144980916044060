export function setupVariable({ label, columnName, description, ...variable }) {
  const options = variable.valuesOrder.map(({ col1 }) => ({
    label: col1,
    value: col1,
  }));
  return {
    ...variable,
    label: label ? label : columnName,
    value: columnName,
    options,
    columnName,
    text: description,
  };
}
