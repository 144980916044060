import React from "react";
import StreamCard from "blocks/Stream/StreamCard";
import "./article-featured-content.scss";

const ArticleFeaturedContent = ({ heading, text, entry }) => {
  return (
    <div className="ArticleFeaturedContent">
      <div className="ArticleFeaturedContent-inner">
        <div className="ArticleFeaturedContent-heading">{heading}</div>
        <div className="ArticleFeaturedContent-text">{text}</div>
        <div className="ArticleFeaturedContent-post">
          <StreamCard {...entry} />
        </div>
      </div>
    </div>
  );
};

export default ArticleFeaturedContent;
