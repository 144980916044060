import React from 'react'
// import PretrialReleaseDashboard from 'components/pretrial-release-dashboard'
import NewOffenseDashboard from 'components/pretrial-release-dashboard/new-offense-dashboard'
import styles from './PeopleArrestsDashboardBlock.module.scss'
import { useFetchStore } from 'Fetch'
import RightCarrot from 'components/right-carrot'
import { ViewSwitcher } from './SupportiveServicesDashboardBlock'

const PeopleArrestsDashboardBlock = props => {
  function setSubView(value) {
    props.dispatch({
      type: 'setSubViewAndClearFilters',
      value,
    })
  }

  function setDataView(value) {
    props.dispatch({
      type: 'setDataView',
      value,
    })
  }

  const variableGroups = useFetchStore(
    `${process.env.REACT_APP_API}/people-arrests-dashboard-variables.json`
  )

  const globalControls = props.showControls && (
    <div className={styles.toggle}>
      <span>Change View: </span>
      <ToggleButton
        label={'Person Characteristics'}
        onClick={() => setSubView('filter')}
        isActive={props.state.subView === 'filter'}
      />
      <ToggleButton
        isActive={props.state.subView === 'examine'}
        onClick={() => setSubView('examine')}
        label="Case Characteristics"
      />
    </div>
  )

  return (
    <>
      {props.state.subView === 'filter' ? (
        <NewOffenseDashboard
          {...props}
          variableGroups={variableGroups}
          resultsEndpoint={'person-arrest-results'}
          tooltipUnit={'people'}
          timeColumn={'Year of Arrest'}
          uid={'people-arrests-filter'}
          globalControls={globalControls}
          showAllFilters
          setView={setDataView}
        />
      ) : (
        <>
          <NewOffenseDashboard
            {...props}
            variableGroups={{
              ...variableGroups,
              viewVariables: variableGroups.groups,
              filterVariables: variableGroups.displayVariable,
            }}
            resultsEndpoint={'person-arrest-results'}
            tooltipUnit={'people'}
            // globalFilter={['Number of Prosecuted Arrests']}
            timeColumn={'Year of Arrest'}
            allowFilterSlice={false}
            uid={'people-arrests-examine'}
            showAllFilters
            setView={setDataView}
            globalControls={
              <>
                {globalControls}
                <ViewSwitcher
                  setView={setDataView}
                  items={variableGroups.groups}
                  dataView={props.state.dataView}
                  size="small"
                />
              </>
            }
          />
        </>
      )}
    </>
  )
}

export default PeopleArrestsDashboardBlock

function ToggleButton({ onClick, isActive, label }) {
  return (
    <button
      className={styles[isActive ? 'activeToggleButton' : 'toggleButton']}
      onClick={onClick}
    >
      <span>{label}</span> <RightCarrot />
    </button>
  )
}
