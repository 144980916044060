import React from 'react'
import './Things.scss'
import { H } from 'Heading'

const Things = ({ heading, children }) => {
  return (
    <div className="Things__container">
      <H className="Things__heading">{heading}</H>
      {children && children.length > 0 && (
        <ul className="Things">{children}</ul>
      )}
    </div>
  )
}

export default Things
