import React from "react"

const SvgCaret = props => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="9.452"
    height="14.838"
    viewBox="0 0 9.452 14.838"
  >
    <path
      id="Path_3"
      data-name="Path 3"
      d="M2398.313,331.029l3.756,4.4,2.253,2.641,3-3.521,3-3.521"
      transform="translate(-329.619 2411.741) rotate(-90)"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
  </svg>
)

export default SvgCaret
