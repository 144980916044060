import React, { useEffect, useState } from 'react'
import Controls from 'components/CAT/Controls'
import Results from './results'
import 'components/CAT/cat.scss'
import Loading from 'components/Loading'
import './pretrial-release-dashboard.scss'
import FilterSelector from './filter-selector'
import { setupVariable } from './helpers'
import DashboardLayout from './dashboard-layout'

const PretrialReleaseDashboard = ({
  showControls = true,
  childrenProps,
  variableGroups,
  resultsEndpoint,
  tooltipUnit,
  globalFilter,
  timeColumn,
  title,
  allowFilterSlice = true,
  isDesktop,
  state,
  dispatch,
  globalControls,
}) => {
  return (
    <div>
      <div className={`CAT ${!showControls ? 'CAT-nosettings' : ''}`}>
        <Tool
          state={state}
          dispatch={dispatch}
          showControls={showControls}
          cta={childrenProps}
          variableGroups={variableGroups}
          resultsEndpoint={resultsEndpoint}
          tooltipUnit={tooltipUnit}
          globalFilter={globalFilter}
          timeColumn={timeColumn}
          title={title}
          allowFilterSlice={allowFilterSlice}
          isDesktop={isDesktop}
          globalControls={globalControls}
        />
      </div>
    </div>
  )
}

const Tool = ({
  state,
  dispatch,
  showControls,
  cta,
  variableGroups,
  resultsEndpoint,
  tooltipUnit,
  globalFilter,
  timeColumn,
  title,
  allowFilterSlice,
  isDesktop,
  globalControls,
}) => {
  useEffect(() => {
    if (
      variableGroups &&
      (state.variables.length === 0 || !state.variables[0])
    ) {
      dispatch({
        type: 'setVariable',
        value: variableGroups.groups[0].variables[0].columnName,
        index: 0,
      })
    }
  }, [variableGroups, state.variables.length, state.variables, dispatch])

  const [showMobile, setShowMobile] = useState(false)

  if (!variableGroups || !state.variables[0]) {
    return <Loading style={{ margin: '4em 0' }} />
  }

  // if (error) {
  //   return 'Error'
  // }

  const fields = variableGroups.groups.map(group => {
    return {
      ...group,
      variables: group.variables.map(setupVariable),
    }
  })

  const allVariables = fields.reduce((r, d) => {
    return [...r, ...d.variables]
  }, [])

  const currentVariable =
    state.variables[0].value &&
    allVariables.find(
      ({ columnName }) => columnName === state.variables[0].value
    )
  const currentLabel = currentVariable?.label
    ? currentVariable.label
    : currentVariable?.columnName

  const sliceByLabel = state.sliceBy
    ? allVariables.find(({ columnName }) => columnName === state.sliceBy).label
    : null

  const globalFilterArray = Array.isArray(globalFilter)
    ? globalFilter
    : [globalFilter]
  const hasBoroughSelector =
    globalFilterArray.includes('Borough') ||
    globalFilterArray.includes('Most Frequent Borough')
  const hasFollowUpSelector = globalFilterArray.includes(
    'New Offense Follow-up Period'
  )
  const hasNumberOfArrestsSelector = globalFilterArray.includes(
    'Number of Prosecuted Arrests'
  )

  function reset() {
    dispatch({
      type: 'resetVariables',
    })
  }

  return (
    <DashboardLayout
      reset={reset}
      isDesktop={isDesktop}
      showControls={showControls}
      showMobile={showMobile}
      setShowMobile={setShowMobile}
      globalControls={globalControls}
      controls={
        <Controls
          fields={fields}
          state={state}
          dispatch={dispatch}
          oneVariable
          allowFilterSlice={allowFilterSlice}
          staticFilter={
            (hasBoroughSelector ||
              hasFollowUpSelector ||
              hasNumberOfArrestsSelector) && (
              <>
                {hasBoroughSelector && (
                  <FilterSelector
                    label="Borough"
                    items={[
                      'All',
                      'Manhattan',
                      'Brooklyn',
                      'Queens',
                      'Bronx',
                      'Staten Island',
                    ]}
                    onChange={value => () => {
                      dispatch({
                        type: 'setBorough',
                        value,
                      })
                    }}
                    current={state.borough}
                  />
                )}
                {hasFollowUpSelector && (
                  <FilterSelector
                    label="Follow Up Period"
                    items={['3 months', '6 months', '9 months', '1 year']}
                    onChange={value => () => {
                      dispatch({
                        type: 'setFollowUp',
                        value,
                      })
                    }}
                    current={state.followUp}
                  />
                )}
                {hasNumberOfArrestsSelector && (
                  <NumberOfArrestsFilterSelector
                    dispatch={dispatch}
                    numberOfArrests={state.numberOfArrests}
                  />
                )}
              </>
            )
          }
        />
      }
      results={
        <Results
          allVariables={allVariables}
          fields={fields}
          state={state}
          dispatch={dispatch}
          label={currentLabel}
          column={currentVariable?.columnName}
          sliceByLabel={sliceByLabel}
          glossaryDownload={variableGroups.download}
          yAxisLabel={variableGroups.yAxisLabel}
          cta={cta}
          endpoint={resultsEndpoint}
          tooltipUnit={tooltipUnit}
          globalFilter={globalFilter}
          showFilteredInKey={true}
          timeColumn={timeColumn}
          title={title}
          hasFollowUpSelector={hasFollowUpSelector}
          isDesktop={isDesktop}
        />
      }
    />
  )
}

export default PretrialReleaseDashboard

export function NumberOfArrestsFilterSelector({ dispatch, numberOfArrests }) {
  return (
    <FilterSelector
      label="Number of Prosecuted Arrests"
      items={['All', '1', '2', '3', '4', '5', '6', '7-8', '9+']}
      onChange={value => () => {
        dispatch({
          type: 'setNumberOfArrests',
          value,
        })
      }}
      current={numberOfArrests}
      selectionType={'checkbox'}
    />
  )
}
