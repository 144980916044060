import { useSpring, a } from "react-spring"

export const useFadein = () => {
  const props = useSpring({
    from: {
      opacity: 0
    },
    to: {
      opacity: 1
    }
  })

  return {
    a,
    props
  }
}