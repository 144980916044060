const colors = [
  '#5cb8ba', //green
  '#f5db58', //yellow
  '#0377d4', //blue
  '#63195e',
  '#fadac8', //pink
  '#6637ff', //purple

  '#24847c',
  '#ea813b',
  '#bdd7f4',
  '#72a4b6',
  '#8eca3a',
  '#a05fa5', //deep purp
  '#f0a804', //golden

  '#5cb8ba', //green
  '#f5db58', //yellow
  '#0377d4', //blue
  '#6637ff', //purple
  '#fadac8', //pink
  '#63195e',
  '#24847c',
  '#ea813b',
  '#bdd7f4',
  '#72a4b6',
  '#8eca3a',
]

export default colors
