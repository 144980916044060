import { useSpring, a as anim } from "react-spring";

export const a = anim;
export const useFade = () => {
  const props = useSpring({
    from: {
      opacity: 0
    },
    opacity: 1
  });
  return props
}