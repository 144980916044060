import React, { useReducer, useEffect } from "react";
import { useFetchStore } from "Fetch";
import Controls from "./Controls";
import Results from "./Results";
import useTheme from "useTheme";
import "./cat.scss";
import useMeasure from "react-use-measure";

import { scaleModes } from "components/data-viz/constants";

import Loading from "components/Loading";
import DashboardLayout from "components/pretrial-release-dashboard/dashboard-layout";

const FIELD = "Field";
const VALUE = "Short Title";
const OPTION_LABEL = "Value";
const ID = "ID";

const reducer = (state, action) => {
  switch (action.type) {
    case "setYear":
      return {
        ...state,
        year: action.value,
      };
    case "addVariable":
      return {
        ...state,
        variables: [
          ...state.variables,
          newVariable(state.variables.length + 1),
        ],
      };

    case "setVariable":
      const newVariables = [...state.variables];
      newVariables[action.index] = {
        ...newVariables[action.index],
        value: action.value,
        filters: [],
      };

      return {
        ...state,
        variables: newVariables,
      };

    case "setVariables":
      return {
        ...state,
        variables: action.value,
      };

    case "resetVariables":
      return {
        ...state,
        variables: [],
      };

    case "removeVariable": {
      return {
        ...state,
        variables: state.variables.filter((d, i) => action.variable !== i),
      };
    }

    case "toggleFilter":
      state.variables[action.index] = {
        ...state.variables[action.index],
        filters: state.variables[action.index].filters.includes(action.value)
          ? state.variables[action.index].filters.filter(
              (d) => d !== action.value
            )
          : [...state.variables[action.index].filters, action.value],
      };
      return {
        ...state,
      };

    case "resetFilters":
      state.variables[action.index] = {
        ...state.variables[action.index],
        filters: [],
      };
      return {
        ...state,
      };

    case "toggleVisualized":
      state.variables[action.index] = {
        ...state.variables[action.index],
        visualize: action.value,
      };
      return {
        ...state,
      };

    case "setView":
      return {
        ...state,
        view: action.value,
      };

    case "setScaleMode":
      return {
        ...state,
        scaleMode: action.value,
      };

    default:
      throw new Error(`unknown action type: ${action.type}`);
  }
};

function newVariable(key) {
  return {
    key,
    value: null,
    filters: [],
    visualize: true,
  };
}

const initialState = {
  variables: [],
  view: "Bars",
  scaleMode: scaleModes.ABSOLUTE,
};

const initial =
  module.hot && typeof window !== "undefined" && localStorage.getItem("CAT")
    ? JSON.parse(localStorage.getItem("CAT"))
    : initialState;

const Index = ({ settingsJson, showControls }) => {
  useTheme("white");

  const initialState = settingsJson ? JSON.parse(settingsJson) : initial;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    localStorage.setItem("CAT", JSON.stringify(state));
  }, [state]);

  const [ref, { width }] = useMeasure();
  const isDesktop = width >= 800;

  return (
    <div
      className={`CAT ${isDesktop ? "CAT--desktop" : "CAT--mobile"}`}
      ref={ref}
    >
      <Tool
        isDesktop={isDesktop}
        state={state}
        dispatch={dispatch}
        showControls={showControls}
      />
    </div>
  );
};

export default Index;

const Tool = ({ state, dispatch, showControls, isDesktop }) => {
  const meta = useFetchStore(`${process.env.REACT_APP_CAT}/variables`);

  if (!meta) {
    return <Loading style={{ margin: "4em 0" }} />;
  }

  const yearOptions = meta.years.map((year) => ({
    label: year["YEAR"],
    value: year["YEAR"],
  }));

  const fields = meta.variables.reduce(
    (r, d) => {
      if (!r[d[VALUE]]) {
        r[d[VALUE]] = {
          label: d[FIELD],
          value: d[VALUE],
          options: [],
        };
      }
      const options = r[d[VALUE]].options;
      if (!options.find((opt) => opt[OPTION_LABEL] === d[OPTION_LABEL])) {
        options.push({
          label: d[OPTION_LABEL],
          value: d[ID],
        });
      }
      return r;
    },
    {
      YEAR: {
        label: "Year",
        value: "YEAR",
        options: yearOptions,
      },
    }
  );

  return (
    <DashboardLayout
      showControls={showControls}
      isDesktop={isDesktop}
      controls={
        <Controls
          yearOptions={yearOptions}
          fields={fields}
          state={state}
          dispatch={dispatch}
        />
      }
      results={
        <Results
          fields={fields}
          state={state}
          dispatch={dispatch}
          showControls={showControls}
        />
      }
    />
  );
};
