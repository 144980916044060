import React from "react";
import { useFetchStore } from "Fetch";

import "./Jobs.scss";

const endpoint = "https://boards-api.greenhouse.io/v1/boards/criminaljusticeagency/jobs"

const Jobs = () => {
  const data = useFetchStore(endpoint);
  if (!data) { return null }
  const { jobs } = data

  return <ul className="Jobs">
    {jobs.map(({
      title,
      absolute_url
    }) => <li key={absolute_url} className="Jobs-item">
      <a href={absolute_url} target="_blank" rel="noopener noreferrer">
        <div className="Jobs-item-heading">{title}</div>
        <div className="Jobs-item-cta">View</div>
      </a>
    </li>)}
  </ul>
}

export default Jobs