import React, { useState, useEffect } from 'react'
import Bars from './bars'
import Link from 'components/Link'
import styles from './results.module.scss'

var queryString = params =>
  Object.keys(params)
    .map(key => key + '=' + encodeURIComponent(params[key]))
    .join('&')

const Results = ({
  state,
  dispatch,
  label,
  column,
  sliceByLabel,
  glossaryDownload,
  fields,
  allVariables,
  cta,
  endpoint,
  yAxisLabel,
  tooltipUnit,
  staticColumn,
  timeColumn = 'Month',
  showFilteredInKey,
  hasFollowUpSelector,
  isDesktop,
  excludePeriods,
  // xDomainDeprecated,
  title,
}) => {
  const [data, setData] = useState()
  const [xDomain, setXDomain] = useState()
  const stateVariablesString = JSON.stringify(
    state.variables.filter(({ value }) => value !== null)
  )

  function setScaleMode(mode) {
    dispatch({
      type: 'setScaleMode',
      value: mode,
    })
  }

  useEffect(() => {
    const stateVariables = JSON.parse(stateVariablesString)
    const filter = Object.values(stateVariables).reduce(
      (result, { value, filters }) => {
        if (filters && filters.length) {
          result[value] = filters
        }
        return result
      },
      {}
    )
    const group = staticColumn
      ? staticColumn
      : stateVariables.reduce((r, d, i) => {
        if (i > 0) {
          r += ','
        }
        return (r += d.value)
      }, '')
    const groupBy = state.sliceBy ? state.sliceBy : group

    const params = {
      group: [`${timeColumn},${groupBy}`],
      filter: JSON.stringify(filter),
      borough: state.borough,
      followUp: state.followUp,
      view: state.dataView
    }

    if (state.numberOfArrests.length > 0) {
      params.numberOfArrests = JSON.stringify(state.numberOfArrests)
    }

    dispatch({
      type: 'loadingResults',
    })

    let mounted = true
    fetch(`${process.env.REACT_APP_CAT}/${endpoint}?${queryString(params)}`)
      .then(res => res.json())
      .then(res => {
        if (mounted) {
          const parsed = res.results.map(d => ({ ...d, value: +d.value }))
          setData(parsed)
          setXDomain(res.xDomain)
          dispatch({
            type: 'doneLoading',
          })
        }
      })
      .catch(err => {
        console.error(err)
        if (mounted) {
          dispatch({
            type: 'errorResults',
            value: true,
          })
        }
      })
    return () => (mounted = false)
  }, [
    stateVariablesString,
    state.sliceBy,
    state.borough,
    state.followUp,
    state.numberOfArrests,
    dispatch,
    endpoint,
    staticColumn,
    timeColumn,
    state.dataView,
  ])

  if (state.errorResults) {
    return 'Error'
  }

  const filters = state.variables.reduce((r, d) => {
    if (d.filters) {
      r[d.value] = d.filters
    }
    return r
  }, {})
  const theColumn = staticColumn ? staticColumn : column ? column : null
  const theLabel = staticColumn ? staticColumn : label

  if ((!data || data.length === 0) && !state.loadingResults) {
    return <div className="CAT-nodata">No Data to Display</div>
  }

  return (
    <div className={styles.element}>
      {theColumn && xDomain && (
        <Bars
          xDomain={xDomain.filter(d => !excludePeriods || !excludePeriods.includes(d))}
          fields={fields}
          allVariables={allVariables}
          data={data}
          filters={filters}
          column={theColumn}
          label={theLabel}
          sliceBy={state.sliceBy}
          sliceByLabel={sliceByLabel}
          loading={state.loadingResults}
          scaleMode={state.scaleMode}
          setScaleMode={setScaleMode}
          glossaryDownload={glossaryDownload}
          yAxisLabel={yAxisLabel}
          tooltipUnit={tooltipUnit}
          borough={state.borough}
          followUp={hasFollowUpSelector && state.followUp}
          numberOfArrests={state.numberOfArrests}
          timeColumn={timeColumn}
          showFilteredInKey={showFilteredInKey}
          title={title}
          isLoading={state.loadingResults}
          isStaticColumn={staticColumn ? true : false}
          isDesktop={isDesktop}
        />
      )}
      {cta && cta[0] && <Cta {...cta[0]} />}
    </div>
  )
}

export default Results

const Cta = props => {
  switch (props.type) {
    case 'anchorLink':
      return (
        <a className={'CAT-results-cta'} href={props.url}>
          {props.label}
        </a>
      )

    default:
      return <Link className={'CAT-results-cta'} {...props} />
  }
}
