import React from "react";

const SvgPerson = props => (
  <svg width={18} height={19} {...props}>
    <g fill="#FFF" fillRule="nonzero">
      <circle cx={9} cy={5} r={5} />
      <path d="M18 19c0-5.523-4.03-10-9-10s-9 4.477-9 10h18z" />
    </g>
  </svg>
);

export default SvgPerson;
