import React from 'react'
import { Shell } from 'components/Page'
import { H } from 'Heading'
import Image from 'blocks/Image'
import Stream from 'blocks/Stream'
import Heading from 'blocks/Heading'
import Container from 'Container'
import { oxford, formatDate } from 'format'
import './Article.scss'

const Article = props => (
  <Shell
    builderType="article"
    mainClassName="Article"
    hero={data => <ArticleHero {...data} />}
    afterBody={data =>
      data.related &&
      data.related.length > 0 && (
        <Container className="Article-related">
          <Heading text="Related Content" />
          <Stream entryIds={data.related} uid={`Article related ${data.id}`} />
        </Container>
      )
    }
    {...props}
  />
)

const ArticleHero = ({ title, description, date, image, authors }) => {
  return (
    <header className="ArticleHero">
      <H className="ArticleHero-heading">{title}</H>
      <div className="ArticleHero-text">{description}</div>
      <div className="ArticleHero-date">{formatDate(date)}</div>
      {authors && authors.length > 0 && (
        <div className="ArticleHero-authors">by {oxford(authors)}</div>
      )}
      {image && <Image className="ArticleHero-image" image={image} />}
    </header>
  )
}

export default Article
