import { combineReducers } from "redux";
import data from "./data.js";
import { reducer as search } from "blocks/Stream/streamReducer";
import style from "./style";

export default combineReducers({
  data,
  style,
  search
});
