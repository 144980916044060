import React from "react";

const SvgEmail = props => (
  <svg width={26} height={16} {...props}>
    <g fill="#161818" fillRule="evenodd">
      <path d="M.904.013l12 9.987L24.763 0zM0 15l8.61-6.909L0 1.001zM17.087 8.183L25.806 15V1z" />
      <path d="M.904 15.987L24.763 16 16.35 9l-3.446 2.623-3.327-2.74z" />
    </g>
  </svg>
);

export default SvgEmail;
