import React, { useState, useReducer } from "react";
import { useTransition, animated } from "react-spring";
// import { scaleLinear } from "d3-scale";
import { useFetchStore } from "Fetch";
// import { SegmentedBar, W } from "components/CAT/views/Bars";
import "./ReleaseAssessment.scss";
import Loading from "components/Loading";
import useMatchMedia from "useMatchMedia";

const colors = {
  blue: "#0377d4",
  green: "#5cb8ba",
  yellow: "#f5db58",
  purple: "#6637ff",
  salmon: "#fadac8",
  plum: "#63195e",
  forest: "#24847c",
  orange: "#ea813b",
  sky: "#bdd7f4",
  palegreen: "#72a4b6",
  lime: "#8eca3a",
};

const color = {
  ror: colors.green,
  considerAllOptions: colors.yellow,
  noRor: colors.purple,
};

const Fetcher = ({ id }) => {
  const data = useFetchStore(
    `${process.env.REACT_APP_API}/release-assessment/${id}.json`
  );
  if (!data) {
    return <Loading className="Loading--module" />;
  }
  return <ReleaseAssessment {...data} />;
};
export default Fetcher;

export const Route = ({ match }) => {
  const id = match.params.id;
  if (!id) {
    return "No id";
  }

  return <Fetcher id={id} />;
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setAnswer":
      return {
        ...state,
        answers: {
          ...state.answers,
          [action.questionId]: action.choice,
        },
      };
    case "nextQuestion":
      return {
        ...state,
        questionIndex: state.questionIndex + 1,
      };
    case "prevQuestion":
      return {
        ...state,
        questionIndex: state.questionIndex - 1,
      };

    case "setQuestion":
      return {
        ...state,
        questionIndex: action.value,
      };

    case "reset":
      return defaultState;

    default:
      throw new Error();
  }
};
const defaultState = {
  answers: {},
  questionIndex: 0,
};
// const testState = {
//   "answers": {
//     "10367": -3,
//     "10372": -2,
//     "10375": -2,
//     "10378": -2,
//     "10551": 0
//   },
//   "questionIndex": 5
// }

const ReleaseAssessment = (props) => {
  const {
    title,
    initialScore,
    questions,
    recommendations,
    recommendationsHeading,
    appearanceRates,
  } = props;

  const [state, dispatch] = useReducer(reducer, defaultState);

  const currentQuestion = questions[state.questionIndex] || null;
  const [showIntro, setShowIntro] = useState(true);

  const nextQuestion = () => {
    dispatch({
      type: "nextQuestion",
    });
  };
  const chooseQuestion = (value) => () => {
    dispatch({
      type: "setQuestion",
      value,
    });
  };
  const choose = (questionId, choice) => {
    dispatch({
      type: "setAnswer",
      questionId,
      choice,
    });
  };
  const reset = () => {
    dispatch({
      type: "reset",
    });
  };

  const currentScore = Object.values(state.answers).reduce((r, d) => {
    r += d;
    return r;
  }, initialScore);

  const desktop = useMatchMedia("(min-width: 768px)");

  const appearanceRate = appearanceRates.reduce(
    (r, { minScore, maxScore, percentage }) => {
      if (currentScore >= minScore && currentScore <= maxScore) {
        return percentage;
      }
      return r;
    },
    null
  );

  return (
    <article className="ReleaseAssessment bg--blue color--white">
      <header className="ReleaseAssessment-header">
        <h1 className="ReleaseAssessment-header-heading">{title}</h1>
        <button
          style={{
            visibility:
              Object.keys(state.answers).length > 0 || !currentQuestion
                ? "visible"
                : "hidden",
          }}
          className="ReleaseAssessment-reset"
          onClick={() => reset()}
        >
          Start Over
        </button>
      </header>
      <div className="ReleaseAssessment-inner">
        {showIntro ? (
          <Intro
            heading={recommendationsHeading}
            recommendations={recommendations}
            next={() => setShowIntro(false)}
          />
        ) : currentQuestion ? (
          <main className="Main ReleaseAssessment-main">
            <div className="ReleaseAssessment-questions">
              <table className="RATable ReleaseAssessment-questions-table">
                <thead>
                  <tr>
                    <th>Questions</th>
                    <th>Points</th>
                  </tr>
                </thead>
                <tbody>
                  {questions.map((question, i) => {
                    const currentAnswer = question.choices.find(
                      ({ value }) => value === state.answers[question.id]
                    );
                    const isCurrentQuestion =
                      currentQuestion && currentQuestion.id === question.id;
                    return (
                      <React.Fragment key={question.id}>
                        <InactiveQuestion
                          className={
                            isCurrentQuestion
                              ? "is-active"
                              : currentAnswer
                              ? "is-answered"
                              : ""
                          }
                          {...question}
                          index={i + 1}
                          currentAnswer={currentAnswer}
                          choose={choose}
                          setAsActive={chooseQuestion(i)}
                          nextQuestion={nextQuestion}
                        />
                        {!desktop && currentQuestion.id === question.id && (
                          <tr>
                            <td colSpan="2">
                              <QuestionMobile
                                {...currentQuestion}
                                index={questions.indexOf(currentQuestion) + 1}
                                currentAnswer={
                                  state.answers[currentQuestion.id]
                                }
                                choose={choose}
                                nextQuestion={nextQuestion}
                              />
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
              <dl className="ReleaseAssessment-total">
                <dt className="ReleaseAssessment-score-label">Score</dt>
                <dd className="ReleaseAssessment-header-total-value">
                  <Score current={currentScore} total={initialScore} />
                </dd>
              </dl>
            </div>
            {desktop && (
              <div>
                <Question
                  {...currentQuestion}
                  index={questions.indexOf(currentQuestion) + 1}
                  currentAnswer={state.answers[currentQuestion.id]}
                  choose={choose}
                  nextQuestion={nextQuestion}
                />
              </div>
            )}
          </main>
        ) : (
          <div className="ReleaseAssessment-final">
            <Result
              score={currentScore}
              total={initialScore}
              reset={reset}
              recommendations={recommendations}
              appearanceRate={appearanceRate}
            />
            {/* <OverallRecommendations /> */}
          </div>
        )}
      </div>
    </article>
  );
};

const Intro = ({ recommendations, heading, next }) => {
  // const scale = scaleLinear()
  //   .domain([0,100])
  //   .range([0,W])

  return (
    <div className="ReleaseAssessment-intro bg--white">
      <div
        className="RATable ReleaseAssessment-intro-heading"
        dangerouslySetInnerHTML={{ __html: heading }}
      />
      <table className="RATable ReleaseAssessment-intro-table">
        <tbody>
          <tr>
            <th>Score</th>
            <th>Recommendation</th>
            {/* <th>% of people CJA interviewed</th> */}
          </tr>
          {recommendations.map((d) => {
            // const percentage = d.groups.reduce((r,d) => r + +d.percentage, 0)
            // const barData = d.groups.map(d => {
            //   return {
            //     value: d.percentage,
            //     key: d.heading,
            //     fill: color[d.recommendation]
            //   }
            // })
            return (
              <tr key={d.id}>
                <td>
                  {d.minScore} - {d.maxScore}
                </td>
                <td>
                  {d.groups.map((d) => (
                    <div
                      key={d.id}
                      className="ReleaseAssessment-intro-table-recommendation"
                    >
                      <Circle fill={color[d.recommendation.value]} />{" "}
                      {d.heading && `${d.heading}: `}
                      {d.recommendation.label}
                    </div>
                  ))}
                </td>
                {/* <td> */}
                {/*   <div className="ReleaseAssessment-intro-table-interviewed"> */}
                {/*     <div className="ReleaseAssessment-intro-table-interviewed-percentage"> */}
                {/*       {percentage}% */}
                {/*     </div> */}
                {/*     <SegmentedBar data={barData} dims={[scale(percentage),30]} barHeight={30} /> */}
                {/*   </div> */}
                {/* </td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
      <button className="ReleaseAssessment-intro-button" onClick={next}>
        See the questions
      </button>
    </div>
  );
};

const Circle = ({ fill }) => {
  return (
    <svg viewBox="0 0 10 10" width="10px" height="10px">
      <circle r={5} cx={5} cy={5} fill={fill} />
    </svg>
  );
};

const Result = ({ reset, score, total, recommendations, appearanceRate }) => {
  const determineScore = (score) => {
    let result = [];
    recommendations.forEach(({ minScore, maxScore, groups }) => {
      if (score >= minScore && score <= maxScore) {
        result = groups;
      }
    });
    return result;
  };

  const recs = determineScore(score);

  return (
    <div className="ReleaseAssessment-result">
      <ResultBox
        className="ReleaseAssessment-result-score"
        heading="Your Score"
        text={
          <>
            <div className="ReleaseAssessment-result-score-value">{score}</div>
            <div className="ReleaseAssessment-result-score-label">
              of {total} points
            </div>
          </>
        }
      />
      <ResultBox
        className="ReleaseAssessment-result-appearance"
        heading="Court Appearance Rate"
        text={
          <>
            <div className="ReleaseAssessment-result-appearance-value">
              {appearanceRate}%
            </div>
            <div className="ReleaseAssessment-result-appearance-label">
              of people with this score appear for all their court dates
            </div>
          </>
        }
      ></ResultBox>
      <ResultBox
        className="ReleaseAssessment-result-recommendation"
        heading="CJA Recommendation"
      >
        <div className="ReleaseAssessment-result-recommendation-list">
          {recs.map(({ id, heading, recommendation }) => (
            <Rec key={id} heading={heading} recommendation={recommendation} />
          ))}
        </div>
      </ResultBox>
    </div>
  );
};

const ResultBox = ({ heading, className = "", text, children }) => {
  return (
    <div className={`ResultBox ${className}`}>
      <div className="ResultBox-heading">{heading}</div>
      {text && <div className="ResultBox-content">{text}</div>}
      {children}
    </div>
  );
};

const Rec = ({ heading, recommendation }) => {
  return (
    <div className="ReleaseAssessment-result-recommendation-item">
      <div className="ReleaseAssessment-result-recommendation-item-heading">
        {heading && (
          <div className="ReleaseAssessment-result-recommendation-item-heading-condition">
            {`${heading}: `}
          </div>
        )}
        <div className="ReleaseAssessment-result-recommendation-item-heading-outcome">
          {recommendation.label}
        </div>
      </div>
      <div
        className="ReleaseAssessment-result-recommendation-item-color"
        style={{ backgroundColor: color[recommendation.value] }}
      />
    </div>
  );
};

// const OverallRecommendations = () => {
//   const scale = scaleLinear()
//     .domain([0,100])
//     .range([0,W])
//
//   const data = [
//     {
//       value: 88.6,
//       recommendation: "Recommended for release"
//     }, {
//       value: 4.2,
//       recommendation: "Consider all options"
//     }, {
//       value: 7.2,
//       recommendation: "Not recommended for release"
//     }
//   ]
//
//   const barData = data.map(({ value, recommendation }) => ({
//     recommendation,
//     data: [{
//       key: value,
//       value,
//       fill: color[recommendation]
//     }],
//     dims: [scale(value),40],
//     barHeight: 30
//   }))
//
//   return <div className="OverallRecommendations">
//     <div className="OverallRecommendations-heading">CJA recommendations for all people interviewed</div>
//     {barData.map(d => <div className="OverallRecommendations-item" key={d.recommendation}>
//       <SegmentedBar {...d} />
//       <div className="OverallRecommendations-item-heading">
//         <span className="OverallRecommendations-item-heading-value">{d.data[0].value}%</span>
//         <span>{d.recommendation}</span>
//       </div>
//     </div>)}
//   </div>
// }

const Question = ({
  id,
  index,
  title,
  choices,
  text,
  choose,
  nextQuestion,
  setAsActive,
  currentAnswer,
}) => {
  const t = useTransition(id, null, {
    unique: true,
    from: {
      opacity: 0,
      transform: `translateY(100%)`,
    },
    enter: {
      opacity: 1,
      transform: `translateY(0%)`,
    },
    leave: {
      opacity: 0,
      transform: `translateY(-100%)`,
      position: "absolute",
    },
  });

  return t.map(({ item, key, props }) => (
    <animated.div
      key={key}
      style={props}
      className="ReleaseAssessment-question"
    >
      <div className="ReleaseAssessment-question-heading">
        {index}. {title}
      </div>
      <div className="ReleaseAssessment-question-choices">
        {choices.map((choice) => {
          return (
            <Choice
              {...choice}
              key={choice.id}
              checked={choice.value === currentAnswer}
              radioname={title}
              onClick={() => {
                choose(id, choice.value);
                nextQuestion();
              }}
            />
          );
        })}
      </div>
      <div
        className="ReleaseAssessment-question-description"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </animated.div>
  ));
};
const QuestionMobile = ({
  id,
  index,
  title,
  choices,
  text,
  choose,
  nextQuestion,
  setAsActive,
  currentAnswer,
}) => {
  return (
    <div className="ReleaseAssessment-question">
      <div className="ReleaseAssessment-question-choices">
        {choices.map((choice) => {
          return (
            <Choice
              {...choice}
              key={choice.id}
              checked={choice.value === currentAnswer}
              radioname={title}
              onClick={() => {
                choose(id, choice.value);
                nextQuestion();
              }}
            />
          );
        })}
      </div>
      <div
        className="ReleaseAssessment-question-description"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  );
};
const InactiveQuestion = ({
  id,
  title,
  className = "",
  text,
  choose,
  nextQuestion,
  setAsActive,
  currentAnswer,
  style,
  index,
}) => {
  return (
    <tr
      style={style}
      className={`ReleaseAssessment-inactive-question ${className}`}
      onClick={() => {
        setAsActive();
      }}
    >
      <td>
        <div className={"ReleaseAssessment-inactive-question-query"}>
          <div>{index}. </div>
          <div>{title}</div>
        </div>
      </td>
      <td>{currentAnswer && currentAnswer.value}</td>
    </tr>
  );
};

const Choice = ({ checked, title, value, radioname, onClick }) => {
  return (
    <label className="ReleaseAssessment-question-choices-item">
      <input
        type="radio"
        name={radioname}
        checked={checked}
        onChange={onClick}
      />
      <div>
        {title}
        <span className={`ReleaseAssessment-question-choices-item-points`}>
          {value} pts
        </span>
      </div>
    </label>
  );
};

const Score = ({ current, total }) => (
  <div className="ReleaseAssessment-score">
    <span className="ReleaseAssessment-score-value">
      {current} of {total}
    </span>
  </div>
);
