import React from 'react'
import DragHandle from 'svgr/Grip'
import styles from './variable.module.scss'

const Variable = ({
  label,
  variableIndex,
  options,
  value,
  filters = [],
  canReset = true,
  canDrag = true,
  dispatch,
  variableKey,
}) => {
  const variable = label
  const current = options && options.find(opt => opt.value === value)

  return (
    <fieldset
      className={`CAT-controls-variable ${
        canDrag ? 'CAT-controls-variable--candrag' : ''
      }`}
    >
      <div className="CAT-controls-variable-inner">
        {/* <Dropdown
          label={variable}
          options={options}
          value={value}
          autoFocus={!value}
          setVariable={(value) => {
            dispatch({
              type: "setVariable",
              index: variableIndex,
              value,
            });
          }}
        /> */}
        {!value ? (
          <div className={styles.buttons}>
            <div style={{ width: '100%' }}>Filter by</div>
            {options.map(({ value, label }) => {
              return (
                <div key={value}>
                  <button
                    className={styles.button}
                    onClick={() => {
                      dispatch({
                        type: 'setVariable',
                        index: variableIndex,
                        value,
                        key: variableKey,
                      })
                    }}
                  >
                    {label}
                  </button>
                </div>
              )
            })}
          </div>
        ) : (
          current && <div className={styles.selection}>{current.label}</div>
        )}
        {value && current && (
          <Filters
            filters={filters}
            options={current.options}
            value={value}
            variableIndex={variableIndex}
            dispatch={dispatch}
          />
        )}
        {current && current.text && (
          <div
            className={`CAT-controls-variable-inner-explainer`}
            dangerouslySetInnerHTML={{ __html: current.text }}
          />
        )}
      </div>
      <VariableHeader
        label={`Variable ${variableIndex + 1}`}
        canReset={canReset && variableIndex > 0}
        canDrag={canDrag}
        canChange={!!value}
        dispatch={dispatch}
        variableIndex={variableIndex}
        removeVariable={() => {
          dispatch({
            type: 'removeVariable',
            variable,
          })
        }}
      />
    </fieldset>
  )
}

export default Variable

const Options = ({ options, currentFilters, onChange }) => {
  return (
    <div className={'Checkbox-options'}>
      {options
        .filter(({ value }) => !!value)
        .map(({ value, label }) => (
          <label className="Checkbox" key={value}>
            <input
              type="checkbox"
              value={value}
              checked={currentFilters.includes(value)}
              onChange={onChange}
            />
            <div className={'Checkbox-label'}>{label}</div>
          </label>
        ))}
    </div>
  )
}

const VariableHeader = ({
  variableIndex,
  removeVariable,
  canReset,
  canDrag,
  canChange,
  dispatch,
}) => {
  if (!canReset && !canDrag && !canChange) {
    return null
  }
  return (
    <div className={styles.variableHeader}>
      {/* <div className="CAT-controls-variable-header-label">{label}</div> */}
      {canDrag && <DragHandle className="DragHandle" />}
      <div className={styles.headerEdit}>
        {canReset && (
          <button
            className={styles.resetButton}
            aria-label={'remove'}
            onClick={removeVariable}
          >
            Remove Filter
          </button>
        )}
        {canChange && (
          <button
            className={styles.resetButton}
            onClick={() => {
              dispatch({
                type: 'setVariable',
                index: variableIndex,
                value: null,
              })
            }}
          >
            Change Variable
          </button>
        )}
      </div>
    </div>
  )
}

export const Filters = ({
  filters = [],
  value,
  variableIndex,
  options,
  dispatch,
  // allowFilter,
  variableKey,
}) => {
  return (
    <div
      className={`CAT-controls-variable-filters ${
        filters.length ? 'has-selected' : ''
      }`}
    >
      <label className="Checkbox Checkbox--all" key={value}>
        <input
          type="checkbox"
          value={value}
          checked={filters.length === 0}
          onChange={function() {
            if (filters.length > 0) {
              dispatch({
                type: 'resetFilters',
                index: variableIndex,
              })
            }
          }}
        />
        <div className={'Checkbox-label'}>All</div>
      </label>
      <Options
        onChange={event => {
          dispatch({
            type: 'toggleFilter',
            index: variableIndex,
            value: event.target.value,
            key: variableKey,
          })
        }}
        currentFilters={filters}
        options={
          options
          //.find(opt => opt.value === value).options
          //.filter(filterExcluded)}
        }
      />
    </div>
  )
}
