import React from "react";
import "./Block.scss";

const Block = ({
  children,
  anchor,
  backgroundColor,
  innerBackgroundColor,
  color,
  layoutHeight,
  layoutWidth,
  innerLayout,
  className = "",
}) => {
  let innerClassName = "";
  if (layoutHeight) {
    className += ` layoutHeight--${layoutHeight}`;
  }
  if (layoutWidth) {
    className += ` layoutWidth--${layoutWidth}`;
  }
  if (backgroundColor) {
    className += ` bg--${backgroundColor}`;
  }
  if (color) {
    className += ` color--${color}`;
  }
  if (innerLayout) {
    className += ` innerLayout--${innerLayout}`;
  }
  if (innerBackgroundColor && innerBackgroundColor !== "default") {
    innerClassName += `has-bg bg--${innerBackgroundColor}`;
  }

  const contents = innerLayout ? (
    <div className={`Block-inner ${innerClassName}`}>{children}</div>
  ) : (
    children
  );
  return (
    <>
      {anchor && <div id={anchor} className="BlockAnchor" />}
      <div className={`Block ${className}`}>{contents}</div>
    </>
  );
};

export default Block;
