import React from 'react'

import Accordion from './Accordion'
import AccordionItem from './AccordionItem'
import AnchorLink from './AnchorLink'
import ArrestsDashboard from './ArrestDashboard'
import ArticleFeaturedContent from './ArticleFeaturedContent'
import BigStatement from './BigStatement'
import Block from './Block'
import Callout from './Callout'
import Carousel from './Carousel'
import CatDashboard from './CatDashboard'
import Code from './code'
import CommunityResources from './CommunityResources'
import ContactLink from './ContactLink'
import ContactLinks from './ContactLinks'
import CtaExplainer from './CtaExplainer'
import CuratedContent from './Stream/CuratedContent'
import DashboardSwitcher from './DashboardSwitcher'
import DepartmentPeople from './DepartmentPeople'
import Explainer from './Explainer'
import ExplainerLink from './ExplainerLink'
import ExplainerLinks from './ExplainerLinks'
import Fact from './Fact'
import FeaturedPost from './FeaturedPost'
import Heading from './Heading'
import IconAnchorsHero from './IconAnchorsHero'
import IconHeading from './IconHeading'
import IconHero from './IconHero'
import IconSet from './IconSet'
import IconSetItem from './IconSetItem'
import IframeEmbed from './IframeEmbed'
import Image from './Image'
import InfoBox from './InfoBox'
import Jobs from './Jobs'
import OurServicesHero from './OurServicesHero'
import People from './People'
import PretrialReleaseDashboard from './PretrialReleaseDashboard'
import QuickFact from './QuickFact'
import QuickFacts from './QuickFacts'
import ReleaseAssessment from './ReleaseAssessment'
import Resource from './Resource'
import Resources from './Resources'
import ResourcesGroup from '../ResourcesGroup'
import StandardHero from './StandardHero'
import StaffPerson from './StaffPerson'
import Stream from './Stream'
import Text from './Text'
import Things from './Things'
import ThingsItem from './ThingsItem'
import ThingsNumber from './ThingsNumber'
import Video from './Video'
import QuoteHero from './QuoteHero'
import Timeline from './Timeline'
import DashboardNav from './DashboardNav'

import './PublicationTable.scss' //Custom html code pasted into cms

const Blocks = {
  Accordion,
  AccordionItem,
  AnchorLink,
  ArrestsDashboard,
  ArticleFeaturedContent,
  BigStatement,
  Block,
  Callout,
  Carousel,
  CatDashboard,
  Code,
  CommunityResources,
  ContactLink,
  ContactLinks,
  CtaExplainer,
  CuratedContent,
  DashboardSwitcher,
  DepartmentPeople,
  Explainer,
  ExplainerLink,
  ExplainerLinks,
  Fact,
  FeaturedPost,
  Heading,
  IconAnchorsHero,
  IconHeading,
  IconHero,
  IconSet,
  IconSetItem,
  IframeEmbed,
  Image,
  InfoBox,
  Jobs,
  OurServicesHero,
  People,
  PretrialReleaseDashboard,
  QuickFact,
  QuickFacts,
  ReleaseAssessment,
  Resource,
  Resources,
  ResourcesGroup,
  StandardHero,
  StaffPerson,
  Stream,
  Text,
  Things,
  ThingsItem,
  ThingsNumber,
  Video,
  QuoteHero,
  Timeline,
  DashboardNav,
}

export const getBlocks = (blocks = []) =>
  blocks.reduce((r, props) => {
    if (!props) {
      console.log('no props what does that mean?')
      return r
    }
    const comp = block(props)
    if (comp) {
      r.push(comp)
    }
    return r
  }, [])

const block = props => {
  const { component, children, ...rest } = props

  const Comp = Blocks[component]
  if (Comp) {
    return (
      <Comp key={props.id} uid={props.id} childrenProps={children} {...rest}>
        {children && children.map(block)}
      </Comp>
    )
  }
  console.log('Missing component: ' + component)
}
