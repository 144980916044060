import React from "react";

const SvgGrip = props => (
  <svg width={10} height={10} {...props}>
    <circle cx={2} cy={2} r={1} />
    <circle cx={2} cy={8} r={1} />
    <circle cx={8} cy={2} r={1} />
    <circle cx={8} cy={8} r={1} />
  </svg>
);

export default SvgGrip;
