import React from "react";
import { useFetchStore } from "Fetch";
import email from "svg/email.svg";
import phone from "svg/phone.svg";
import SVG from "react-inlinesvg";
import { H } from "Heading";
import "./CommunityResources.scss";

const footerEndpoint = `${process.env.REACT_APP_API}/footer.json`;

const CommunityResources = ({ heading, text, disclaimer }) => {
  const data = useFetchStore(footerEndpoint);

  if (!data) {
    return null;
  }

  const { resourcesPhone, resourcesEmail } = data;

  return (
    <div className={"CommunityResources"}>
      <div className={"CommunityResources-inner"}>
        <H className="CommunityResources-heading">{heading}</H>
        <div
          className="CommunityResources-text"
          dangerouslySetInnerHTML={{ __html: text }}
        />
        <ul className="CommunityResources-contact">
          <li>
            <a href={`tel:${resourcesPhone}`}>
              <SVG src={phone} /> {resourcesPhone}
            </a>
          </li>
          <li>
            <a href={`mailto:${resourcesEmail}`}>
              <SVG src={email} /> {resourcesEmail}
            </a>
          </li>
        </ul>
        <div className="CommunityResources-disclaimer">{disclaimer}</div>
      </div>
    </div>
  );
};

export default CommunityResources;
