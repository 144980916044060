import React from 'react'
import DashboardSwitcher from './DashboardSwitcher'

const PretrialReleaseDashboardBlock = props => {
  return (
    <DashboardSwitcher
      selectedDashboard={props.selectedDashboard}
      isEmbed
      {...props}
    />
  )
}

export default PretrialReleaseDashboardBlock
