import React from "react";

const SvgSearch = props => (
  <svg width={20} height={19} {...props}>
    <g fill="#0377D4" fillRule="nonzero">
      <path d="M7.574 0c4.181 0 7.575 3.347 7.575 7.48 0 4.133-3.394 7.48-7.575 7.48C3.394 14.96 0 11.613 0 7.48 0 3.347 3.393 0 7.574 0zm0 1.404c-3.41 0-6.17 2.722-6.17 6.076 0 3.354 2.76 6.077 6.17 6.077s6.171-2.723 6.171-6.077-2.76-6.076-6.17-6.076z" />
      <path d="M12.383 13.128a1.213 1.213 0 111.703-1.727l5.266 5.194-1.703 1.727-5.266-5.194z" />
    </g>
  </svg>
);

export default SvgSearch;
