import React from 'react'
import BarLabel from 'components/data-viz/bar-label'
import colors from 'data-colors'
import SVG from 'react-inlinesvg'
import filter from './filter.svg'

const KEYDIM = 50
const Key = ({
  columnLabel,
  label,
  data,
  variable,
  filters = [],
  sliceBy,
  reversed,
  borough,
  followUp,
  numberOfArrests,
  showFiltered,
  isStaticColumn,
  ...rest
}) => {
  if (!data) return null
  const usedColors = [...colors.slice(0, data.length)]
  const directionalColors = reversed ? usedColors : usedColors.reverse()

  let keyData = data

  return (
    <div className="Key" {...rest}>
      {showFiltered && (
        <Filtered
          label={label}
          borough={borough}
          columnLabel={columnLabel}
          filters={filters}
          followUp={followUp}
          numberOfArrests={numberOfArrests}
        />
      )}
      <BarLabel variable={columnLabel} sliceBy={sliceBy} />
      {keyData && (
        <div className="Key-items" key={keyData.length}>
          {keyData.map((datum, i) => {
            if (
              !datum.value ||
              (!sliceBy &&
                filters &&
                filters[variable] &&
                filters[variable].length &&
                !filters[variable].includes(datum.value))
              // recheck this condition
            ) {
              return null
            }
            return (
              <div key={`${datum.label}${i}`} className="Key-item">
                <svg
                  viewBox={`0 0 ${KEYDIM} ${KEYDIM}`}
                  style={{ height: `2em` }}
                >
                  <rect
                    width={KEYDIM}
                    height={KEYDIM}
                    x={0}
                    y={0}
                    fill={directionalColors[i]}
                  />
                </svg>
                {datum.label}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default Key

function Filtered({
  label,
  borough,
  columnLabel,
  filters,
  followUp,
  numberOfArrests,
}) {
  const hasFilter = Object.keys(filters).reduce((hasFilter, key) => {
    if (filters[key].length > 0) {
      hasFilter = true
    }
    return hasFilter
  }, false)
  const boroughSelected = borough && borough !== 'All'
  const numberOfArrestsSelected = numberOfArrests && numberOfArrests.length >= 1
  // const followUpSelected = !!followUp

  return (
    <>
      {label && <div className="Key-label">{label}</div>}
      {(hasFilter ||
        boroughSelected ||
        numberOfArrestsSelected ||
        followUp) && (
        <div className={'Key-label-filtered'}>
          <SVG src={filter} className={'Key-label-filtered-icon'} />
          <div className={'Key-label-filtered__inner'}>
            {followUp && (
              <div className={'Key-label-filtergroup'}>
                <span>New Arrest Follow Up Period: </span>
                <div className="Key-filteritem">{followUp}</div>
              </div>
            )}
            {boroughSelected && (
              <div className={'Key-label-filtergroup'}>
                <span>Borough: </span>
                <div className="Key-filteritem">{borough}</div>
              </div>
            )}
            {numberOfArrestsSelected && (
              <div className={'Key-label-filtergroup'}>
                <span>Number of Prosecuted Arrests: </span>
                {numberOfArrests.map(d => (
                  <div className="Key-filteritem" key={d}>
                    {d}
                  </div>
                ))}
              </div>
            )}
            {hasFilter &&
              Object.keys(filters).map(
                key =>
                  filters[key].length > 0 && (
                    <div className={'Key-label-filtergroup'} key={key}>
                      <span>{key}: </span>
                      {filters[key].map(d => (
                        <div className="Key-filteritem" key={d}>
                          {d}
                        </div>
                      ))}
                    </div>
                  )
              )}
          </div>
        </div>
      )}
    </>
  )
}
