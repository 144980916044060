import React, { useEffect, useRef } from 'react'
import ReactTooltip from 'react-tooltip'
import RichText from 'components/rich-text/rich-text'

function makeId(text) {
  return text.replace(/[^a-zA-Z_]/g, '')
}
const Text = ({ text, children }) => {
  const ref = useRef()
  useEffect(() => {
    ref.current.querySelectorAll('h2,h3').forEach(node => {
      const id = makeId(node.textContent)
      node.id = id
    })
    ReactTooltip.rebuild()
  }, [text])

  const content = (
    <div ref={ref}>
      <RichText text={text} className="Text rte" />
    </div>
  )
  return children.length ? (
    <div className="twocolumn">
      {content}
      {children}
    </div>
  ) : (
    content
  )
}

export default Text
