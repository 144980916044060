import React from "react";
import { Link } from "react-router-dom";
import Image from "blocks/Image";


const IconSetItem = ({ id, heading, link, image }) => {
  let inner;

  switch (link.type) {
    case "anchorLink":
      inner = <a href={`#${link.to}`}>
        <Image image={image} caption={heading} />
      </a>
      break;

    case "internalLink":
      inner = <Link to={link.to}>
        <Image image={image} caption={heading} />
      </Link>
      break;

    case "externalLink":
      inner = <a href={link.to} target="_blank" rel="noopener noreferrer">
        <Image image={image} caption={heading} />
      </a>
      break;

    default:
      inner = null
  }
  return <li className="IconSet-item">{inner}</li>
}

export default IconSetItem;