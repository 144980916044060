import React, { useState } from "react";
import { H } from "Heading";
import { useTransition, a } from "react-spring";
import ReleaseAssessment from "components/ReleaseAssessment";
import "./ReleaseAssessment.scss";

const ReleaseAssessmentBlock = ({
  heading,
  text,
  assessmentId,
  disclaimer,
}) => {
  const [active, setActive] = useState(false);
  const transitions = useTransition(active, null, {
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
      position: "absolute",
      width: "100%",
      height: "100%",
    },
  });

  return transitions.map(({ item, key, props }) => {
    return item ? (
      <a.div style={props} key={"assess"}>
        <ReleaseAssessment id={assessmentId} />
      </a.div>
    ) : (
      <a.div style={props} key={"intro"}>
        <Intro
          heading={heading}
          text={text}
          setActive={setActive}
          disclaimer={disclaimer}
        />
      </a.div>
    );
  });
};

export default ReleaseAssessmentBlock;

const Intro = ({ heading, text, disclaimer, setActive }) => {
  return (
    <div className="Block bg--blue innerLayout--whitePill ReleaseAssessmentBlock">
      <div
        className="Block-inner bg--white"
        onClick={() => {
          setActive(true);
        }}
      >
        <div className="CtaExplainer">
          <H className="CtaExplainer-heading">{heading}</H>
          <div
            className="CtaExplainer-body"
            dangerouslySetInnerHTML={{ __html: text }}
          />
          <span className="ReleaseAssessmentBlock-button">
            Explore the assessment
          </span>
        </div>
        <div className="CtaExplainer-disclaimer">{disclaimer}</div>
      </div>
    </div>
  );
};
