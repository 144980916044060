import React from 'react'
import styles from './toggle-set.module.scss'

const ToggleSet = ({ items, current, set, icons }) => {
  return (
    <fieldset className={'CAT-results-controls-item ToggleSet'}>
      {/* <div className="ToggleSet-buttons">
        {items.map((view) => {
          return (
            <IconToggle
              key={view}
              active={current === view}
              onClick={set(view)}
            >
              {icons[view]()}
            </IconToggle>
          );
        })}
      </div> */}
      <div className="ToggleSet-labels">
        {items.map(view => (
          <button
            key={view}
            onClick={() => set(view)}
            className={
              current === view ? styles.toggleButtonActive : styles.toggleButton
            }
          >
            {view}
          </button>
        ))}
      </div>
    </fieldset>
  )
}

export default ToggleSet

// const IconToggle = ({ active, children, label, ...rest }) => {
//   return (
//     <button
//       className={`IconToggle ${active ? "active" : ""}`}
//       aria-label={label}
//       {...rest}
//     >
//       <div className="IconToggle-icon">{children}</div>
//     </button>
//   );
// };
